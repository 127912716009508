import { ref } from 'vue';
import { useStore } from 'vuex';

// productAuto.js
export default function productAuto() {
    const store = useStore();

    const modelNameList = ref([]);

    const getProduct = () => {
        // 상세 검색 => 제품명
        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/model/getList',
                "param": {
                    "isUse": true
                }
            }
        ).then(result => {
            // modelNameList.value = result.data.data.map(obj => obj['modelName']);
            modelNameList.value = result.data.data;

            // prefix 제거
            // modelNameList.value = modelNameList.value.map(item => {
            //     const {prefix, ...rest} = item;
            //     return rest;
            // });

            // prefix를 다른 key로 변경
            modelNameList.value.forEach(item => {
                item.pf = item.prefix;
                delete item.prefix;
            });
        });
    }
    
    return {
        modelNameList,
        getProduct,
    }
}