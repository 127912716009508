<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">확인</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            삭제할 데이터을 선택해 주세요.
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="cancel" class="btn-sb">닫기</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
export default {
    name: 'CheckBoxPopup2',
    setup() {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        return {
            cancel,
        }
    },
}
</script>

<style>

</style>