<template>
    <div class="popup-box">
        <div class="bg">
            <div v-if="!confirm" class="popup">
                <div class="tit">펌웨어 그룹 편집</div>
                <div class="inner">
                    <div class="cont">
                        <div class="table pbot20 clearfix">
                            <div class="group">
                                <ul>
                                    <li class="table-tit">그룹명</li>
                                    <li class="table-item">
                                        <span>{{groupName}}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li class="table-tit">제품명</li>
                                    <li class="table-item"> 
                                        <span>{{modelName}}</span>
                                    </li>
                                </ul>
                            </div>  
                        </div>
                        <ul v-for="item in (partList)" :key="item" class="input-txt-list pbot10">
                            <li class="tit">{{item.partName}} 펌웨어 버전</li>
                            <li class="cont">
                                <input type="text" v-model="item.firmwareVersion">
                            </li>
                            <li class="tit">{{item.partName}} 펌웨어 URL</li>
                            <li class="cont">
                                <input type="text" v-model="item.url">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
            <div v-else-if="confirm" class="popup">
                <div class="tit">펌웨어 그룹 편집</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, reactive, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'FirmwareDetailPopup1',
    setup() {
        const emitter = inject('emitter');

        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);
        const firmwareModelId = computed(() => store.state.dataModule.firmwareDetail_firmwareModelId);
        const modelId = computed(() => store.state.dataModule.firmwareDetail_modelId);

        const confirm = ref(false);
        let publicParam = ref(null);

        const groupName = ref(null);
        const modelName = ref(null);

        const partList = ref(null)

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const readData = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/firmware-model/getDataParts',
                    "param": {
                        "firmwareModelId": firmwareModelId.value,
                        "modelId": modelId.value
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;
                partList.value = result.data.data.partList;

                groupName.value = result.data.data.firmwareModel.groupName;
                modelName.value = result.data.data.firmwareModel.modelName;

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });
        
        const next = (() => {
            confirm.value = true;
        });

        const execute = (() => {
            let param = {...publicParam.value}

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/firmware-model/updateData',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onMounted(() => {
            readData(); 
        });

        // onBeforeMount(() => {
        //     readData();
        // })

        return {
            confirm,

            groupName,
            modelName,
            modelId,

            partList,

            cancel,
            next,
            execute,
        }
    },
}
</script>

<style>

</style>