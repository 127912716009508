import { ref } from 'vue';
import { useStore } from 'vuex';

// nationAuto.js
export default function nationAuto() {
    const store = useStore();

    const nationList = ref([]);

    const getNation = () => {
        let param = {
            "isUse": true
        }

        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/common/getList',
                "param": {
                    "mainCode": "N0001",
                    "isUse": true
                }
            }
        ).then(result => {
            nationList.value = result.data.data;
        });
    }
    
    return {
        nationList,
        getNation,
    }
}