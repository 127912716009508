<template>
    <div class="popup-box">
        <div class="bg">
            <div v-if="!confirm" class="popup">
                <div class="tit">일련번호 변경</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                일련번호
                                <span v-if="warning" class="warning">일련번호를 입력해주세요</span>
                            </li>
                            <li class="cont">
                                <input type="text" v-model="productSnNew" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>

            <div v-else-if="confirm" class="popup">
                <div class="tit">일련번호 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            일련번호를 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import moment from 'moment'
export default {
    name: 'ProductDetailPopup3',
    setup(props) {
        const emitter = inject('emitter');
        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);

        let publicParam = ref(null);

        const productSn = computed(() => store.state.dataModule.productDetail_productSn);
        const productSnNew = ref('');

        let utcTime = computed(() => store.state.utcTime);
        const dateUtils = inject('dateUtils');

        const warning = ref(false);
        const confirm = ref(false);

        const next = () => {
            // 필수 값 체크
            if (!productSnNew.value) {
                // alert("변경할 일련번호 값을 입력해주세요.");
                warning.value = true;
                return;
            }

            warning.value = false;
            confirm.value = true;
        };

        const cancel = (() => {
            emitter.emit('popupType', '');
        });
        
        const execute = (() => {
            let param = {...publicParam.value};
            param.insertId = insertId.value;
            param.productSnNew = productSnNew.value;

            // productSnIssueDate를 현재 시간에 UTC를 적용한 값을 등록
            let currentDate = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            param.productSnIssueDate = dateUtils.convertUTC(currentDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/product/changeSn',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readData = (()=> {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/getData',
                    "param": {
                        "productSn": productSn.value,
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onBeforeMount(() => {
            readData();
        });

        return {
            productSnNew,
            warning,
            confirm,

            next,
            cancel,
            execute,
        }
    }
}
</script>

<style>

</style>