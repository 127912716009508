<template>
    <div v-if="showTooltip" class="popup-box">
        <div class="bg">
            <div class="popup" v-click-outside="closeTooltip">
                <div class="tit">{{tooltipTitle}}</div>
                <div class="inner">
                    <div class="cont">
                        <p :style="{fontSize:'15px'}">{{tooltipContent}}</p>
                    </div>
                    <div class="btn-box one">
                        <button @click="this.emitter.emit('showTooltip', false)" class="btn-sb">닫기</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeUnmount, onMounted } from 'vue';
import { inject } from 'vue';

export default {
  name: 'Tooltip',
  setup() {
    // 상태 변수 선언
    const showTooltip = ref(false);
    const tooltipTitle = ref('');
    const tooltipContent = ref('');

    const emitter = inject('emitter')

    // 이벤트 구독
    const onShowTooltip = (data) => {
        showTooltip.value = data;
    };

    const onTooltipTitle = (data) => {
        tooltipTitle.value = data;
    };

    const onTooltipContent = (data) => {
        tooltipContent.value = data;
    };

    // emitter.on('showTooltip', onShowTooltip);
    // emitter.on('tooltipTitle', onTooltipTitle);
    // emitter.on('tooltipContent', onTooltipContent);

    // emitter.on('showTooltip', (data) => {
    //     showTooltip.value = data;
    // });

    onMounted(() => {
        emitter.on('showTooltip', onShowTooltip);
        emitter.on('tooltipTitle', onTooltipTitle);
        emitter.on('tooltipContent', onTooltipContent);  
    })

    // 컴포넌트가 제거될 때 이벤트 구독 해제
    const onBeforeUnmountHandler = () => {
        emitter.off('showTooltip', onShowTooltip);
        emitter.off('tooltipTitle', onTooltipTitle);
        emitter.off('tooltipContent', onTooltipContent);
    };

    // onBeforeUnmount를 훅으로 등록
    onBeforeUnmount(onBeforeUnmountHandler);

    // 기타 로직과 상태 변수들 반환
    return {
        showTooltip,
        tooltipTitle,
        tooltipContent,
    };
  },
};
</script>

<style>

</style>