import { createStore } from 'vuex'
import { createVuexPersistedState } from 'vue-persistedstate'
import cartridgeModule from './modules/cartridgeModule.js'
import loginModule from './modules/loginModule.js'
import filteringModule from './modules/filteringModule.js'
import popupModule from './modules/popupModule.js'
import profileModule from './modules/profileModule.js'
import menuModule from './modules/menuModule.js'

import dataModule from './modules/dataModule.js'

const store = createStore({
  plugins: [ createVuexPersistedState({
    key: 'vuexStore',
    storage: window.localStorage,
    // storage: window.sessionStorage,
  }) ],
  state(){
    return{
      isLoading: false,
      originColumn: [],
      utcTime: 0,
    }
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
  },
  mutations:{
    SET_LOADING_BAR(state,data){
      state.isLoading = data;
    },
    SET_ORIGIN_COLUMN(state, payload) {
      state.originColumn = payload;
    },
    SET_UTC_TIME(state, payload) {
      state.utcTime = payload;
    }
  },
  modules: {
    cartridgeModule,
    loginModule,
    filteringModule,
    popupModule,
    profileModule,
    menuModule,
    dataModule
  },


  
})

export default store