<template>
  <div class="wrap">
    <div class="login-box pos-center">
        <div class="logo">
            <h1></h1>
        </div>
        <div class="info">
            <div>
                <div class="tit">로그인</div>
                <ul class="input-txt-list">
                    <form>
                        <li class="cont">
                            <input 
                                type="text" 
                                v-model="userData.userId" 
                                style="font-size: 16px;" 
                                placeholder="아이디" 
                                title="아이디"
                            >
                        </li>
                        <li class="cont">
                            <input 
                                type="password" 
                                v-model="userData.userPw" 
                                @keyup.enter="doLogin" 
                                style="font-size: 16px;" 
                                placeholder="비밀번호" 
                                title="비밀번호"
                            >
                        </li>
                    </form>
                </ul>
                <div class="chk-box filtering">
                    <input type="checkbox" id="keep" class="chk_btn" v-model="keepLogin">
                    <label for="keep" class="chk">
                        <!-- <span>로그인 유지</span> -->
                        <span>아이디 저장</span>
                    </label>
                </div>
                <div class="btn-box full">
                    <button @click="doLogin" class="btn">로그인</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// import { mapMutations, mapState } from 'vuex'
import { reactive, ref, onMounted, watchEffect } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'Login',
    setup() {
        // 변수 선언
        let userData = reactive({
            userId: '',
            userPw: ''
        });
        let keepLogin = ref(false);
        
        // store 선언
        const store = useStore();

        /**
         * 함수 선언
         */
        // 로그인
        const doLogin = (() => {
            if(userData.userId == '' || userData.userId == null) {
                alert("아이디를 입력해주세요.");
                return;
            }
            if(userData.userPw == '' || userData.userPw == null) {
                alert("비밀번호를 입력해주세요.");
                return;
            }

            store.dispatch('loginModule/doLogin', userData).then(result => {
                if (result.success) {
                    userData.userId = ''
                    userData.userPw = ''
                } else {
                    alert(result.error);
                }
            });
        });

        onMounted(() => {
            keepLogin.value = false;

            function getCookie(name) {
                const value = "; " + document.cookie;
                const parts = value.split("; " + name + "=");
                if (parts.length === 2) return parts.pop().split(";").shift();
            }

            const id_cookie = getCookie('id');

            if(id_cookie != null && id_cookie != undefined && id_cookie != '') {
                keepLogin.value = true;
                userData.userId = id_cookie;
            }
            
            // const keepLogin_cookie = getCookie('keepLogin');

            // if(keepLogin_cookie === 'true') {
            //     keepLogin.value = true;
            //     userData.userId = id_cookie;
            // } else if(keepLogin_cookie === 'false') {
            //     keepLogin.value = false;
            // }
        });

        watchEffect(() => {
            const newValue = keepLogin.value;
            store.commit('loginModule/SET_KEEP_LOGIN', newValue)
        });

        return {
            userData,
            keepLogin,
            doLogin,
        }
    },
}
</script>

<style>

</style>