<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">발주 시스템 연동</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">주문 코드</li>
                            <li class="cont">
                                <input v-model="orderCode" type="text" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">발주 시스템 연동</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            주문 코드를 적용하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, computed, inject, onMounted, reactive, onCreated, onBeforeUnmount } from 'vue';
import { useStore  } from 'vuex';
export default {
    name: 'ProductDetailPopup2',
    setup() {
        const store = useStore();
        const emitter = inject('emitter');

        const productSn = computed(() => store.state.dataModule.productDetail_productSn);

        let confirm = ref(false);
        let orderCode = ref(null);
        
        const cancel = (event) => {
            emitter.emit('popupType', '');
        };
        
        const next = () => {
            if(orderCode.value == '' || orderCode.value == null) {
                alert('주문 코드를 입력해주세요.')
                return;
            }
            confirm.value = true;
        };

        const execute = () => {
            let param = {
                "productSn": productSn.value,
                "orderCode": orderCode.value
            };

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/order/sync',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                    emitter.emit('returnFunction', 'licenseChange');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        };

        return {
            confirm,
            productSn,
            orderCode,

            cancel,
            next,
            execute
        }
    }
}
</script>

<style>

</style>