<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a href="" class="prev">라이선스 관리</a>
                <a href="" class="now">라이선스 키 생성</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/license')" class="btn-sb-g">목록으로</button>
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">일련번호</li>
                            <li class="table-item">
                                <span>{{productSn}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">판매처</li>
                            <li class="table-item">
                                <span>{{customerName}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">제품명</li>
                            <li class="table-item">
                                <span>{{modelName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">Request Code</li>
                            <li class="table-item">
                                <span>{{requestCode}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <h3>신규 라이선스 키</h3>
                <div class="box">
                    <div class="inner " v-if="!createKeyFinish">
                        <ul class="input-txt-list">
                            <li class="tit">라이선스 기간</li>
                            <li class="cont">
                                <input type="date" v-model="expirationDate" @input="setDate('direct')">
                            </li>
                        </ul>

                        <div class="cont">
                            <div class="btn-box tab pbot20">
                                <button @click="setDate('month', 1)" class="btn-sb-g-license" :class="{'active': expirationButton == 'm1'}">1달</button>
                                <button @click="setDate('month', 3)" class="btn-sb-g-license" :class="{'active': expirationButton == 'm3'}">3달</button>
                                <button @click="setDate('month', 6)" class="btn-sb-g-license" :class="{'active': expirationButton == 'm6'}">6달</button>
                                <button @click="setDate('year', 1)" class="btn-sb-g-license" :class="{'active': expirationButton == 'y1'}">1년</button>
                                <button @click="setDate('year', 3)" class="btn-sb-g-license" :class="{'active': expirationButton == 'y3'}">3년</button>
                                <button @click="setDate('none', 999999)" class="btn-sb-g-license" :class="{'active': expirationButton == 'n'}">영구</button>
                            </div>
                        </div>

                        <ul class="input-txt-list">
                            <li class="tit">오프라인 유효기간(일)</li>
                            <li class="cont">
                                <input type="text" v-model="offlineExpirationDay">
                            </li>
                        </ul>

                        <div class="btn-box two">
                            <button @click="$router.push('/license')" class="btn-sb">취소</button>
                            <button @click="next" class="btn">키 생성</button>
                        </div>
                    </div>
                    <div class="inner licenseKey" v-if="createKeyFinish">
                        <div class="cont">
                            <p class="big">
                            </p>
                            <p class="big">
                                {{licenseKey}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btn-box one mtop40" v-if="createKeyFinish">
                    <button @click="$router.push('/license')" class="btn">확인</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">라이선스 관리</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            라이선스 키를 생성하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="createKey" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore  } from 'vuex';
export default {
    name: 'LicenseKey',
    setup() {
        const store = useStore();
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const insertId = computed(() => store.state.profileModule.id);
        const licenseKeyData = computed(() => store.state.dataModule.licenseKey_data);

        const customerId = ref(null);
        const productSn = ref(null);
        const modelName = ref(null);
        const modelId = ref(null);
        const requestCode = ref(null);
        const customerName = ref(null);
        const expirationDate = ref(null);
        let expirationDay = ref(null);
        const offlineExpirationDay = ref(null);
        const expirationButton = ref(null);       // 라이선스 만료일 버튼 제어
        
        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);
        
        const licenseKey = ref(null);

        // 라이선스 만료일 설정
        const setDate = ((type, num) => {
            if(type == 'month') {
                let returnDate = dateUtils.changeMonthDate(num);
                expirationDate.value = returnDate.dateString;
                expirationDay.value = returnDate.day;
                expirationButton.value = 'm'+num;
            } else if(type == 'year') {
                let returnDate = dateUtils.changeYearDate(num);
                expirationDate.value = returnDate.dateString
                expirationDay.value = returnDate.day;
                expirationButton.value = 'y'+num;
            } else if(type == 'none') {
                expirationDate.value = '2127-12-31';
                expirationDay.value = num;
                expirationButton.value = 'n';
            } else if(type == 'direct') {
                expirationDay.value = dateUtils.getCurrentDate(expirationDate.value)
                expirationButton.value = null;
            }
        });

        const next = (() => {
            if(expirationDate.value == null || expirationDate.value == '') {
                warning.value = true;
                message.value = '라이선스 기간을 확인해주세요.';
                return;
            }

            if(offlineExpirationDay.value == null || offlineExpirationDay.value == '') {
                warning.value = true;
                message.value = '오프라인 유효기간을 확인해주세요.';
                return;
            }

            confirm.value = true;
        })

        let createKeyFinish = ref(false);

        const createKey = (() => {
            let param = {
                "insertId": insertId.value,
                "licenseType": 1,
                "customerId": customerId.value,
                "productSn": productSn.value,
                "modelId": modelId.value,
                "requestId": insertId.value,
                "expirationDate": expirationDate.value,
                "offlineExpirationDay": offlineExpirationDay.value,
                "requestCode": requestCode.value,
            }
            
            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/device-license/insert-license',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    createKeyFinish.value = !createKeyFinish.value;

                    licenseKey.value = result.data.data.licenseKey;
                    
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    confirm.value = false;
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const getCustomer = () => {
            // 상세 검색 => 제품명
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/customer/getData',
                    "param": {
                        "customerId": customerId.value
                    }
                }
            ).then(result => {
                customerName.value = result.data.data.customerName;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        }

        onMounted(() => {
            customerId.value = licenseKeyData.value.customerId;
            productSn.value = licenseKeyData.value.productSn;
            modelName.value = licenseKeyData.value.modelName;
            modelId.value = licenseKeyData.value.modelId;
            requestCode.value = licenseKeyData.value.requestCode;

            getCustomer();
        });

        return {
            productSn,
            modelName,
            requestCode,
            customerName,
            expirationDate,
            expirationDay,
            offlineExpirationDay,
            expirationButton,
            setDate,

            message,
            warning,
            confirm,

            next,

            createKeyFinish,
            createKey,
            licenseKey,
        }
    },
}
</script>

<style>

</style>