<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup" >
                <div class="tit">삭제</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보를 삭제하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="del" class="btn">삭제</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, onUpdated, computed, inject } from 'vue';
export default {
    name: 'DeletePopup',
    setup() {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');
        
        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const del = (() => {
            emitter.emit('delete', true);
        });

        return {
            cancel,
            del,
        }
    }
}
</script>
<style>
</style>