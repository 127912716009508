const state = () => ({
    popupType: '',
})

const getters = {
    getPopupType: (state) => state.popupType,
}

const actions = {
    
}

const mutations = {
    CHANGE_POPUP_TYPE(state, payload) {
        state.popupType = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}