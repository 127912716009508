<template>
    <div class="contents scroll-y">
        <div class="inner">
            <h2 class="nav-title">
                <p>
                    <a href="" class="now">대리점 정보 관리</a>
                </p>
            </h2>
            <div class="top-bar">
                <div class="btn-box multi">
                    <button class="btn-sb-g off">라이선스 그룹 변경</button>
                    <button class="btn-sb-g off">펌웨이 그룹 변경</button>
                    <button class="btn-sb-g">엑셀 다운로드</button>
                </div>
                <div class="btn-box tab">
                    <button class="btn-sb-g">신규 연동 검색</button>
                    <button class="btn-sb-g">접속 IP 상이 검색</button>
                    <button class="btn-sb-g">임시 적용 검색</button>
                    <button class="btn-sb-g">상세 검색</button>
                </div>
            </div>
            <div class="board product">
                <ul class="list-tit">
                    <li>
                        <div class="chk-box">
                            <input type="checkbox" id="chk-hold" class="none">
                            <label for="chk-hold" class="chk"></label>
                        </div>
                    </li>
                    <li>No.</li>
                    <li>일련번호</li>
                    <li>제품명</li>
                    <li>고객사</li>
                    <li>판매처</li>
                    <li>발주 주문 코드</li>
                    <li>만료 기간</li>
                    <li>최종 접속 일</li>
                    <li>출고일</li>
                    <li>아이콘</li>
                </ul>
                <ul class="list-item">
                    <li>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                        <ul class="item">
                            <li>
                                <div class="chk-box">
                                    <input type="checkbox" id="chk-hold" class="none">
                                    <label for="chk-hold" class="chk"></label>
                                </div>
                            </li>
                            <li>1</li>
                            <li>CN22H014</li>
                            <li>UF 4 MPT</li>
                            <li>BeauiskyBeauisky</li>
                            <li>BeauiskyBeauisky</li>
                            <li>PK0299996</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>2022.08.29</li>
                            <li>아이콘</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AgencyInfo'
}
</script>

<style>

</style>