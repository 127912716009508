const state = () => ({
    menus: [],
    subMenus: [],
    currentMenu: "",
    settingMenu: "",
})

const getters = {
    getMenus: (state) => state.menus,
    getSubMenus: (state) => state.subMenus,
    getCurrentMenu: (state) => state.currentMenu,
    getSettingMenu: (state) => state.settingMenu,
}


const actions = {
    set_menus_for_auth(context, payload) {
        /**
         * userAuth 값에 따라 계정의 권한이 달라짐
         */
        // let authMenu = payload.userInfo.userAuth
        let menus = payload.menus

        // 숨김 처리 (2차 작업 예정)
        // 대리점 코드 변경 관리, 대리점 관리, 라이선스 관리, 대리점 코드 변경, 제품 날짜 변경
        // if(process.env.VUE_APP_SERVER == "PRD") {
        //     menus = menus.filter(item => 
        //         item.siteUrl != '/agencyCode' && item.siteUrl != '/agency' && item.siteUrl != '/license' 
        //         && item.siteUrl != '/agencyCodeChange' && item.siteUrl != '/productDate' 
        //     )
        // }
        // menus = menus.filter(item => 
        //     item.siteUrl != '/agencyCode' && item.siteUrl != '/agency' && item.siteUrl != '/license' 
        //     && item.siteUrl != '/agencyCodeChange' && item.siteUrl != '/productDate' 
        //     // && item.siteUrl != '/serialNumber' && item.siteUrl != '/mail'
        // )
        
        let subMenus = menus.filter(menu => menu.level == 2)
        menus = menus.filter(menu => menu.level == 1)
        // menus.forEach((menu)=>{
        //     menu.className = menu.siteUrl.replaceAll('/', '')
        //     // 환경설정에 siteUrl이 없어서 따로 처리
        //     if(menu.menuCode == 25) {
        //         menu.siteUrl = '/setting'
        //         menu.className = 'setting'
        //     }
        // })

        context.commit('SET_MENUS', menus);
        context.commit('SET_SUB_MENUS', subMenus);
    },
}

const mutations = {
    SET_MENUS(state, payload) {
        state.menus = payload
    },
    SET_SUB_MENUS(state, payload) {
        state.subMenus = payload
    },
    SET_CURRENT_MENU(state, payload) {
        state.currentMenu = payload
    },
    SET_SETTING_MENU(state, payload) {
        state.settingMenu = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}