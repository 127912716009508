const state = () => ({
    originColumn: [],
})

const getters = {
    originColumn: (state) => state.originColumn,
}


const actions = {
    setOriginColumn({ state, commit }, payload) {
        commit('SET_ORIGIN_COLUMN', payload)
    },
}

const mutations = {
    SET_ORIGIN_COLUMN(context, payload) {
        // state.originColumn = payload
        context.originColumn = payload
    }
}

export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}