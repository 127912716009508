<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup" ref="userPopup1">
                <div class="tit">사용자 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                <p>사용자 분류<em>*</em></p>
                            </li>
                            <li class="pbot10">
                                <v-autocomplete
                                :items="authList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="subCode"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">
                                <p>서브 분류<em>*</em></p>
                            </li>
                            <li class="pbot10">
                                <v-autocomplete
                                :items="authSubList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="subCode2"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">
                                <p>대리점명<em>*</em></p>
                                <span class="warning" v-if="!agencyName">대리점명을 입력해주세요.</span>
                            </li>
                            <li class="cont">
                                <input type="text" :value="agencyName" class="readonly" readonly>
                            </li>
                            <div class="btn-box popup-right mbot10">
                                <button :disabled="!isAgencyList" @click="openUserPopup2" class="btn-sb-g">대리점 목록</button>
                            </div>
                            <li class="tit">
                                <p>아이디<em>*</em></p>
                                <span v-if="userIdAlert" class="warning">아이디를 입력해주세요. (3~30자리)</span>
                                <!-- <span class="">글자 수를 4~20 사이로 입력해주세요.</span> -->
                                <!-- <span class="done">사용가능한 아이디입니다.</span> -->
                            </li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="userId">
                            </li>
                            <li class="tit">
                                <p>비밀번호<em>*</em></p>
                                <span v-if="userPwAlert" class="warning">비밀번호를 입력해주세요. (3~30자리)</span>
                                <!-- <span class=""> 영문, 숫자, 특수문자 포함 8자리 이상</span> -->
                            </li>
                            <li class="cont">
                                <input type="password" placeholder="" v-model="userPw">
                            </li>
                            <li class="tit">
                                <p>비밀번호 확인<em>*</em></p>
                                <span class="warning" v-if="userPw !== userPwCheck">비밀번호 불일치</span>
                            </li>
                            <li class="cont">
                                <input type="password" placeholder="" v-model="userPwCheck">
                            </li>
                            <li class="tit">이름</li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="userName">
                            </li>
                            <li class="tit">연락처</li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="phone">
                            </li>
                            <li class="tit">이메일</li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="email">
                            </li>
                            <li class="tit">주소</li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="address">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="dataCheck" class="btn">확인</button>
                    </div>
                </div>
            </div>
            <div class="popup" ref="userPopup2" style="display: none;">
                <div class="tit">대리점 목록</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                <p>검색</p>
                            </li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="agencySearch">
                            </li>
                        </ul>
                        <ul class="search-list">
                            <li v-for="(agency) in agencyData" :key="agency.customerId" @click="setAgencyName(agency)">
                                {{agency.customerName}}
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="closeUserPopup2" class="btn-sb">취소</button>
                        <button @click="setAgencyName()" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">사용자 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="createData" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onBeforeMount, onMounted, onUpdated, computed, inject, reactive } from 'vue';
import { useStore} from 'vuex';
import { validateId, validatePw } from '@/js/common/regex.js';
import authAuto from '@/js/autocomplete/authAuto';
export default {
    name: 'UserPopup1',
    setup(props, context) {
        const store = useStore();
        const emitter = inject('emitter');

        const insertId = computed(() => store.state.profileModule.id);
        const userAuth = computed(() => store.state.loginModule.userAuth);

        const userPopup1 = ref(null);
        const userPopup2 = ref(null);
        
        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        let userIdAlert = ref(false);
        let userPwAlert = ref(false);

        let agencySearch = ref('');
        let agencyData = ref([]);

        let agencyName = ref(null);     // 대리점명
        let isAgencyList = ref(true);   // 대리점 목록 버튼 사용 여부
                
        let customerId = ref(null);
        let userId = ref(null);
        let userPw = ref(null);
        let userPwCheck = ref(null);
        let userName = ref('');
        let phone = ref('');
        let email = ref('');
        let address = ref('');
        let addressDetail = ref('');

        let executeCheck = ref('i');
        let id = ref(null);

        const subCode = ref(null);
        const codeName = ref(null);
        const subCode2 = ref(null);
        const codeName2 = ref(null);
        const {
            authList,
            getAuth,
            authSubList,
            authSubListOri,
            getAuthSub,
        } = authAuto();

        onBeforeMount(() => {
            // getAuth();
            getAuth(userAuth.value);
            getAuthSub();
            readAgency();
        });

        // next
        const dataCheck = (() => {
            if(subCode.value === null || subCode.value === '') {
                warning.value = true;
                message.value = '사용자 분류를 선택해주세요.';
                return;
            }
            if(subCode2.value === null || subCode2.value === '') {
                warning.value = true;
                message.value = '서브 분류를 선택해주세요.';
                return;
            }
            if(agencyName.value === null || agencyName.value === '') {
                warning.value = true;
                message.value = '대리점을 선택해주세요.';
                return;
            }
            if(userIdAlert.value || userId.value === null || userId.value === '') {
                warning.value = true;
                message.value = '아이디를 입력해주세요.';
                return;
            }
            if(userPwAlert.value || userPw.value === null || userPw.value === '') {
                warning.value = true;
                message.value = '비밀번호를 입력해주세요.';
                return;
            }
            if(userPwAlert.value || userPwCheck.value === null || userPwCheck.value === '') {
                warning.value = true;
                message.value = '비밀번호 확인을 입력해주세요.';
                return;
            }
            // if(!userId.value) {}
            // if(!userPw.value) {}
            // if(!userPwCheck.value) {}
            idCheck();
        });

        const idCheck = (() => {
            const param = {
                "userId": String(userId.value)
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/user/getData',
                    "param": param
                }
            ).then(result => {
                if(result.data.data.userId == null) {
                    confirm.value = true;
                } else {
                    if(result.data.data.isUse == true) {
                        warning.value = true;
                        message.value = '아이디가 중복되었습니다.';
                        return;
                    } else if(result.data.data.isUse == false) {
                        executeCheck.value = 'u';
                        id.value = result.data.data.id;
                        confirm.value = true;
                    }
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const createData = (() => {
            
            let param = {
                "insertId": insertId.value,
                "userId": String(userId.value),
                "userPw": String(userPw.value),
                "userName": String(userName.value),
                "customerId": parseInt(customerId.value),
                "userAuth": parseInt(subCode.value),
                "userAuthSub": parseInt(subCode2.value),
                "phone": String(phone.value),
                "email": String(email.value),
                "address": String(address.value),
                "addressDetail": "",
                "remark": "",
                "isUse": true
            };

            if(executeCheck.value == 'i') {
                store.dispatch(
                    'dataModule/insertData'
                    , {
                        "url": '/api/v1/user/insert',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', result.data.data);
                        emitter.emit('popupType', 'resultPopup1');
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                });
            } else if(executeCheck.value == 'u') {
                param.id = id.value;
                param.isUse = true;

                store.dispatch(
                    'dataModule/updateData'
                    , {
                        "url": '/api/v1/user/update',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', result.data.data);
                        emitter.emit('popupType', 'resultPopup1');
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                });
            }
            
            
        });

        const readAgency = (() => {
            const param = {
                "customerName": agencySearch.value,
                "isUse" : true
            };
            
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/customer/getList',
                    "param": param
                }
            ).then(result => {
                agencyData.value = result.data.data.map(item => ({
                    customerName: item.customerName,
                    customerId: item.customerId 
                }));            
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        watch(agencySearch, () => {
            readAgency();
        });

        /**
         * 사용자 분류가 '대리점'이 아닌 경우 대리점명을 '클래시스'로 표시
         * 사용자 분류가 '관리자'인 경우 서브 분류 값은 '관리'로 고정
         */
        // watch(subCode, () => {
        //     if(subCode.value != 11) {
        //         agencyName.value = '클래시스';
        //         customerId.value = 261;
        //         isAgencyList.value = false;
        //     } else {
        //         agencyName.value = null;
        //         isAgencyList.value = true;
        //     }
        // })

        watch(subCode, () => {
            if(subCode.value == 11) {
                agencyName.value = null;
                customerId.value = null;
                isAgencyList.value = true;
            } else {
                agencyName.value = '클래시스';
                customerId.value = 1;
                // customerId.value = 261;
                isAgencyList.value = false;

                if(subCode.value == 1) {
                    authSubList.value = authSubList.value.filter(item => item.subCode == 1)
                } else {
                    authSubList.value = authSubListOri.value;
                }
            }
        });

        watch(userId, () => {
            if(validateId(userId.value)) {
                userIdAlert.value = false;
            } else {
                userIdAlert.value = true;
            }        
        });

        watch(userPw, () => {
            if(validatePw(userPw.value)) {
                userPwAlert.value = false;
            } else {
                userPwAlert.value = true;
            }        
        });

        const cancel = ((popupType) => {
            emitter.emit('popupType', '');
        });

        const openUserPopup2 = (() => {
            userPopup1.value.style.display = 'none';
            userPopup2.value.style.display = 'block';
        });

        const closeUserPopup2 = (() => {
            userPopup2.value.style.display = 'none';
            userPopup1.value.style.display = 'block';
        });
        
        const setAgencyName = (agency) => {
            agencyName.value = agency.customerName;
            customerId.value = agency.customerId;  

            closeUserPopup2();
        };

        return {
            message,
            warning,
            confirm,

            userIdAlert,
            userPwAlert,
            
            readAgency,
            agencyData,
            agencySearch,
            userPopup1,
            userPopup2,

            agencyName,
            isAgencyList,

            subCode,
            codeName,
            authList,
            getAuth,
            
            subCode2,
            codeName2,
            authSubList,
            getAuthSub,

            // userAuth,
            // userAuthSub,
            customerId,
            userId,
            userPw,
            userPwCheck,
            userName,
            phone,
            email,
            address,
            addressDetail,

            createData,
            dataCheck,
            idCheck,
            cancel,
            openUserPopup2,
            closeUserPopup2,
            setAgencyName
        }
    },
}
</script>

<style>

</style>