<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">적용</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            임시적용 되었습니다.
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="cancel" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
export default {
    name: 'ProductDetailPopup1',
    setup() {
        const emitter = inject('emitter')

        // 취소 => 팝업 닫기 
        // + returnFunction에 licenseChange 전달하여 licenseChange 함수 실행
        const cancel = (event) => {
            emitter.emit('popupType', '')
            emitter.emit('returnFunction', 'licenseChange')
        };

        return {
            cancel,
        }
    }
}
</script>

<style>

</style>