<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">제품 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">제품 코드</li>
                            <li class="cont">
                                <input type="text" v-model="modelCode" placeholder="">
                            </li>
                            <li class="tit">제품명</li>
                            <li class="cont">
                                <input type="text" v-model="modelName" placeholder="">
                            </li>
                            <li class="tit">Prefix 코드</li>
                            <li class="cont pbot20">
                                <input type="text" v-model="prefix" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">제품 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'SettingProductPopup1',
    setup() {
        const emitter = inject('emitter');    
        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);

        let executeCheck = ref('i');

        let modelCode  = ref(null);     // 제품 코드
        let modelName  = ref(null);     // 제품명
        let prefix = ref(null);         // PreFix 코드

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        const modelCodeCheck = ref(false);
        const modelNameCheck = ref(false);

        let modelId = ref(null);

        onBeforeMount(() => {
            // getProduct();
        });

        const cancel = () => {
            emitter.emit('popupType', '');
        };

        // 필드 값 확인
        const next = (() => {
            // 제품 코드, 제품명에 대한 중복 체크 필요
            if (!modelCode.value) {
                warning.value = true;
                message.value = '제품 코드를 입력해주세요.';
                return;
            }
            if (!modelName.value) {
                warning.value = true;
                message.value = '제품명을 입력해주세요.';
                return;
            }
            // prefix 코드는 중복 가능
            if (!prefix.value) {
                warning.value = true;
                message.value = 'Prefix 코드를 입력해주세요.';
                return;
            }

            productCodeCheck();
        });

        const execute = () => {
            let param = {
                "insertId": insertId.value,
                "modelCode": modelCode.value,
                "modelName": modelName.value,
                "prefix": prefix.value,
            };

            if(executeCheck.value == 'i') {
                store.dispatch(
                    'dataModule/insertData'
                    , {
                        "url": '/api/v1/model/insert',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', result.data.data);
                        emitter.emit('popupType', 'resultPopup1');
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                })
            } else if(executeCheck.value = 'u') {
                param.modelId = modelId.value;
                param.isUse = true;

                store.dispatch(
                    'dataModule/insertData'
                    , {
                        "url": '/api/v1/model/update',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', result.data.data);
                        emitter.emit('popupType', 'resultPopup1');
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                })
            }
        };
        
        const productCodeCheck = (() => {
            const param = {
                "modelCode": modelCode.value,
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/model/getList',
                    "param": param
                }
            ).then(result => {
                if(result.data.data.length == 0) {
                    productNameCheck();
                } else if(result.data.data.length > 0) {
                    for(const item of result.data.data) {
                        if(item.modelCode == modelCode.value && item.isUse == true) {
                            warning.value = true;
                            message.value = '제품 코드가 중복되었습니다.';
                            modelCodeCheck.value = true;
                            break;
                        } else if(item.modelCode == modelCode.value && item.isUse == false) {
                            executeCheck.value = 'u'
                            modelId.value = item.modelId;
                            modelCodeCheck.value = false;
                            break;
                        }
                    }

                    if(!modelCodeCheck.value) {
                        productNameCheck();
                    }
                }
                modelCodeCheck.value = false;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const productNameCheck = (() => {
            const param = {
                "modelName": modelName.value,
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/model/getList',
                    "param": param
                }
            ).then(result => {
                if(result.data.data.length == 0) {
                    confirm.value = true;
                } else if(result.data.data.length > 0) {
                    for(const item of result.data.data) {
                        if(item.modelName == modelName.value && item.isUse == true) {
                            warning.value = true;
                            message.value = '제품명이 중복되었습니다.';
                            modelNameCheck.value = true;
                            break;
                        } 
                        // else if(item.modelName == modelName.value && item.isUse == false) {
                        //     modelCodeCheck.value = false;
                        //     break;
                        // }
                    }

                    if(!modelNameCheck.value) {
                        confirm.value = true;
                    }
                }

                modelNameCheck.value = false;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        return {
            modelCode,
            modelName,
            prefix,

            message,
            warning,
            confirm,

            cancel,
            next,
            execute,
            productCodeCheck,
            productNameCheck,
        }
    },
}
</script>

<style>

</style>