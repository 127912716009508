<template>
    <div class="popup-box">
        <div class="bg">
            <div v-if="!confirm" class="popup">
                <div class="tit">펌웨어 그룹 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list pbot10">
                            <li class="tit">그룹명</li>
                            <li class="cont">
                                <input type="text" v-model="groupName">
                            </li>
                        </ul>
                        <div class="input-txt-list">
                            <li class="tit">제품명</li>
                            <li class="">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                item-value="modelId"
                                v-model="modelId"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </div>
                    </div>
                    <div class="cont" v-if="partList !== null">
                        <ul v-for="(item) in partList" :key="item" class="input-txt-list pbot10">
                            <li class="tit">{{item.partName}} 펌웨어 버전</li>
                            <li class="cont">
                                <input type="text" v-model="item.firmwareVersion">
                            </li>
                            <li class="tit">{{item.partName}} 펌웨어 URL</li>
                            <li class="cont">
                                <input type="text" v-model="item.url">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
            <div v-else-if="confirm" class="popup">
                <div class="tit">펌웨어 그룹 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import productAuto from '@/js/autocomplete/productAuto';
export default {
    name: 'FirmwarePopup1',
    setup() {
        const emitter = inject('emitter');

        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);

        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);
        let publicParam = ref(null);

        const groupName = ref(null);
        const modelName = ref(null);
        const modelId = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        const partList = ref(null)

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const getDataPart = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/firmware-model/getDataParts',
                    "param": {
                        "modelId": modelId.value,
                        "firmwareModelId":-1
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;
                partList.value = result.data.data.partList;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });
        
        const next = (() => {
            if(groupName.value == '' || groupName.value == null) {
                warning.value = true;
                message.value = '그룹명을 입력해주세요.';
                return;
            }

            if(modelId.value == '' || modelId.value == null) {
                warning.value = true;
                message.value = '제품명을 선택해주세요.';
                return;
            }

            let checkPartList = false;
            partList.value.some(item => {
                if(item.firmwareVersion == '' || item.firmwareVersion == null) {
                    warning.value = true;
                    message.value = '펌웨어 버전을 입력해주세요.';
                    checkPartList = false;
                    return;
                }
                if(item.url == '' || item.url == null) {
                    warning.value = true;
                    message.value = '펌웨어 URL을 입력해주세요.';
                    checkPartList = false;
                    return;
                }
                checkPartList = true;
            });
            if(!checkPartList) {
                return;
            }
            
            confirm.value = true;
        });

        const execute = (() => {
            let param = {...publicParam.value}
            // param.firmwareModel.groupName = groupName.value;
            // param.firmwareModel.insertId = insertId.value;

            param.firmwareModel = {
                "modelId": modelId.value,
                "groupName": groupName.value,
                "insertId": insertId.value
            }

            param.partList.forEach((item) => {
                delete item.firmwareId;
                delete item.firmwareModelId;
                delete item.insertDate;
                delete item.insertId;
                delete item.insertName;
                delete item.isUse;
                delete item.partName;
                delete item.remark;
            });

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/firmware-model/insertData',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        watch(modelId, () => {
            if(modelId.value != null) {
                getDataPart();
            } else {
                publicParam.value = null;
                partList.value = null;
            }
            
        });

        onBeforeMount(() => {
            getProduct();
        })

        return {
            message,
            warning,
            confirm,

            groupName,
            modelName,
            modelId,
            modelNameList,

            partList,

            getProduct,

            cancel,
            next,
            execute,
        }
    },
}
</script>

<style>

</style>