<template>
    <div class="snb main-document-header-container">
        <div class="inner">
            <h1 class="mobile">
                <a href=""></a>
            </h1>
            <div>
                <div class="user-menu pc">
                    <!-- <div class="alert">
                        <a class="" href=""></a>
                    </div> -->
                    <button class="info" @click="toggle = !toggle" v-click-outside="closeDom">
                        <div class="user">
                            <span>{{userName}}</span>
                            <span>님</span>
                        </div>
                        <ul v-if="toggle" class="info-list">
                            <li class="id">
                                <span>ID :</span>
                                <span>{{userId}}</span>
                            </li>
                            <!-- <li>
                                <a href="">내 정보</a>
                            </li> -->
                            <li @click="doLogout">
                                <a @click="doLogout">로그아웃</a>
                            </li>
                        </ul>
                    </button>
                </div>
                
            </div>
            <div class="select-box">
                <select class="gray" name="" id="time" v-model="utcTime"> 
                    <option :value="9">한국기준(UTC-9)</option>
                    <option :value="localTimezone">사용국가기준(UTC-{{localTimezone}})</option>
                    <option :value="0">서버기준(UTC-0)</option>
                </select>
                <button @click="this.emitter.emit('mobileOpen', true)" class="hamburger"></button>
            </div>
        </div>
    </div>
</template>

<script>
// import { mapState } from 'vuex'
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex'
import { inject } from 'vue';
export default {
    name: 'Header',
    setup() {
        // 변수 선언
        let toggle = ref(false);
        
        // store에서 state 값 가져오기
        const store = useStore();
        const userName = computed(() => store.state.profileModule.userName);
        const userId = computed(() => store.state.profileModule.userId);
        const utcTime = computed({
            get() {
                return store.state.utcTime;
            },
            set(value) {
                store.commit('SET_UTC_TIME', value); // 상태를 변경하기 위해 mutation을 사용하였습니다.
            }
        });

        // utcTime.value = 0;

        // loca timezone 설정하기
        const date = new Date();
        const localTimezone = date.getTimezoneOffset() / 60 * -1;

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter')
        
        /**
         * 함수 선언
         */
        // 로그아웃
        const doLogout = (data) => {
            store.dispatch('loginModule/doLogout').then(() => {})
        };

        // 외부 영역 클릭시 '내 정보' 영역 닫기
        const closeDom = () => {
            if(toggle.value) {
                toggle.value = false
            }
        };

        return {
            toggle,
            userName,
            userId,
            doLogout,
            closeDom,
            utcTime,
            localTimezone
        }
    },
}
</script>

<style>

</style>