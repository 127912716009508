<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 편집</div>
                <div class="inner">
                    <div class="cont">
                        <div class="table pbot20 clearfix">
                            <div class="group">
                                <ul>
                                    <li class="table-tit">부품 코드</li>
                                    <li class="table-item">
                                        <span>{{partCode}}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li class="table-tit">부품 종류</li>
                                    <li class="table-item">
                                        <span>{{partTypeName}}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li class="table-tit">제품명</li>
                                    <li class="table-item">
                                        <span>{{modelName}}</span>
                                    </li>
                                </ul>
                            </div>  
                        </div>
                        <ul class="input-txt-list">
                            <li class="tit">부품명</li>
                            <li class="cont">
                                <input type="text" v-model="partName">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 편집</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            데이터를 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, reactive } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'PartDetailPopup1',
    setup() {
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);
        const partData = computed(() => store.state.dataModule.partDetail_data);

        let partCode = ref(null);
        let partTypeName = ref(null);
        let partName = ref(null);
        let modelName = ref(null);
        let insertDate = ref(null);

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);
        
        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(!partName.value) {
                warning.value = true;
                message.value = '부품명을 입력해주세요.';
                return;
            }
            
            confirm.value = true;
        });

        const execute = (() => {
            let param = {...partData.value};
            param.insertId = insertId.value;
            param.partName = partName.value;

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/model-part/update',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });
        
        onBeforeMount(() => {
            partCode.value = partData.value.partCode;
            partTypeName.value = partData.value.partTypeName;
            partName.value = partData.value.partName;
            modelName.value = partData.value.modelName;
            insertDate.value = partData.value.insertDate;
        });

        return {
            partCode,
            partTypeName,
            partName,
            modelName,

            message,
            warning,
            confirm,

            next,
            cancel,
            execute
        }
    }
}
</script>

<style>

</style>