<template>
    <div  v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">국가 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">국가 코드
                                <span class="">2자리 영어로 입력해주세요</span>
                            </li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="refData1" @input="checkRefData1">
                            </li>
                            <li class="tit">국가</li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="codeName">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">국가 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'SettingCountryPopup1',
    setup() {
        // 변수 선언
        const emitter = inject('emitter');
        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);

        let refData1 = ref('');
        let refData2 = ref('');
        let refData3 = ref('');
        let codeName = ref(null);

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        let regexConfirm = ref(false);

        let executeCheck = ref('i');
        let commonId = ref(null);

        // 국가 코드 제약조건 (영어? + 2자리)
        const checkRefData1 = (() => {
            refData1.value = refData1.value.toUpperCase();

            const regex = new RegExp("^[A-Za-z]{2}$");
            if(regex.test(refData1.value)) {
                regexConfirm.value = true;
            } else {
                regexConfirm.value = false;
            }
        });
   
        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(!refData1.value) {
                warning.value = true;
                message.value = '국가 코드를 입력해주세요.';
                return;
            }
            if(!codeName.value) {
                warning.value = true;
                message.value = '국가를 입력해주세요.';
                return;
            }

            if(regexConfirm.value) {
                // 국가 코드, 국가에 대한 정합성 체크
                refData1Check();
            } else {
                warning.value = true;
                message.value = '국가 코드를 정확하게 입력해주세요.';
            }
        });

        const execute = () => {
            let param = [];

            if(executeCheck.value == 'i') {
                param = [{
                    "insertId": insertId.value,
                    "refData1": refData1.value,
                    // "refData2": refData2.value,
                    // "refData3": refData3.value,
                    "codeName": codeName.value,
                    "saveMode": "N",
                }];
            } else if(executeCheck.value == 'u') {
                param = [{
                    "commonId": commonId.value,
                    "insertId": insertId.value,
                    "refData1": refData1.value,
                    "codeName": codeName.value,
                    "saveMode": "U",
                    "isUse": true
                }];
            }

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/common/nationSaveList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        };

        // 국가 코드 체크 (isUse가 true인 경우)
        const refData1Check = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": true,
                        "refData1": refData1.value
                    }
                }
            ).then(result => {
                if(result.data.data.commonId == null) {
                    refData1Check2();
                } else {
                    warning.value = true;
                    message.value = '국가 코드가 중복입니다.';
                    return;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 국가 코드 체크 (isUse가 false 경우)
        const refData1Check2 = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": false,
                        "refData1": refData1.value
                    }
                }
            ).then(result => {
                if(result.data.data.commonId == null) {
                    executeCheck.value = 'i';
                    confirm.value = true;
                } else {
                    commonId.value = result.data.data.commonId;
                    executeCheck.value = 'u'
                    confirm.value = true;
                }
                // codeNameCheck();
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 국가 체크 (isUse가 true인 경우)
        const codeNameCheck = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": true,
                        "codeName": codeName.value
                    }
                }
            ).then(result => {
                if(result.data.data.commonId == null) {
                    confirm.value = true;
                    // codeNameCheck2();
                } else {
                    warning.value = true;
                    message.value = '국가가 중복입니다.';
                    return;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 국가 체크 (isUse가 false 경우)
        const codeNameCheck2 = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": false,
                        "codeName": codeName.value
                    }
                }
            ).then(result => {
                if(result.data.data.commonId == null) {
                    executeCheck.value = 'i'
                    confirm.value = true;
                } else {
                    commonId.value = result.data.data.commonId;
                    executeCheck.value = 'u'
                    confirm.value = true;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // const refData1Check = (() => {
        //     store.dispatch(
        //         'dataModule/getList'
        //         , {
        //             "url": '/api/v1/common/getList',
        //             "param": {
        //                 "mainCode": "N0001",
        //                 "refData1": refData1.value
        //             }
        //         }
        //     ).then(result => {
        //         if(result.data.data.length != 0) {
        //             alert('국가 코드가 중복입니다.');
        //             return;
        //         } else {
        //             confirm.value = true;
        //         }

        //         // if(result.data.data.refData1 != null) {
        //         //     console.log(result.data.data)
        //         //     alert('국가 코드가 중복입니다.');
        //         //     return;
        //         // }
        //         // codeNameCheck()
        //     });
        // });

        // const codeNameCheck = (() => {
        //     store.dispatch(
        //         'dataModule/getList'
        //         , {
        //             "url": '/api/v1/common/getData',
        //             "param": {
        //                 "mainCode": "N0001",
        //                 "codeName": codeName.value
        //             }
        //         }
        //     ).then(result => {
        //         // 성공시
        //         if(result.data.data.codeName != null) {
        //             alert('국가가 중복입니다.');
        //             return;
        //         }
        //         confirm.value = true;
        //     });
        // });

        return {
            refData1,
            refData2,
            refData3,
            codeName,

            message,
            warning,
            confirm,

            checkRefData1,

            cancel,
            next,
            execute
        }
    },
}
</script>

<style>

</style>