<template>
    <div class="contents">
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">내 정보 관리</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="edit = !edit" class="btn-sb-g">편집</button>
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">사용자 분류</li>
                            <li class="table-item">
                                <span>{{userData.userAuthName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">아이디</li>
                            <li class="table-item">
                                <span>{{userData.userId}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">이름</li>
                            <li class="table-item">
                                <input 
                                    type="text" 
                                    v-model="userData.userName" 
                                    :class="{'readonly': !edit}" 
                                    :disabled="!edit">
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">연락처</li>
                            <li class="table-item">
                                <input 
                                    type="text" 
                                    v-model="userData.phone" 
                                    :class="{'readonly': !edit}" 
                                    :disabled="!edit">
                            </li>
                        </ul>
                         <ul>
                            <li class="table-tit">가입 일시</li>
                            <li class="table-item">
                                <span>{{userData.insertDate}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">서브 분류</li>
                            <li class="table-item">
                                <span>{{userData.userAuthSubName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">비밀번호</li>
                            <li class="table-item">
                                <div class="btn-box">
                                    <button @click="openPopup('settingInfoPopup2')" class="btn-mini">비밀번호 변경</button>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">이메일</li>
                            <li class="table-item">
                                <input 
                                    type="text" 
                                    v-model="userData.email" 
                                    :class="{'readonly': !edit}" 
                                    :disabled="!edit">
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">주소</li>
                            <li class="table-item address">
                                <input 
                                    type="text" 
                                    v-model="userData.address" 
                                    :class="{'readonly': !edit}" 
                                    :disabled="!edit">
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <span></span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul class="memo border-b0">
                            <li class="table-tit">메모</li>
                            <li class="table-item">
                                <textarea 
                                    name="" id="" cols="30" rows="10" 
                                    v-model="memo" 
                                    @input="limitTextArea" 
                                    :class="{'readonly': !edit}" 
                                    ></textarea>
                            </li>
                        </ul>
                        <ul class="memo-btn">
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <div class="btn-box multi right">
                                    <button class="btn-sb-g" @click="openPopup('savePopup1')">저장</button>
                                </div>
                            </li>
                        </ul>
                    </div>  
                </div>

                <h3></h3>
                <!-- 연구소 사용자에게만 보여짐 -->
                <!-- <div v-if="userAuth == 2">
                    <h3>제품 목록</h3>
                    <div class="top-bar">
                        <div class="btn-box multi">
                            <button @click="openPopup('settingInfoPopup3')" class="btn-sb-g">추가</button>
                            <button @click="openPopup('deletePopup')" class="btn-sb-g off">삭제</button>
                        </div>
                    </div>
                    <div v-if="totalCount === 0" class="board setting-04 filtering-board">
                        <ul class="list-item filtering-board-item list-item-none">
                            <li>
                                <ul class="item">  
                                    <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div v-if="totalCount !== 0" class="board setting-04 filtering-board">
                        <ul class="list-item filtering-board-tit">
                            <li>
                                <ul class="item">
                                    <li :style="{flex : 1}">
                                        <div class="chk-box">
                                            <input type="checkbox" class="chk_btn" v-model="checkedAll" value="all" id="all">
                                            <label for="all" class="chk"></label>
                                        </div>
                                    </li>
                                    <li v-for="(column) in columnData" :key="column" 
                                        :style="{flex : `${column.flex}`}" 
                                        :title="column.korName">{{column.korName}}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                            <li>
                                <ul class="item">
                                    <li :style="{flex : 1}">
                                        <div class="chk-box">
                                            <input type="checkbox" :id="row.no" class="chk_btn" :value="row.no" v-model="checkList">
                                            <label :for="row.no" class="chk"></label>
                                        </div>
                                    </li>
                                    <li v-for="(column) in columnData" :key="column" 
                                        @touchstart="startTimer(column.korName, row[column.engName])" 
                                        @touchend="clearTimer" 
                                        :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                        :title="row[column.engName]">{{row[column.engName]}}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readList"/>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { validateText } from '@/js/common/regex.js';

import { ref, onBeforeMount, onBeforeUnmount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: 'SettingInfo',
    setup() {
        // 변수 선언
        let edit = ref(false);
        let textAreaInput = ref("");
        let timer = ref(null);
        let checkList = ref([]);
        let columnData = ref([]);
        let rowData = ref([]);
        let userData = ref([]);
        let memo = ref(null);

        let totalCount = ref(1);
        let selectedPage = ref(1);
        let selectedPageSize = ref(5);

        // store에서 state 값 가져오기
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const id = computed(() => store.state.profileModule.id);
        let utcTime = computed(() => store.state.utcTime);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // router 선언
        const router = useRouter();

        /**
         * 함수 선언
         */
        // 메모 길이 제한
        const limitTextArea = () => {
            // if (!validateText(textAreaInput.value)) {
            //     textAreaInput.value = textAreaInput.value.slice(0, 4096);
            // }
            if (!validateText(memo.value)) {
                memo.value = memo.value.slice(0, 4096);
            }
        };
        
        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType)
        });


        // computed 설정
        const checkedAll = computed({
            get: () => {
                checkList.value.sort((a, b) => a - b);
                return rowData.value.length === checkList.value.length;
            },
            set: (e) => {
                if (e) {
                    checkList.value = [];
                    rowData.value.forEach((r) => {
                        checkList.value.push(r.no);
                    });
                } else {
                    checkList.value = [];
                }
            },
        });

        const readUserData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/user/getData',
                    "param": {
                        "id": id.value,
                    }
                }
            ).then(result => {
                userData.value = result.data.data;
                userData.value.insertDate = dateUtils.convertUTC(userData.value.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                memo.value = result.data.data.memo;

                if(userAuth.value == 2 && userData.value.userAuth == 2) {
                    readList(1, 'search', 5);
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const updateUserData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/user/update',
                    "param": {
                        "id": id.value,
                        "insertId": id.value,
                        "userName": String(userData.value.userName),
                        "phone": String(userData.value.phone),
                        "email": String(userData.value.email),
                        "address": String(userData.value.address),
                        "customerId": parseInt(userData.value.customerId),
                        "userAuth": parseInt(userData.value.userAuth),
                        "userAuthSub": parseInt(userData.value.userAuthSub),
                        "remark": String(userData.value.remark),
                        "isUse": true,
                        "memo": String(memo.value),
                    }
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    store.commit('dataModule/SET_USER_DETAIL_POPUP_DATA', null);
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 데이터 저장 후 다시 조회
        const onReData = (data) => {
            if(data) {
                readUserData();
            }
        };

        const readList = ((pageNo, type, pageSize) => {
            if(pageSize == undefined || pageSize == null) {
                pageSize = selectedPageSize.value;
            }
            selectedPage.value = pageNo;
            selectedPageSize.value = pageSize;

            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": {
                        "page": selectedPage.value,
                        "pageSize": selectedPageSize.value,
                        "isUse": true
                        // "salesCustomerId": userData.value.customerId,
                    }
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 1, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 5, align: 'left'},
                    { engName: 'updateDate', korName: '등록일', flex: 10, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

                if(result.data.data.list.length == 0) {
                    totalCount.value = 0;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    confirm.value = false;
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        onBeforeMount(() => {
            readUserData();

            // readList(1);
            // const gridData = getData('settingInfo');
            // columnData.value = gridData.columnDefs;
            // rowData.value = gridData.rowData;
        });

        
        onMounted(() => {
            emitter.on('execute', updateUserData);
            emitter.on('reData', onReData);
        })
        
        onBeforeUnmount(() => {
            emitter.off('execute', updateUserData);
            emitter.off('reData', onReData);
        });
        
        watch(utcTime, () => {
            readUserData();
        });

        return {
            edit,
            textAreaInput,
            timer,
            checkList,
            checkedAll,
            userAuth,
            id,
            readUserData,
            updateUserData,
            // readData,
            readList,
            
            columnData,
            rowData,
            userData,
            memo,

            totalCount,
            selectedPage,
            selectedPageSize,

            limitTextArea,
            startTimer,
            clearTimer,
            openPopup
        }
    },
}
</script>

<style>

</style>