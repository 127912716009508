<template>
    <ul class="board-sub-menu log">
        <li @click="goCartridge">
            <span>카트리지/팁 로그</span>
        </li>
        <li @click="goSystem">
            <span>시스템 로그</span>
        </li>
    </ul>
</template>

<script>
import { inject } from 'vue';
export default {
    name: 'Filtering2',
    props: {
        productSn: String,
    },
    setup(props) {
        const emitter = inject('emitter');

        const goCartridge = (() => {
            emitter.emit('goCartridge', props.productSn);
        });
        const goSystem = (() => {
            emitter.emit('goSystem', props.productSn)
        });

        return {
            goCartridge,
            goSystem
        }
    },
}
</script>

<style>

</style>