import { ref } from 'vue';
import { useStore } from 'vuex';

// customerAuto.js
export default function customerAuto() {
    const store = useStore();

    const customerList = ref([]);

    const getCustomer = () => {
        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/customer/getList',
                "param": {
                    "isUse": true,
                    // "customerId": 183
                }
            }
        ).then(result => {
            customerList.value = result.data.data;

            customerList.value.forEach((item) => {
                item.autoTitle = item.customerCode + ' - ' + item.customerName;
            });

            // prefix 제거
            // customerList.value = modelNameList.value.map(item => {
            //     const {prefix, ...rest} = item;
            //     return rest;
            // });
        });
    }
    
    return {
        customerList,
        getCustomer,
    }
}