<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">사용자 분류 변경</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                <p>사용자 분류</p>
                            </li>
                            <li class="cont">
                                <div class="select-box block">
                                    <select class="" name="" id="">
                                        <option value="11">대리점</option>
                                        <option value="1">관리자</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingInfoPopup1',
    methods: {
        cancel() {
            this.emitter.emit('popupType', '')
        }
    },
}
</script>

<style>

</style>