import { ref } from 'vue';
import { useStore } from 'vuex';

// licenseGroupAuto.js
export default function licenseGroupAuto() {
    const store = useStore();

    const licenseGroupList = ref([]);
    const selectedLicenseGroupList = ref(null);

    const getLicenseGroup = (_licenseId, licenseGroupYn) => {
        let param = {
            "isUse": true
        }

        // if(_licenseId != null) {
        //     param.licenseId = _licenseId;
        // }

        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/license/getList',
                "param": param
            }
        ).then(result => {
            licenseGroupList.value = result.data.data;

            if(_licenseId != null && _licenseId != undefined && _licenseId != '') {
                if(licenseGroupYn == true) {
                    selectedLicenseGroupList.value = (result.data.data.filter(item => item.licenseId == _licenseId))[0]
                }
            }
        });
    }
    
    return {
        licenseGroupList,
        selectedLicenseGroupList,
        getLicenseGroup,
    }
}