<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <!-- <button @click="cancel" class="btn-sb">취소</button> -->
                        <button @click="this.emitter.emit('popupType', 'agencyDetailPopup1')" class="btn-sb">취소</button>
                        <button class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SavePopup2',
    methods: {
        cancel() {
            this.emitter.emit('popupType', '')
        }
    },
}
</script>

<style>

</style>