<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">대리점 코드 변경 신청 완료</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            대리점 코드 변경 요청이<br>정상적으로 처리되었습니다.
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="cancel" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AgencyCodeChangePopup1',
    methods: {
        cancel() {
            this.emitter.emit('popupType', '')
        }
    },
}
</script>

<style>

</style>