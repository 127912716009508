<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">결과</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, onUpdated, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
    name: 'ResultPopup',
    props: {
        message: String,
    },
    setup(props) {
        // 결과 메시지
        const message = ref(props.message);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // router 선언
        const router = useRouter();

        const execute = (() => {
            emitter.emit('goMenu', true);
            emitter.emit('popupType', '');
        });

        onMounted(() => {
            if(message.value == '' || message.value == null) {
                message.value = '데이터 처리가 완료되었습니다.'
            }
        })

        return {
            message,
            execute
        }
    },
}
</script>

<style>

</style>