<template>
    <div class="contents">
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">메일 알림 설정</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="board mail">
                    <ul class="list-tit">
                        <li>기능</li>
                        <!-- <li>사용 여부</li> -->
                    </ul>
                    <ul class="list-item">
                        <li>
                            <ul class="item">
                                <li>
                                    <span>누적 샷</span>
                                    <div class="select-box">
                                        <select class="select-temper" v-model="accumulateShot" @change="updateAlarm('accumulateShot')">
                                            <option :value=100000>100,000</option>
                                            <option :value=50000>50,000</option>
                                            <option :value=40000>40,000</option>
                                            <option :value=30000>30,000</option>
                                            <option :value=20000>20,000</option>
                                            <option :value=10000>10,000</option>
                                        </select>
                                    </div>
                                    <span>회 이상시 알림 메일 발송</span>
                                </li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch1" v-model="isMail1" class="switch-input" @change="updateAlarm('accumulateShot')">
                                        <label for="switch1" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <!-- <ul class="item">
                                <li>해킹 시도 감지 시(베이스 보드의 해킹 마크 검사) 알림 메일 발송</li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch2" class="switch-input">
                                        <label for="switch2" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <ul class="item">
                                <li>
                                    <span>핸드 피스 연결 실패 횟수</span>
                                    <div class="select-box">
                                        <select>
                                            <option value="">100</option>
                                            <option value="">50</option>
                                            <option value="">40</option>
                                            <option value="">30</option>
                                        </select>
                                    </div>
                                    <span>회 이상 시 알림 메일 발송</span>
                                </li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch3" class="switch-input">
                                        <label for="switch3" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul> -->
                            <ul class="item">
                                <li>
                                    <span>본체 내부 온도 60℃ 이상 감지 시 알림 메일 발송</span>
                                    <!-- <div class="select-box">
                                        <select>
                                            <option value="60">60</option>
                                        </select>
                                    </div> -->
                                    <!-- <span>℃ 이상 감지 시 알림 메일 발송</span> -->
                                </li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch4" v-model="isMail2" class="switch-input" @change="updateAlarm('inTemper')">
                                        <label for="switch4" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <!-- <ul class="item">
                                <li>카트리지의 다중 사용 감지 시(같은 시리얼의 카트리지를 여러 곳의 병원에tj 사용 시)</li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch5" class="switch-input">
                                        <label for="switch5" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <ul class="item">
                                <li>카트리지의 남은 샷이 증가 되었을 경우</li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch6" class="switch-input">
                                        <label for="switch6" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <ul class="item">
                                <li>
                                    <span>남은 </span>
                                    <div class="select-box">
                                        <select>
                                            <option value="">1,000</option>
                                            <option value="">500</option>
                                            <option value="">400</option>
                                            <option value="">300</option>
                                        </select>
                                    </div>
                                    <span>회 이하 시 알림 메일 발송</span>
                                </li>
                                <li>
                                    <div class="switch-box">
                                        <input type="checkbox" id="switch7" class="switch-input">
                                        <label for="switch7" class="switch-label">
                                            <span class="onf-btn"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, onMounted, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
export default {
    name: 'Mail',
    setup() {
        // 변수 선언

        // store
        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);
        const userAuth = computed(() => store.state.loginModule.userAuth);
        let utcTime = computed(() => store.state.utcTime);
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const publicParam1 = ref(null);
        const publicParam2 = ref(null);

        const accumulateShot = ref(null);
        const isMail1 = ref(null);

        const inTemper = ref(null);
        const isMail2 = ref(null);

        const loadData = ref(false);

        const readData = (() => {
            let param = {

            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/alarm/getList',
                    "param": param
                }
            ).then(result => {
                result.data.data.some(item => {
                    if(item.issueType == 1) {
                        publicParam1.value = item;

                        accumulateShot.value = item.issueValue;
                        isMail1.value = item.isMail;
                    } else if(item.issueType == 2) {
                        publicParam2.value = item;

                        inTemper.value = item.issueValue;
                        isMail2.value = item.isMail;
                    }
                });
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const updateAlarm = ((type) => {
            let param = {};

            if(type == 'accumulateShot') {
                param = {...publicParam1.value};
                param.isMail = isMail1.value;
                param.issueValue = accumulateShot.value;
            } else if(type == 'inTemper') {
                param = {...publicParam2.value};
                param.isMail = isMail2.value;
                param.issueValue = inTemper.value;
            }
            param.insertId = insertId.value;

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/alarm/update',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // watch(accumulateShot, () => {
        //     // updateAlarm('accumulateShot');
        // });

        watch(utcTime, () => {
            // readData();
        });

        onBeforeMount(() => {
            
        });

        onMounted(() => {
            readData();
        });

        onBeforeUnmount(() => {
        });

        return {
            userAuth,

            accumulateShot,
            isMail1,
            
            inTemper,
            isMail2,

            updateAlarm,

            readData,
        }
    },
}
</script>

<style>

</style>