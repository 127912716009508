<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">사용자 분류 변경</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                <p>사용자 분류</p>
                            </li>
                            <li class="pbot10">
                                <v-autocomplete
                                :items="authList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="subCode"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                :disabled="userAuthYn"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">
                                <p>서브 분류</p>
                            </li>
                           <li class="pbot10">
                                <v-autocomplete
                                :items="authSubList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="subCode2"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                :disabled="userAuthYn"
                                ></v-autocomplete>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">사용자 분류 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import authAuto from '@/js/autocomplete/authAuto';
export default {
    name: 'UserDetailPopup1',
    setup(props) {
        const emitter = inject('emitter');

        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);
        const userData = computed(() => store.state.dataModule.userDetailPopup_data);
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userAuthYn = ref(false);

        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);

        const cancel = (() => {
            emitter.emit('popupType', '');
        });
        
        const subCode = ref(null);
        const codeName = ref(null);
        const subCode2 = ref(null);
        const codeName2 = ref(null);
        const {
            authList,
            getAuth,
            authSubList,
            authSubListOri,
            getAuthSub,
        } = authAuto();

        const next = (() => {
            // if(!subCode.value || !codeName.value) {
            //     warning.value = true;
            //     message.value = '사용자 분류를 선택해주세요.';
            //     return;
            // }

            // if(!subCode2.value || !codeName2.value) {
            //     warning.value = true;
            //     message.value = '서브 분류를 선택해주세요.';
            //     return;
            // }

            if(subCode.value === null || subCode.value === '') {
                warning.value = true;
                message.value = '사용자 분류를 선택해주세요.';
                return;
            }

            if(subCode2.value === null || subCode2.value === '') {
                warning.value = true;
                message.value = '서브 분류를 선택해주세요.';
                return;
            }

            if(userAuthYn.value) {
                warning.value = true;
                message.value = '최고관리자 권한은 최고관리자 계정으로만 변경이 가능합니다.';
                return;
            }

            confirm.value = true;
        });

        const execute = (() => {
            const param = {
                "id": userData.value.id,
                // "insertId": userData.value.insertId,
                "insertId": insertId.value,
                "userName": userData.value.userName,
                "phone": userData.value.phone,
                "email": userData.value.email,
                "address": userData.value.address,
                "customerId": userData.value.customerId,
                "userAuth": subCode.value,
                "userAuthName": codeName.value,
                "userAuthSub": subCode2.value,
                "userAuthName": codeName2.value,
                "remark": userData.value.remark,
                "isUse": true,
                "memo": userData.value.memo,
            };

            store.dispatch(
                'dataModule/updateData', 
                {
                    "url": '/api/v1/user/update',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    store.commit('dataModule/SET_USER_DETAIL_POPUP_DATA', null);
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        watch(subCode, () => {
            if(subCode.value == 1) {
                authSubList.value = authSubList.value.filter(item => item.subCode == 1)
                subCode2.value = 1;
                // codeName2.value = '관리'
            } else {
                authSubList.value = authSubListOri.value;
            }
        });

        onBeforeMount(() => {
            // getAuth(userData.value.userAuth);
            // getAuth(userAuth.value);
            // getAuth();
            getAuthSub();

            if(userAuth.value != 0) {
                if(userData.value.userAuth == 0) {
                    getAuth();
                    userAuthYn.value = true;
                } else {
                    getAuth(userAuth.value);
                }
            } else {
                getAuth();
            }

            // 사용자 분류(권한) 세팅
            codeName.value = userData.value.userAuthName;
            subCode.value = userData.value.userAuth;

            // 서브 분류 세팅
            codeName2.value = userData.value.userAuthSubName;
            subCode2.value = userData.value.userAuthSub;
        });

        return {
            subCode,
            codeName,
            authList,
            getAuth,
            
            subCode2,
            codeName2,
            authSubList,
            getAuthSub,

            message,
            warning,
            confirm,

            userAuthYn,

            cancel,
            next,
            execute,
        }  
    },
}
</script>

<style>

</style>