<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">펌웨어 관리</div>
                <div class="inner">
                    <div class="cont">
                        <div class="chk-box pbot20">
                            <input type="checkbox" v-model="firmwareGroupYn" id="firmwareGroupYn" class="chk_btn" @change="activeFirmwareGroup">
                            <label for="firmwareGroupYn" class="chk">
                                <span>펌웨어 그룹 활성화</span>
                            </label>
                        </div>
                        <div class="select-box pbot20">
                            <div class="tit">펌웨어 그룹</div>
                            <ul class="input-txt-list">
                                <li v-if="firmwareGroupYn == false" class="cont">
                                    <input 
                                        type="text" 
                                        :class="{'readonly': !firmwareGroupYn}"
                                        :disabled="!firmwareGroupYn">
                                </li>
                                <li v-if="firmwareGroupYn == true">
                                    <v-autocomplete
                                    :items="firmwareList"
                                    item-title="groupName"
                                    v-model="selectedFirmwareList"
                                    prepend-inner-icon="mdi-magnify"
                                    theme="light"
                                    variant="outlined"
                                    item-props
                                    return-object
                                    :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                    :disabled="!firmwareGroupYn"
                                    ></v-autocomplete>
                                </li>
                            </ul>
                        </div>

                        <ul v-for="(item) in productPartArray" :key="item" class="input-txt-list pbot20">
                            <li class="tit">{{item.partName}} 펌웨어 버전</li>
                            <li class="cont">
                                <input 
                                    type="text" 
                                    v-model="item.firmwareVersion" 
                                    :class="{'readonly': firmwareGroupYn}" 
                                    :disabled="firmwareGroupYn">
                            </li>
                            <li class="tit">{{item.partName}} 펌웨어 URL</li>
                            <li class="cont">
                                <input 
                                    type="text" 
                                    v-model="item.url" 
                                    :class="{'readonly': firmwareGroupYn}" 
                                    :disabled="firmwareGroupYn">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">펌웨어 관리</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            펌웨어 관리 정보를 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import firmwareAuto from '@/js/autocomplete/firmwareAuto';
export default {
    name: 'ProductDetailPopup6',
    setup(props) {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // store에서 데이터 가져오기
        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);
        const productSn = computed(() => store.state.dataModule.productDetail_productSn);
        const productId = computed(() => store.state.dataModule.productDetail_productId);
        const modelId = ref(null);

        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);

        let publicParam = ref(null);
        const firmwareGroupYn = ref(false);      // 라이선스 그룹 활성화
        
        let productPartArrayParam = ref([]);
        let productPartArray = ref([]);
        
        const groupName = ref(null);
        const firmwareModelId = ref(null);

        const {
            firmwareList,
            getFirmware,
            selectedFirmwareList
        } = firmwareAuto();

        // 펌웨어 그룹 활성화 이벤트
        const activeFirmwareGroup = (() => {
            // 기존에 입력한 데이터들 초기화
            // firmwareModelId.value = null;
        })

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(firmwareModelId.value == null && firmwareGroupYn.value == true) {
                warning.value = true;
                message.value = '펌웨어 그룹을 선택해주세요.';
                return;
            }
            confirm.value = true;
        });

        const execute = (() => {
            let param = {
                // "firmwareModelId": firmwareModelId.value,
                "productId": publicParam.value.productId,
                "productSn": publicParam.value.productSn,
                "modelName": publicParam.value.modelName,
                "insertId": insertId.value,
                "partList": [...productPartArray.value],
                // "insertId": publicParam.value.insertId,
                // "partList": [...productPartArrayParam.value]
            }

            if(firmwareModelId.value !== null) {
                param.firmwareModelId = firmwareModelId.value;
            }

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/product/firmwareUpdate',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readData = (()=> {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/getData',
                    "param": {
                        "productSn": productSn.value,
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;

                if(result.data.data.firmwareModelId != null) {
                    firmwareGroupYn.value = true;
                }

                getFirmware(result.data.data.modelId, firmwareGroupYn.value, result.data.data.firmwareModelId);

                // selectedFirmwareList.value = firmwareList.value.filter(item => item.modelId == result.data.data.modelId)[0];
                // getProductPart();

                if(result.data.data.firmwareModelId !== null && result.data.data.firmwareModelId !== undefined) {
                    // getDataParts(result.data.data.firmwareModelId, result.data.data.modelId);
                    getFirmwareModelPart(result.data.data.firmwareModelId, result.data.data.modelId);
                } else {
                    // getProductPart(1);
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const getProductPart = ((type) => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product-part/getList',
                    "param": {
                        "productId": productId.value,
                        // "productId": publicParam.value.productId,
                        // "productSn": productSn.value,
                    }
                }
            ).then(result => {
                productPartArrayParam.value = result.data.data;
                productPartArray.value = result.data.data;

                // productPartArrayParam.value = result.data.data;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // const getDataParts = ((firmwareModelId, modelId) => {
        //     store.dispatch(
        //         'dataModule/getData'
        //         , {
        //             "url": '/api/v1/firmware-model/getDataParts',
        //             "param": {
        //                 "firmwareModelId": firmwareModelId,
        //                 "modelId": modelId
        //             }
        //         }
        //     ).then(result => {
        //         productPartArray.value = result.data.data.partList;
        //     }).catch(error => {
        //         if(error.response.status == 400) {
        //             emitter.emit('message', error.response.data.data);
        //             emitter.emit('popupType', 'resultPopup3');
        //         }
        //     });
        // });

        const getFirmwareModelPart = ((firmwareModelId, modelId) => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/firmware-model-part/getList',
                    "param": {
                        "isUse": true,
                        "firmwareModelId": firmwareModelId,
                        // "modelId": modelId
                    }
                }
            ).then(result => {
                // productPartArray.value = result.data.data;

                productPartArray.value = [...productPartArrayParam.value].map(item1 => {
                    const matchItem = [...result.data.data].find(item2 => item2.partCode == item1.partCode);
                    if (matchItem) {
                        const updatedItem = {
                        ...item1,
                        ...Object.fromEntries(Object.entries(matchItem).filter(([key]) => item1.hasOwnProperty(key)))
                        };
                        return updatedItem;
                    } else {
                        return item1;
                    }
                });
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });


        watch(selectedFirmwareList, (item) => {
            if(item != null) {
                firmwareModelId.value = item.firmwareModelId;

                // getDataParts(item.firmwareModelId, item.modelId);
                getFirmwareModelPart(item.firmwareModelId, item.modelId);
            } else {
                firmwareModelId.value = null;
                // productPartArray.value = null;
            }
        });

        watch(firmwareGroupYn, (item) => {
            if(item == false) {
                if(selectedFirmwareList.value != null) {
                    firmwareModelId.value = null;
                } else if(selectedFirmwareList.value == null) {
                    getProductPart();
                }
            } else {
                // productPartArray.value = null;
            }
        });

        onBeforeMount(() => {
            getFirmware();
            getProductPart();
        });

        onMounted(() => {
            readData(); 
        });

        return {
            message,
            warning,
            confirm,

            publicParam,
            firmwareGroupYn,

            groupName,
            firmwareModelId,
            firmwareList,
            getFirmware,
            selectedFirmwareList,

            productPartArray,

            activeFirmwareGroup,
            cancel,
            next,
            execute,
        }
    }
}
</script>

<style>

</style>