<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">라이선스 그룹 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list pbot20">
                            <li class="tit">그룹명</li>
                            <li class="cont">
                                <input type="text" v-model="licenseName" placeholder="">
                            </li>
                        </ul>
                        <div class="chk-box pbot20 filtering">
                            <div class="tit">라이선스 권한</div>
                            <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" checked="">
                            <label for="licenseIsUse" class="chk">
                                <span>사용 여부</span>
                            </label>
                            <input type="checkbox" v-model="isServer" id="isServer" class="chk_btn" checked="">
                            <label for="isServer" class="chk">
                                <span>서버 연동</span>
                            </label>
                        </div>
                        <ul class="input-txt-list">
                            <li class="tit">라이선스 만료일</li>
                            <li class="cont">
                                <input type="date" v-model="expirationDate" @input="setDate('direct')">
                                <!-- <input type="date" v-model="expirationDate" placeholder=""> -->
                            </li>
                        </ul>
                        <div class="btn-box tab pbot20">
                            <button @click="setDate('month', 1)" class="btn-sb-g" :class="{'active': expirationButton == 'm1'}">1달</button>
                            <button @click="setDate('month', 3)" class="btn-sb-g" :class="{'active': expirationButton == 'm3'}">3달</button>
                            <button @click="setDate('month', 6)" class="btn-sb-g" :class="{'active': expirationButton == 'm6'}">6달</button>
                            <button @click="setDate('year', 1)" class="btn-sb-g" :class="{'active': expirationButton == 'y1'}">1년</button>
                            <button @click="setDate('year', 3)" class="btn-sb-g" :class="{'active': expirationButton == 'y3'}">3년</button>
                            <button @click="setDate('none', 999999)" class="btn-sb-g" :class="{'active': expirationButton == 'n'}">영구</button>
                        </div>
                        <ul class="input-txt-list">
                            <li class="tit">오프라인 유효기간(일)</li>
                            <li class="cont">
                                <input type="number" v-model.number="offlineExpirationDay" @input="checkOfflineExpirationDay" placeholder="숫자만 입력 가능 0~1,000,000 (일반적으로 90)">
                            </li>
                            <li class="tit">유효기간 만기안내(일)</li>
                            <li class="cont">
                                <input type="number" v-model.number="offlineExpirationAlarm" @input="checkOfflineExpirationAlarm" placeholder="숫자만 입력 가능 0~1,000,000 (일반적으로 7)">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">국가 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, reactive } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import { validateOfflineExpirationDate } from '@/js/common/regex.js';
export default {
    name: 'LicenseGroupPopup1',
    setup() {
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // store
        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);

        let licenseName = ref(null);            // 그룹명
        let licenseIsUse = ref(false);          // 사용 여부
        let isServer = ref(false);              // 서버 연동
        let expirationDay = ref(null);          // 만료일(일)
        let expirationDate = ref(null);         // 라이선스 만료일
        let offlineExpirationDay = ref(0);      // 오프라인 유효기간(일)
        let offlineExpirationAlarm = ref(0);    // 유효기간 만기안내(일)
        const expirationButton = ref(null);       // 라이선스 만료일 버튼 제어

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        // 라이선스 만료일 설정
        const setDate = ((type, num) => {
            if(type == 'month') {
                let returnDate = dateUtils.changeMonthDate(num);
                expirationDate.value = returnDate.dateString;
                expirationDay.value = returnDate.day;
                expirationButton.value = 'm'+num;
            } else if(type == 'year') {
                let returnDate = dateUtils.changeYearDate(num);
                expirationDate.value = returnDate.dateString
                expirationDay.value = returnDate.day;
                expirationButton.value = 'y'+num;
            } else if(type == 'none') {
                expirationDate.value = '2127-12-31';
                expirationDay.value = num;
                expirationButton.value = 'n';
            } else if(type == 'direct') {
                expirationDay.value = dateUtils.getCurrentDate(expirationDate.value)
                expirationButton.value = null;
            }
        });

        // 오프라인 유효기간(일) 제약조건
        const checkOfflineExpirationDay = (() => {
            offlineExpirationDay.value = parseInt(offlineExpirationDay.value, 10).toString();
            if(isNaN(offlineExpirationDay.value)) offlineExpirationDay.value = 0;
            if (!validateOfflineExpirationDate(offlineExpirationDay.value)) {
                offlineExpirationDay.value = 1000000;
            }
        });

        // 유효기간 만기안내(일)
        const checkOfflineExpirationAlarm = (() => {
            offlineExpirationAlarm.value = parseInt(offlineExpirationAlarm.value, 10).toString();
            if(isNaN(offlineExpirationAlarm.value)) offlineExpirationAlarm.value = 0;
            if (!validateOfflineExpirationDate(offlineExpirationAlarm.value)) {
                offlineExpirationAlarm.value = 1000000;
            }
        });

        // 취소 => 팝업 닫기
        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(licenseName.value == null || licenseName.value == '') {
                warning.value = true;
                message.value = '그룹명을 입력해주세요.';
                return;
            }
            if(expirationDate.value == null || expirationDate.value == '' 
                || expirationDay.value == null || expirationDay.value == '') {
                warning.value = true;
                message.value = '라이선스 만료일을 선택해주세요.';
                return;
            }
            if(offlineExpirationDay.value == null || offlineExpirationDay.value == undefined) {
                warning.value = true;
                message.value = '오프라인 유효기간(일)을 입력해주세요.';
                return;
            }
            if(offlineExpirationAlarm.value == null || offlineExpirationAlarm.value == undefined) {
                warning.value = true;
                message.value = '유효기간 만기안내(일)을 입력해주세요.';
                return;
            }

            // 라이선스 그룹 중복 여부 확인
            duplicateCheck();
        });

        // 확인 => 라이선스 그룹 추가
        const execute = (() => {    
            let param = {
                "insertId": insertId.value,
                "licenseName": licenseName.value,
                "licenseIsUse": licenseIsUse.value,
                "isServer": isServer.value,
                "expirationDay": expirationDay.value,
                "expirationDate": expirationDate.value,
                "offlineExpirationDay": offlineExpirationDay.value,
                "offlineExpirationAlarm": offlineExpirationAlarm.value,
                "isUse": true,
            };
            
            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/license/insert',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const duplicateCheck = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/license/getData',
                    "param": {
                        "licenseName": licenseName.value
                    }
                }
            ).then(result => {
                if(result.data.data == null) {
                    confirm.value = true
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        return {
            insertId,
            licenseName,
            licenseIsUse,
            isServer,
            expirationDay,
            expirationDate,
            offlineExpirationDay,
            offlineExpirationAlarm,
            expirationButton,

            message,
            warning,
            confirm,

            checkOfflineExpirationDay,
            checkOfflineExpirationAlarm,

            setDate,
            cancel,
            next,
            execute,
        }
    },
}
</script>

<style>

</style>