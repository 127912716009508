<template>
  <router-view></router-view>
</template>

<script>
// Test CodeReview 동작하는지 확인 커밋
// CodeReview 동작하는지 확인 커밋2
export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
/* #app {
} */
</style>
