import { ref } from 'vue';
import { useStore } from 'vuex';

// firmwareAuto.js
export default function firmwareAuto() {
    const store = useStore();

    const firmwareList = ref([]);

    const selectedFirmwareList = ref(null);

    const getFirmware = (_modelId, firmwareGroupYn, _firmwareModelId) => {
        let param = {
            "isUse": true
        }
        if(_modelId != null && _modelId != undefined && _modelId != '') {
            param.modelId = _modelId;
        }

        // 상세 검색 => 제품명
        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/firmware-model/getList',
                "param": param
            }
        ).then(result => {
            firmwareList.value = result.data.data;

            if(_modelId != null && _modelId != undefined && _modelId != '') {
                if(firmwareGroupYn == true) {
                    // selectedFirmwareList.value = result.data.data[0];
                    if(_firmwareModelId !== undefined && _firmwareModelId !== null) {
                        selectedFirmwareList.value = (result.data.data.filter(item => item.firmwareModelId == _firmwareModelId))[0]
                    }
                    
                }
            }
            
            // if(_firmwareModelId != null) {
            //     let getFirmwareModelName = [...firmwareGroup.value].filter(key => key.firmwareModelId === _firmwareModelId);
            //     firmwareModelName.value = getFirmwareModelName[0].groupName;
            //     selectedFirmwareModelId.value = firmwareModelId;
            // }
        });
    }
    
    return {
        firmwareList,
        selectedFirmwareList,
        getFirmware,
    }
}