<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">대리점명</li>
                        <li class="cont">
                            <input type="text" v-model="customerName">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">이름</li>
                        <li class="cont">
                            <input type="text" v-model="userName">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">아이디</li>
                        <li class="cont">
                            <input type="text" v-model="userId">
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">사용자 관리</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="openPopup('userPopup1')" class="btn-sb-g">추가</button>
                        <!-- <button v-if="prodfile_userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="userAuth == 0" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board setting-03 filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board user filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    :style="{flex : `${column.flex}`}" 
                                    :title="column.korName">{{column.korName}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item pointer">
                                <li v-for="(column) in columnData" :key="column" 
                                    @mousemove="isDragging = true;"
                                    @mousedown="isDragging = false;"
                                    @click="moveDetail(row.id)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, reactive, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import { downloadExcel } from '@/js/excelHelper.js';
export default {
    name: 'User',
    setup() {
        // 변수 선언
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_user || 10);
        let totalCount = ref(1);

        let activeParam = ref(null);                    // 페이지 이동시 사용할 param
        let customerName = ref(null);
        let userName = ref(null);
        let userId = ref(null);

        let searchPanelOn = ref(false);
        let timer = ref(null);

        let columnData = ref([]);
        let rowData = ref([]);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const prodfile_userId = computed(() => store.state.profileModule.userId);
        let utcTime = computed(() => store.state.utcTime);

        // 상세보기에서 목록으로 돌아올 때 사용할 param
        const returnParam = computed(() => store.state.dataModule.returnParam);
        let sendParam = ref({});
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // router
        const router = useRouter();

        // 드래그 여부 확인
        const isDragging = ref(false);

        // 상세보기 화면으로 이동
        const moveDetail = ((id) => {
            if(isDragging.value == true) {
                return;
            }
            store.commit('dataModule/SET_USER_DETAIL_ID', id);
            router.push('/userDetail');
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType)
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_USER', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "customerName": customerName.value,
                    "userName": userName.value,
                    "userId": userId.value,
                    "isUse" : true
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/user/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;

                // 상세보기에서 목록으로 돌아올 때 사용할 검색 조건 저장
                store.commit('dataModule/SET_RETURN_PARAM', sendParam.value);

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'userAuthName', korName: '사용자분류', flex: 3, align: 'left'},
                    { engName: 'customerName', korName: '대리점명', flex: 8, align: 'left'},
                    { engName: 'userName', korName: '이름', flex: 8, align: 'left'},  // 이름은 center이지만 현재 길이가 정해져있지 않아서 left로 수정
                    { engName: 'userId', korName: '아이디', flex: 4, align: 'left'},
                    { engName: 'insertDate', korName: '등록일시', flex: 7, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value),
                    item.updateDate = dateUtils.convertUTC(item.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })

            closeSearchPanel();
        });

        const refresh = (() => {
            customerName.value = null;
            userName.value = null;
            userId.value = null;
        });

        // 추가 후 다시 재검색
        const onReData = (data) => {
            if(data) {
                readData(selectedPage.value, "search", null);
            }
        };

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            let engNames = columnData._value.map(item => item.engName);
            let korNames = columnData._value.map(item => item.korName);
            let width = 35;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;
            
            try {
                const result = await store.dispatch('dataModule/getList', {
                    "url": '/api/v1/user/getPageList',
                    "param": param
                });
                const dataList = result.data.data.list;

                const filename = "사용자_관리.xlsx"; // 원하는 파일 이름
                const sheetname = "사용자 관리";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

        onMounted(() => {
            // 상세보기에서 목록으로 돌아온 경우
            if(returnParam.value == null) {
                readData(1, "search");
            } else {
                const page = returnParam.value.page;
                const pageSize = returnParam.value.pageSize;
                customerName.value = returnParam.value.customerName;
                userName.value = returnParam.value.userName;
                userId.value = returnParam.value.userId;

                // 한번 적용 후 null 처리
                store.commit('dataModule/SET_RETURN_PARAM', null);

                readData(page, "search");
            }

            emitter.on('reData', onReData);
            resizeObserver.observe(document.querySelector(".search-panel"));
        });

        const onBeforeUnmountHandler = () => {
            emitter.off('reData', onReData);
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };

        onBeforeUnmount(onBeforeUnmountHandler);

        onBeforeMount(() => {
        });

        return {
            selectedPage,
            selectedPageSize,
            totalCount,

            userAuth,
            prodfile_userId,

            readData,
            refresh,
            activeParam,
            customerName,
            userName,
            userId,

            searchPanelOn,
            timer,

            columnData,
            rowData,

            moveDetail,
            isDragging,
            
            startTimer,
            clearTimer,
            openPopup,
            fetchDataAndDownload,

            toggleSearchPanel
        }
    },
}
</script>

<style>

</style>