<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">라이선스 변경</div>
                <div class="inner">
                    <div class="cont">
                        <li class="cont pbot20">
                            <div class="select-box block">
                                <div class="tit">라이선스 그룹</div>
                                <ul class="input-txt-list">
                                    <li class="">
                                        <v-autocomplete
                                        :items="licenseGroupList"
                                        item-title="licenseName"
                                        v-model="selectedLicenseGroupList"
                                        prepend-inner-icon="mdi-magnify"
                                        theme="light"
                                        variant="outlined"
                                        item-props
                                        return-object
                                        :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                        ></v-autocomplete>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <div class="chk-box pbot20 filtering">
                            <div class="tit">라이선스 권한</div>
                            <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" :disabled="true">
                            <label for="licenseIsUse" class="chk readonly">
                                <span>사용 여부</span>
                            </label>
                            <input type="checkbox" v-model="licenseIsServer" id="licenseIsServer" class="chk_btn" :disabled="true">
                            <label for="licenseIsServer" class="chk readonly">
                                <span>서버 연동</span>
                            </label>
                        </div>
                        <div class="table pbot10 clearfix">
                            <div class="group">
                                <ul>
                                    <li class="table-tit">라이선스 만료일</li>
                                    <li class="table-item">
                                        <span>{{expirationDate}}</span>
                                    </li>
                                </ul>
                            </div>  
                        </div>
                        <div class="table pbot20 clearfix">
                            <div class="group">
                                <ul>
                                    <li class="table-tit">오프라인 유효기간</li>
                                    <li class="table-item">
                                        <span v-if="offlineExpirationDay != null">{{offlineExpirationDay}}일</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li class="table-tit">유효기간 만기안내</li>
                                    <li class="table-item">
                                        <span v-if="offlineExpirationAlarm != null">{{offlineExpirationAlarm}}일</span>
                                    </li>
                                </ul>
                            </div>  
                        </div>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            라이선스 그룹을 선택해주세요.
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import licenseGroupAuto from '@/js/autocomplete/licenseGroupAuto';
export default {
    name: 'ProductPopup1',
    setup(props) {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // store에서 데이터 가져오기
        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);
        const productIdList = computed(() => store.state.dataModule.productPopup_checkList);

        let warning = ref(false);
        let confirm = ref(false);
        
        const licenseId = ref(null);
        const licenseName = ref(null);
        const expirationDate = ref(null);
        const offlineExpirationDay = ref(null);
        const offlineExpirationMin = ref(null);
        const offlineExpirationAlarm = ref(null);
        const licenseIsUse = ref(null);
        const licenseIsServer = ref(null);

        const selectedLicenseGroupList = ref([]);

        // 라이선스 그룹
        const {
            licenseGroupList,
            getLicenseGroup,
        } = licenseGroupAuto();

        const cancel = (() => {
            emitter.emit('popupType', '')
        });

        const next = (() => {
            if(licenseId.value == null || licenseId.value == '') {
                warning.value = true;
                return;
            }
            confirm.value = true;
        });

        const execute = (() => {
            let param = {
                "licenseId": licenseId.value,
                "productIds": productIdList.value,
                "insertId": insertId.value
            };

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/product/licenseChange',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        watch(selectedLicenseGroupList, (item) => {
            if(item != null) {
                licenseId.value = item.licenseId;
                licenseName.value = item.licenseName;
                expirationDate.value = item.expirationDate;
                offlineExpirationDay.value = item.offlineExpirationDay;
                offlineExpirationMin.value = item.offlineExpirationMin;
                offlineExpirationAlarm.value = item.offlineExpirationAlarm;
                // licenseIsUse.value = item.isUse;
                licenseIsUse.value = item.licenseIsUse;
                licenseIsServer.value = item.isServer;
            } else {
                licenseId.value = null;
                licenseName.value = null;
                expirationDate.value = null;
                offlineExpirationDay.value = null;
                offlineExpirationMin.value = null;
                offlineExpirationAlarm.value = null;
                licenseIsUse.value = null;
                licenseIsServer.value = null;
            }
        });

        onBeforeMount(() => {
            getLicenseGroup();
        });

        return {
            insertId,
            productIdList,

            warning,
            confirm,

            licenseId,
            licenseName,
            expirationDate,
            offlineExpirationDay,
            offlineExpirationMin,
            offlineExpirationAlarm,
            licenseIsUse,
            licenseIsServer,

            selectedLicenseGroupList,

            licenseGroupList,
            getLicenseGroup,
            
            cancel,
            execute,
            next,
        }
    },
}
</script>

<style>

</style>