const state = () => ({
    number: 1,
    serial: "CVN22H014",
    productName: "UF 4 MPT - TAIET",
    country: "대한민국",
    ip: "218.237.59.211 (대한민국)",
    currentShotCount: "999,999",
    totalShotCount: "999,999",
    remainerShotCount: "999,999",
    baseShotCount: "999,999",
    date: "2022.09.16 02:53:42"
})

const getters = {
    getNumber: (state) => state.number,
    getSerial: (state) => state.serial,
    getProductName: (state) => state.productName,
    getCountry: (state) => state.country,
    getIP: (state) => state.ip,
    getCurrentShotCount: (state) => state.currentShotCount,
    getTotalShotCount: (state) => state.totalShotCount,
    getRemainerShotCount: (state) => state.remainerShotCount,
    getBaseShotCount: (state) => state.baseShotCount,
    getDate: (state) => state.date
}


const actions = {
    incrementNumber({ state, commit }, payload) {
        commit('incrementNumber', payload)
    }
}

const mutations = {
    incrementNumber(state, payload) {
        state.number += 1;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}