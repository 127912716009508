<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">일련번호</li>
                        <li class="cont">
                            <input type="text" v-model="cartridgeSn">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">제품 일련번호</li>
                        <li class="cont">
                            <input type="text" v-model="productSn">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">제품명</li>
                        <li class="">
                            <v-autocomplete
                            :items="modelNameList"
                            item-title="modelName"
                            item-value="modelName"
                            v-model="modelName"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">카트리지/팁 로그</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <!-- <button v-if="userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="userAuth == 0" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <!-- 권한OK : 대리점 이외에 전부 -->
                        <button 
                            v-if="userAuth != 11" 
                            class="btn-sb-g" 
                            :class="{'active': easyButton == 'isProductDuplicate'}"
                            @click="checkReadData('isProductDuplicate')">중복 사용
                        </button>
                        <button 
                            v-if="userAuth != 11" 
                            class="btn-sb-g" 
                            :class="{'active': easyButton == 'isRemainingIncrease'}"
                            @click="checkReadData('isRemainingIncrease')">카운트 증가
                        </button>
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
            </div>
                <div v-if="totalCount === 0" class="board cartridge filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board cartridge filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="sortReadData(column.sortKey)"
                                    :style="{flex : `${column.flex}`}" 
                                    :name="column.engName" 
                                    :title="column.korName">{{column.korName}}
                                    <img 
                                        v-if="column.sortKey && sortKey == null" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-ud.png">
                                    <img 
                                        v-else-if="column.sortKey && sortKey != column.sortKey" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-ud.png">
                                    <img 
                                        v-if="column.sortKey == sortKey && sort == 'asc'" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-u.png">
                                    <img 
                                        v-if="column.sortKey == sortKey && sort == 'desc'" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-d.png">
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item" @contextmenu.prevent>
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="goProductDetail(column.engName, row.productNumber)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName"
                                    :title="row[column.engName]">
                                    <p v-if="column.engName == 'useIp'">{{row[column.engName]}}({{row.useNation}})</p>
                                    <p v-else>{{row[column.engName]}}</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import productAuto from '@/js/autocomplete/productAuto';
import { downloadExcel } from '@/js/excelHelper.js';
export default {
    name: 'Catridge',
    setup() {
        // 변수 선언
        let cartridgeSn = ref(null);
        let productSn = ref(null);

        let isProductDuplicate = ref(false);
        let isRemainingIncrease = ref(false);

        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_cartridge || 10);
        let totalCount = ref(1);
        let activeParam = ref(null);

        let searchPanelOn = ref(false);
        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let utcTime = computed(() => store.state.utcTime);
        let sendParam = ref({});

        const easyButton = ref('');        // 간편검색 버튼 active 적용하기 위한

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userId = computed(() => store.state.profileModule.userId);
        const productSnByProduct = computed(() => store.state.dataModule.cartridge_productSn);
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // router
        const router = useRouter();

        const modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        /**
         * 함수 선언
         */
        // 제품 일련번호를 클릭 => 해당 번호의 제품 관리 상세보기로 이동
        const goProductDetail = ((key, productNumber) => {
            if(key === 'productNumber') {
                router.push('/productDetail')
            }
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        // 게시판 열 고정
        const getliwidth = (() => {
            let cols = document.querySelectorAll(".list-item.filtering-board-tit li ul li")
            let colWidthArr = []
            
            cols.forEach((li, a)=>{
                if(a == 0) {
                    colWidthArr.push(li.getBoundingClientRect().width)
                } else if(a > 0) {
                    colWidthArr.push(li.getBoundingClientRect().width + colWidthArr[a-1])
                }
            })
            
            let ulArr = document.querySelectorAll(".list-item li ul")
            ulArr.forEach((ul,a)=>{
                /**
                 * 1번 : 체크박스, 번호, 일련번호 고정
                 */
                ul.querySelectorAll('li').forEach((li, b)=>{
                    if(li.getAttribute('name') == 'no' || li.getAttribute('name') == 'serialNumber') {
                        li.classList.add('sticky-column')
                        li.style.left = colWidthArr[b-1] + 'px'
                    }
                })

                /**
                 * 2번 : 체크박스, 일련번호 고정
                 */
                // ul.querySelectorAll('li').forEach((li, b)=>{
                //     if(li.getAttribute('name') == 'serialNumber') {
                //         li.classList.add('sticky-column')
                //         li.style.left = colWidthArr[b-2] + 'px'
                //     }
                // })
            })
        });

        // 상세 검색 초기화
        const refresh = (() => {
            cartridgeSn.value = null;
            productSn.value = null;
            modelName.value = null;
            isProductDuplicate.value = false;
            isRemainingIncrease.value = false;
        });

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            let engNames = columnData._value.map(item => item.engName);
            let korNames = columnData._value.map(item => item.korName);
            let width = 20;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;

            // IP, 국가를 분리
            if(engNames.includes('useIp')) {
                // const index = engNames.indexOf('useIp');
                // korNames[index] = 'IP';

                // engNames.splice(index, 0, 'useNation');
                // korNames.splice(index, 0, '국가');

                const index = engNames.indexOf('useIp');
                korNames[index] = '접속 국가 IP';

                engNames.splice(index+1, 0, 'useNation');
                korNames.splice(index+1, 0, '접속 국가');
            }
            
            try {
                const result = await store.dispatch('dataModule/getList', {
                    "url": '/api/v1/product-cartridge-tip-log/getPageList',
                    "param": param
                });
                const dataList = result.data.data.list;

                const filename = "카트리지_팁_로그.xlsx"; // 원하는 파일 이름
                const sheetname = "카트리지 팁 로그";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const orderBy = ref(null);
        const sortKey = ref(null);
        const sort = ref(null);
        const sortReadData = ((data) => {
            // sortKey가 존재하는 경우에만 동작
            if(data == undefined) return;

            // 정렬 중에 다른 column을 눌렀을 경우 sort값을 초기화
            if(sortKey.value != data) {
                sort.value = null;
            }

            if(sort.value == null) {
                sort.value = 'desc'
                sortKey.value = data;
                orderBy.value = data + ' ' + sort.value;
            } else if(sort.value == 'desc') {
                sort.value = 'asc'
                sortKey.value = data;
                orderBy.value = data + ' ' + sort.value;
            } else if(sort.value == 'asc') {
                sort.value = null;
                sortKey.value = null;
                orderBy.value = null;
            }

            readData(selectedPage.value, null, null);
        });
        
        const checkReadData = ((type) => {
            if(easyButton.value == type) {
                readData(1, 'search');
            } else {
                readData(1, type);
            }
        });

        const readData = ((pageNo, type, pageSize) => {
            // 제품 관리 팝업으로 넘어온 경우 productSn 설정
            if(productSnByProduct.value != undefined && productSnByProduct.value != null) {
                productSn.value = productSnByProduct.value;

                // 제품 일련번호 설정 후 vuex 부분은 초기화
                store.commit('dataModule/SET_CARTRIDGE_PRODUCT_SN', null);
            }

            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_CARTRIDGE', pageSize);
            }

            let param = {};
            if(type == "search") {
                easyButton.value = '';
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "isUse": true,
                    "cartridgeSn": cartridgeSn.value,
                    "productSn": productSn.value,
                    "modelName": modelName.value,
                    "isProductDuplicate": false,
                    "isRemainingIncrease": false,
                });
                activeParam.value = param;
            } else if(type == "isProductDuplicate") {
                refresh();
                easyButton.value = 'isProductDuplicate';
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
                param.isProductDuplicate = true;
                param.isRemainingIncrease = false;
                activeParam.value = param;
            } else if(type == "isRemainingIncrease") {
                refresh();
                easyButton.value = 'isRemainingIncrease';
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
                param.isProductDuplicate = false;
                param.isRemainingIncrease = true
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            if(orderBy.value != null) {
                param.orderBy = orderBy.value;
            } else {
                delete param.orderBy;
            }
            // param.orderBy = orderBy.value;

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product-cartridge-tip-log/getPageList',
                    "param": param
                }
            ).then(result => {
                // 엑셀다운로드 param
                sendParam.value = param;

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'cartridgeSn', korName: '일련번호', flex: 7, align: 'center', sortKey: 'pctl.cartridge_sn'},
                    { engName: 'productSn', korName: '제품 일련번호', flex: 7, align: 'center', sortKey: 'pctl.product_sn'},
                    { engName: 'modelName', korName: '제품명', flex: 9, align: 'left'},
                    { engName: 'salesNation', korName: '판매 국가', flex: 4, align: 'center'},
                    // { engName: 'useIp', korName: 'IP (국가)', flex: 10, align: 'center'},
                    { engName: 'useIp', korName: '접속 IP (국가)', flex: 10, align: 'center'},
                    { engName: 'useShot', korName: '현재샷 수', flex: 3, align: 'right'},
                    { engName: 'remainingShot', korName: '남은샷 수', flex: 3, align: 'right'},
                    { engName: 'baseShot', korName: '기본샷 수', flex: 3, align: 'right'},
                    { engName: 'insertDate', korName: '수신 일시', flex: 10, align: 'center', sortKey: 'pctl.log_id'},
                    { engName: 'useDate', korName: '사용 일시', flex: 10, align: 'center', sortKey: 'pctl.use_date'},
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    item.useDate = dateUtils.convertUTC(item.useDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })

            closeSearchPanel();
        });

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

        onBeforeMount(() => {
            // readData(1, "search");
            getProduct();
        });

        onMounted(() => {
            readData(1, "search");
            getliwidth();
            resizeObserver.observe(document.querySelector(".search-panel"));
        });

        onUpdated(() => {
            getliwidth();
        });

        onBeforeUnmount(() => {
            getliwidth();
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        });

        return {
            cartridgeSn,
            productSn,

            isProductDuplicate,
            isRemainingIncrease,

            selectedPage,
            selectedPageSize,
            totalCount,
            activeParam,

            searchPanelOn,
            timer,
            columnData,
            rowData,

            userAuth,
            userId,

            easyButton,

            goProductDetail,
            startTimer,
            clearTimer,
            getliwidth,

            // 제품명 + 자동완성 처리 변수
            modelName,
            modelNameList,
            getProduct,
            
            refresh,
            fetchDataAndDownload,

            checkReadData,
            readData,

            toggleSearchPanel,

            sortKey,
            sort,
            sortReadData,
        }
    },
}
</script>

<style>

</style>