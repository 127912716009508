<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/part')" class="prev">부품 관리</a>
                <a href="" class="now">상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/part')" class="btn-sb-g">목록으로</button>
                        <button @click="openPopup('partDetailPopup1')" class="btn-sb-g">편집</button>
                        <button @click="openPopup('deletePopup')" class="btn-sb-g">삭제</button>
                    </div>
                </div>
                <div class="table product clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">부품 코드</li>
                            <li class="table-item">
                                <span>{{partCode}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">부품명</li>
                            <li class="table-item"> 
                                <span>{{partName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">등록 일시</li>
                            <li class="table-item">
                                <span>{{insertDate}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">부품 종류</li>
                            <li class="table-item">
                                <span>{{partTypeName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">제품명</li>
                            <li class="table-item"> 
                                <span>{{modelName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <span></span>
                            </li>
                        </ul>
                    </div>    
                </div>
                <!-- 해당 부품을 사용하는 장비의 목록만 표시 -->
                <h3>사용 목록</h3>
                <div class="top-bar">
                    <div class="list-area">
                        <ul class="input-txt-list">
                            <li class="tit">제품 일련번호</li>
                            <li class="cont">
                                <input type="text" v-model="productSn">
                            </li>
                        </ul>
                        <div class="btn-box one">
                            <button class="btn-sb-g" @click="readList(1, 'search')">검색</button>
                        </div>
                    </div> 
                </div>
                <div v-if="totalCount === 0" class="board piece-detail filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board piece-detail filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :name="column.engName" :title="column.korName">{{column.korName}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="goProductDetail(column.engName, row.productNumber)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`}" 
                                    :name="column.engName" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readList"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'PartDetail',
    setup() {
        // emitter
        const emitter = inject('emitter');

        // router
        const router = useRouter();
        const dateUtils = inject('dateUtils');

        // store
        const store = useStore();
        // const userAuth = computed(() => store.state.loginModule.userAuth);
        const insertId = computed(() => store.state.profileModule.id);
        const partData = computed(() => store.state.dataModule.partDetail_data);
        let utcTime = computed(() => store.state.utcTime);
        
        // 변수 선언
        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let totalCount = ref(1);
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_partDetail || 5);
        let activeParam = ref(null);

        let partCode = ref(null);
        let partTypeName = ref(null);
        let partName = ref(null);
        let modelName = ref(null);
        let insertDate = ref(null);

        // 사용 목록 검색용
        let productSn = ref(null);

        /**
         * 함수 선언
         */
        // 제품 일련번호를 클릭 => 해당 번호의 제품 관리 상세보기로 이동
        const goProductDetail = ((key, productNumber) => {
            if(key === 'productNumber') {
                router.push('/productDetail')
            }
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType)
        });

        // 상세보기 데이터 불러오기
        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/model-part/getData',
                    "param": {
                        "modelPartId": partData.value.modelPartId,
                        "partId": partData.value.partId,
                        "partCode": partData.value.partCode,
                        "isUse": true
                    }
                }
            ).then(result => {
                partCode.value = result.data.data.partCode;
                partTypeName.value = result.data.data.partTypeName;
                partName.value = result.data.data.partName;
                modelName.value = result.data.data.modelName;
                insertDate.value = result.data.data.insertDate;

                insertDate.value = dateUtils.convertUTC(insertDate.value, "yyyy-MM-DD HH:mm:ss", utcTime.value);
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readList = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_PART_DETAIL', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "partId": partData.value.partId,
                    "productSn": productSn.value

                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": param
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 2, align: 'center'},
                    { engName: 'productSn', korName: '일련 번호', flex: 4, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 5, align: 'left'},
                    { engName: 'nationName', korName: '판매 국가', flex: 3, align: 'center'},
                    { engName: 'salesCustomerName', korName: '고객사', flex: 8, align: 'left'},
                    { engName: 'customerName', korName: '판매처', flex: 8, align: 'left'},
                    { engName: 'orderCode', korName: '발주 주문 코드', flex: 4, align: 'center'},
                    { engName: 'releaseDate', korName: '출고일', flex: 4, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 삭제
        const deleteFunction = (() => {
            let param = [{
                "modelPartId": partData.value.modelPartId,
                "insertId": insertId.value,
                // "insertId": partData.value.insertId,
                "isUse": false
            }];

            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/model-part/updateUseList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 편집 후 데이터 다시 조회
        const onReData = (data) => {
            if(data) {
                readData();
            }
        };

        // 편집 후 데이터 다시 조회
        const onGoMenu = (data) => {
            router.push('/part');
        };

        watch(utcTime, () => {
            readData();
        });

        onBeforeMount(() => {
            readData();

            readList(1, "search");
        });

        onMounted(() => {
            emitter.on('reData', onReData);
            emitter.on('delete', deleteFunction);
            emitter.on('goMenu', onGoMenu);
        });

        onBeforeUnmount(() => {
            emitter.off('reData', onReData);
            emitter.off('delete', deleteFunction);
            emitter.off('goMenu', onGoMenu);
        });
        
        return {
            partCode,
            partTypeName,
            partName,
            modelName,
            insertDate,

            productSn,

            timer,
            columnData,
            rowData,
            totalCount,
            selectedPage,
            selectedPageSize,

            goProductDetail,
            startTimer,
            clearTimer,
            openPopup,
            readList
        }
    },
}
</script>

<style>

</style>