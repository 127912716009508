<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/firmware')" class="prev">펌웨어 그룹 관리</a>
                <a href="" class="now">상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/firmware')" class="btn-sb-g">목록으로</button>
                        <button @click="openPopup('firmwareDetailPopup1')" class="btn-sb-g">편집</button>
                        <!-- 펌웨어 그룹을 사용하고 있는 장비가 없는 경우에만 펌웨어 그룹을 삭제할 수 있다 -->
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || userAuth == 2" 
                            @click="openPopup('deletePopup')" 
                            class="btn-sb-g">삭제
                        </button>
                    </div>
                </div>
                <div class="table firmware clearfix">
                    <div class="group firmware">
                        <ul>
                            <li class="table-tit table-tit-firmware">그룹명</li>
                            <li class="table-item">
                                <span>{{groupName}}</span>
                            </li>
                            <li class="table-tit table-tit-firmware">제품명</li>
                            <li class="table-item">
                                <span>{{modelName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">최종 변경 일시</li>
                            <li class="table-item">
                                <span>{{updateDate}}</span>
                            </li>
                            <li class="table-tit table-tit-firmware">등록 일시</li>
                            <li class="table-item">
                                <span>{{insertDate}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group firmware">
                        <ul v-for="item in (partList)" :key="item">
                            <li class="table-tit table-tit-firmware" :title="item.partName">{{item.partName}} 펌웨어 버전</li>
                            <li class="table-item">
                                <span :title="item.firmwareVersion">{{item.firmwareVersion}}</span>
                            </li>
                            <li class="table-tit table-tit-firmware" :title="item.partName">{{item.partName}} 펌웨어 URL</li>
                            <li class="table-item">
                                <span :title="item.url">{{item.url}}</span>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="group">
                        <ul>
                            <li class="table-tit table-tit-firmware">제품명</li>
                            <li class="table-item">
                                <span>{{modelName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">UL4_MPT 펌웨어 URL</li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dn/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit table-tit-firmware">등록 일시</li>
                            <li class="table-item">
                                <span>{{insertDate}}</span>
                            </li>
                        </ul>
                    </div> -->
                </div>

                <!-- <div class="table clearfix">
                    <div class="group firmware-group-detail">
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Pre_Baseboard 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Pre_GUI 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Pre_HP 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Pre_HP_Booster 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Pre_HP_Face 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Pre 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_Baseboard 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_GUI 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">
                                UL4_MPT_HP 펌웨어 버전 / URL
                            </li>
                            <li class="table-item">
                                <span>1.0.1</span>
                            </li>
                            <li class="table-item">
                                <span>https://www.classyslab.com/dm/fw/ul4_mpt_b.bin</span>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <h3>목록</h3>
                <div class="top-bar">
                    <div class="list-area">
                        <ul class="input-txt-list">
                            <li class="tit">일련번호</li>
                            <li class="cont">
                                <input type="text" v-model="list_productSn">
                            </li>
                        </ul>
                        <ul class="input-txt-list">
                            <li class="tit">제품명</li>
                            <li class="">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                item-value="modelName"
                                v-model="list_modelName"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                        <div class="btn-box one">
                            <button class="btn-sb-g" @click="readList(1, 'search')">검색</button>
                        </div>
                    </div> 
                </div>
                <div v-if="totalCount === 0" class="board firmware-group-detail filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board firmware-group-detail filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :name="column.engName" :title="column.korName">{{column.korName}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="goProductDetail(column.engName, row.productSn, row.productId)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :class="{'pointer' : column.engName == 'productSn'}"
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readList"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, reactive, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import productAuto from '@/js/autocomplete/productAuto';
export default {
    name: 'FirmwareDetail',
    setup() {
        // 변수 선언
        let groupName = ref(null);
        let modelName = ref(null);
        let updateDate = ref(null);
        let insertDate = ref(null);

        let activeParam = ref(null);           

        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let totalCount = ref(1);
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_firmwareDetail || 5);
        let utcTime = computed(() => store.state.utcTime);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const firmwareModelId = computed(() => store.state.dataModule.firmwareDetail_firmwareModelId);
        const modelId = computed(() => store.state.dataModule.firmwareDetail_modelId);
        const insertId = computed(() => store.state.profileModule.id);
        
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');
        const router = useRouter();

        const partList = ref(null);

        
        let list_productSn = ref(null);
        // 자동완성 - 제품명
        const list_modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        // 제품 일련번호를 클릭 => 해당 번호의 제품 관리 상세보기로 이동
        const goProductDetail = ((key, productSn, productId) => {
            if(key === 'productSn') {
                store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_SN', productSn);
                store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_ID', productId);
                router.push('/productDetail');
            }
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType)
        });

        // 상세보기 데이터 요청
        // const readData = (() => {
        //     store.dispatch(
        //         'dataModule/getData'
        //         , {
        //             "url": '/api/v1/firmware-model/getData',
        //             "param": {
        //                 "firmwareModelId": firmwareModelId.value,
        //             }
        //         }
        //     ).then(result => {
        //         firmwareGroupName.value = result.data.data.groupName;
        //         firmwareModelName.value = result.data.data.modelName;
        //         firmwareUpdateDate.value = dateUtils.convertUTC(result.data.data.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
        //         firmwareInsertDate.value = dateUtils.convertUTC(result.data.data.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
        //     });
        // });

        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/firmware-model/getDataParts',
                    "param": {
                        "firmwareModelId": firmwareModelId.value,
                        "modelId": modelId.value
                    }
                }
            ).then(result => {
                groupName.value = result.data.data.firmwareModel.groupName;
                modelName.value = result.data.data.firmwareModel.modelName;
                insertDate.value = result.data.data.firmwareModel.insertDate;
                updateDate.value = result.data.data.firmwareModel.updateDate;

                if(insertDate.value != null && insertDate.value != '') {
                    insertDate.value = dateUtils.convertUTC(insertDate.value, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                }
                if(updateDate.value != null && updateDate.value != '') {
                    updateDate.value = dateUtils.convertUTC(updateDate.value, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                }

                partList.value = result.data.data.partList;
                // firmwareGroupName.value = result.data.data.groupName;
                // firmwareModelName.value = result.data.data.modelName;
                // firmwareUpdateDate.value = dateUtils.convertUTC(result.data.data.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                // firmwareInsertDate.value = dateUtils.convertUTC(result.data.data.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 삭제
        const deleteFunction = (() => {
            let param = {
                "firmwareModelId": firmwareModelId.value,
                "insertId": insertId.value
            };

            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/firmware-model/delete',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {  
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readList = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_FIRMWARE_DETAIL', pageSize);
            }

            // 원래는 firmwareModelId, 0828기준 modelId로 보내야함-나중에 수정
            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "firmwareModelId": firmwareModelId.value,
                    "productSn" : list_productSn.value,
                    "modelName": list_modelName.value,
                    "isUse": true
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": param
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 2, align: 'center'},
                    { engName: 'productSn', korName: '제품 일련 번호', flex: 4, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 5, align: 'left'},
                    { engName: 'nationName', korName: '판매 국가', flex: 3, align: 'center'},
                    { engName: 'salesCustomerName', korName: '고객사', flex: 8, align: 'left'},
                    { engName: 'customerName', korName: '판매처', flex: 8, align: 'left'},
                    { engName: 'orderCode', korName: '발주 주문 코드', flex: 4, align: 'center'},
                    { engName: 'releaseDate', korName: '출고일', flex: 4, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 추가 후 다시 재검색
        const onReData = (data) => {
            if(data) {
                readData();
                readList(selectedPage.value, "search", null);
            }
        };

        // 편집 후 데이터 다시 조회
        const onGoMenu = (data) => {
            router.push('/firmware');
        };

        onBeforeMount(() => {
            readData();
            readList(1, "search");
            getProduct();
        });

        onMounted(() => {
            emitter.on('delete', deleteFunction);
            emitter.on('reData', onReData);
            emitter.on('goMenu', onGoMenu);
        })

        const onBeforeUnmountHandler = () => {
            emitter.off('delete', deleteFunction);
            emitter.off('reData', onReData);
            emitter.off('goMenu', onGoMenu);
        };

        onBeforeUnmount(onBeforeUnmountHandler);

        watch(utcTime, () => {
            readData();
        });
        
        return {
            totalCount,
            selectedPage,
            selectedPageSize,
            readList,
            activeParam,

            groupName,
            modelName,
            updateDate,
            insertDate,

            timer,
            columnData,
            rowData,

            firmwareModelId,
            insertId,
            
            partList,

            // 제품명 + 자동완성 처리 변수
            modelName,
            modelNameList,
            getProduct,

            list_productSn,
            list_modelName,

            goProductDetail,
            startTimer,
            clearTimer,
            openPopup,
            userAuth
        }
    },
}
</script>

<style>

</style>