<template>
    <div class="popup-box">
        <div class="bg">
            <!-- <div class="popup bigsize"> -->
            <div class="popup">
                <div class="tit">메모 히스토리</div>
                <div class="inner">
                    <div class="cont">
                        <div class="board memo-history filtering-board">
                            <ul class="list-item filtering-board-tit">
                                <li>
                                    <ul class="item">
                                        <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :title="column.korName">{{column.korName}}</li>
                                    </ul>
                                </li>
                            </ul>
                            <div v-if="totalCount === 0">
                                <ul class="list-item filtering-board-item list-item-none">
                                    <li>
                                        <ul class="item">
                                            <li :style="{flex : 1}">히스토리가 존재하지 않습니다</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="totalCount !== 0">
                                <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                                    <li>
                                        <ul class="item">
                                            <li v-for="(column) in columnData" :key="column" 
                                                @touchstart="startTimer(column.korName, row[column.engName])" 
                                                @touchend="clearTimer" 
                                                :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                                :title="row[column.engName]">
                                                {{row[column.engName]}}</li>
                                            <!-- <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :title="row[column.engName]">{{row[column.engName]}}</li> -->
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
                    </div>
                    <div class="btn-box one">
                        <button @click="cancel" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore } from 'vuex'
export default {
    name: 'ProductDetailPopup7',
    setup() {
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const productId = computed(() => store.state.dataModule.productDetail_productId);
        let utcTime = computed(() => store.state.utcTime);

        let store = useStore();
        let columnData = ref(null);
        let rowData = ref([]);
        let totalCount = ref(1);
        // let selectedPage = ref(1);
        let selectedPage = ref(1)
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_productDetailPopup7 || 5);

        let timer = ref(null);

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        onBeforeMount(() => {
            readData(1);
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_PRODUCT_DETAIL_POPUP7', pageSize);
            }

            let param = {
                "page": pageNo,
                "pageSize": selectedPageSize.value, 
                "productId": productId.value,
                "isUse": true,
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product-memo/getPageList',
                    "param": param
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 3, align: 'center'},
                    { engName: 'contents', korName: '내용', flex: 9, align: 'left'},
                    { engName: 'insertDate', korName: '등록일시', flex: 4, align: 'center' },
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / 5);
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });


        return {
            selectedPage,
            selectedPageSize,
            totalCount,
            columnData,
            rowData,
            
            readData,
            cancel,
            startTimer,
            clearTimer
        }

    }
}
</script>

<style>

</style>