<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="moveList" class="prev">사용자 관리</a>
                <a href="" class="now">상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/user')" class="btn-sb-g">목록으로</button>
                        <button 
                            v-if="!userAuthYn"
                            @click="openPopup('deletePopup')" 
                            class="btn-sb-g">삭제
                        </button>
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">사용자 분류</li>
                            <li class="table-item">
                                <span>{{userData.userAuthName}}</span>
                                <div class="btn-box right">
                                    <button 
                                        v-if="!userAuthYn"
                                        @click="openPopup('userDetailPopup1')" 
                                        class="btn-mini">권한 변경
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">아이디</li>
                            <li class="table-item">
                                <span>{{userData.userId}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">이름</li>
                            <li class="table-item">
                                <span>{{userData.userName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">연락처</li>
                            <li class="table-item">
                                <span>{{userData.phone}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">가입 일시</li>
                            <li class="table-item">
                                <span>{{userData.insertDate}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">대리점명</li>
                            <li class="table-item">
                                <span>{{userData.customerName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">비밀번호</li>
                            <li class="table-item">
                                <div class="btn-box">
                                    <button 
                                        :disabled="userAuthYn"
                                        :class="{'readonly': userAuthYn}"
                                        @click="openPopup('userDetailPopup2')" 
                                        class="btn-mini">비밀번호 초기화
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">이메일</li>
                            <li class="table-item">
                                <span>{{userData.email}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">주소</li>
                            <li class="table-item">
                                <span>{{userData.address}} {{userData.addressDetail}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <span></span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul class="memo border-b0">
                            <li class="table-tit">메모</li>
                            <li class="table-item">
                                <textarea name="" id="" cols="30" rows="10" v-model="memo" @input="limitTextArea"></textarea>
                            </li>
                        </ul>
                        <ul class="memo-btn">
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <div class="btn-box multi right">
                                    <button 
                                        :disabled="userAuthYn"
                                        :class="{'readonly': userAuthYn}"
                                        class="btn-sb-g" 
                                        @click="openPopup('savePopup1')">저장
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>  
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { validateText } from '@/js/common/regex.js';

import { ref, onBeforeMount, onMounted, onBeforeUnmount, onUpdated, computed, inject, reactive, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'UserDetail',
    setup() {
        // 변수 선언
        let textAreaInput = ref("");

        // store에서 state 값 가져오기
        const store = useStore();
        // computed or mapState 두개의 방법으로 가져올 수 있음
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const id = computed(() => store.state.profileModule.id);
        const insertId = computed(() => store.state.profileModule.id);
        const userDetailId = computed(() => store.state.dataModule.userDetail_id);

        let utcTime = computed(() => store.state.utcTime);
        let userData = ref({});
        let memo = ref('');

        const userAuthYn = ref(false);

        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');
        const router = useRouter();

        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/user/getData',
                    "param": {
                        "id": userDetailId.value,
                    }
                }
            ).then(result => {
                userData.value = result.data.data;
                userData.value.insertDate = dateUtils.convertUTC(userData.value.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                memo.value = result.data.data.memo;


                // 최고관리자에 대한 삭제, 권한 변경, 비밀번호 초기화는 최고관리자 계정으로만 가능하게
                if(userAuth.value != 0) {
                    if(userData.value.userAuth == 0) {
                        userAuthYn.value = true;
                    }
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 삭제 function
        const deleteFunction = (() => {
            const param = [{
                "id": userDetailId.value,
                // "insertId": id.value,
                "insertId": insertId.value,
                "isUse": false
            }];
            store.dispatch(
                'dataModule/getList', 
                {
                    "url": '/api/v1/user/updateUseList',
                    "param": param
                }
            ).then(result => {
                // emitter.emit('popupType', '');
                // router.push('/user');
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 메모 저장 function
        const updateFunction = (() => {
            const param = {
                "id": userData.value.id,
                // "insertId": userData.value.insertId,
                "insertId": insertId.value,
                "userName": userData.value.userName,
                "phone": userData.value.phone,
                "email": userData.value.email,
                "address": userData.value.address,
                "customerId": userData.value.customerId,
                "userAuth": userData.value.userAuth,
                "userAuthSub": userData.value.userAuthSub,
                "remark": userData.value.remark,
                "isUse": true,
                "memo": memo.value
            };
            
            store.dispatch(
                'dataModule/updateData', 
                {
                    "url": '/api/v1/user/update',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('returnPath', '/user');
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 권한 변경 및 메모 저장 이후 데이터 다시 조회
        const onReData = (data) => {
            if(data) {
                readData();
            }
        };

        const onGoMenu = (data) => {
            router.push('/user');
        };

        // 메모 길이 제한
        const limitTextArea = (() => {
            // if (!validateText(textAreaInput.value)) {
            //     textAreaInput.value = textAreaInput.value.slice(0, 4096);
            // }
            if (!validateText(memo.value)) {
                memo.value = memo.value.slice(0, 4096);
            }
        });

        const openPopup = ((popupType) => {
            if(popupType == 'userDetailPopup1') {
                store.commit('dataModule/SET_USER_DETAIL_POPUP_DATA', userData.value);
            }
            emitter.emit('popupType', popupType);
        });

        onBeforeMount(() => {
            readData();
        });

        onMounted(() => {
            emitter.on('delete', deleteFunction);
            emitter.on('execute', updateFunction);
            emitter.on('reData', onReData);
            emitter.on('goMenu', onGoMenu);
        });

        onBeforeUnmount(() => {
            emitter.off('delete', deleteFunction);
            emitter.off('execute', updateFunction);
            emitter.off('reData', onReData);
            emitter.off('goMenu', onGoMenu);
        })

        const moveList = (() => {
            router.push('/user');
        });
        
        watch(utcTime, () => {
            readData();
        });

        return {
            userData,
            memo,
            userAuth,
            textAreaInput,

            userAuthYn,

            moveList,
            readData,
            deleteFunction,
            updateFunction,
            limitTextArea,
            openPopup
        }
    },
}
</script>

<style>

</style>