<template>
    <div class="popup-box">
        <div class="bg">
            <!-- <div v-if="!confirm" class="popup"> -->
            <div class="popup">
                <div class="tit">부품 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">부품 코드</li>
                            <li class="cont">
                                <input type="text" v-model="partCode">
                            </li>
                            <li class="tit">부품 종류</li>
                            <li class="pbot10">
                                <v-autocomplete
                                :items="partTypeList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="partType"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">부품명</li>
                            <li class="cont">
                                <input type="text" v-model="partName">
                            </li>
                            <li class="tit">제품명</li>
                            <li class="pbot10">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                item-value="modelId"
                                v-model="modelId"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import productAuto from '@/js/autocomplete/productAuto';
import partTypeAuto from '@/js/autocomplete/partTypeAuto';
import { ref, onBeforeMount, computed, inject } from 'vue';
import { useStore } from 'vuex';
export default {
    name: 'PartPopup1',
    setup() {
        const emitter = inject('emitter');

        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);

        let partCode = ref(null);
        let partName = ref(null);

        let executeCheck = ref('i');
        let partData = ref(null);

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        const partType = ref(null);
        const partTypeName = ref(null);
        const {
            partTypeList,
            getPartType,
        } = partTypeAuto();

        const modelId = ref(null);
        const modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        const next = (() => {
            if(!partCode.value) {
                warning.value = true;
                message.value = '부품 코드를 입력해주세요.';
                return;
            }
            if(!partType.value) {
                warning.value = true;
                message.value = '부품 종류를 선택해주세요.';
                return;
            }
            if(!partName.value) {
                warning.value = true;
                message.value = '부품명을 입력해주세요.';
                return;
            }
            if(!modelId.value) {
                warning.value = true;
                message.value = '제품명을 선택해주세요.';
                return;
            }

            partCodeCheck();
        });

        const partCodeCheck = (() => {
            const param = {
                "partCode": partCode.value
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/model-part/getData',
                    "param": param
                }
            ).then(result => {
                if(result.data.data == null) {
                    confirm.value = true;
                } else {
                    if(result.data.data.isUse == true) {
                        warning.value = true;
                        message.value = '부품 코드가 중복입니다.';
                        return;
                    } else if(result.data.data.isUse == false) {
                        executeCheck.value = 'u';
                        partData.value = result.data.data;
                        confirm.value = true
                        // partNameCheck();
                    }
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // const partNameCheck = (() => {
        //     const param = {
        //         // "isUse": true,
        //         "partName": partName.value
        //     }

        //     store.dispatch(
        //         'dataModule/getList'
        //         , {
        //             "url": '/api/v1/model-part/getData',
        //             "param": param
        //         }
        //     ).then(result => {
        //         if(result.data.data == null) {
        //             confirm.value = true;
        //         } else {
        //             alert('부품명이 중복입니다.')
        //             return;
        //             // if(result.data.data.isUse == true) {
        //             //     alert('부품명이 중복입니다.')
        //             //     return;
        //             // } else if(result.data.data.isUse == false) {
        //             //     executeCheck.value = 'u';
        //             // }
        //         }
        //     });
        // });

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        // 저장
        const execute = () => {
            if(executeCheck.value == 'i') {
                let param = {
                    "insertId": insertId.value,
                    "partCode": partCode.value,
                    "partName": partName.value,
                    "partType": partType.value,
                    "modelId": modelId.value,
                    "isUse": true,
                };

                store.dispatch(
                    'dataModule/insertData'
                    , {
                        "url": '/api/v1/model-part/insert',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', "데이터가 추가되었습니다");
                        emitter.emit('popupType', 'resultPopup1');
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                });
            } else if(executeCheck.value == 'u') {
                let param = {...partData.value};
                param.insertId = insertId.value;
                param.partCode = partCode.value;
                param.partName = partName.value;
                param.partType = partType.value;
                param.partTypeName = partTypeName.value;
                param.modelId = modelId.value;
                param.isUse = true;

                store.dispatch(
                    'dataModule/updateData'
                    , {
                        "url": '/api/v1/model-part/update',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', "데이터가 추가되었습니다.");
                        emitter.emit('popupType', 'resultPopup1');
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                });
            }

            
        };

        onBeforeMount(() => {
            getPartType();
            getProduct();
        })

        return {
            partCode,
            partName,

            message,
            warning,
            confirm,

            // 제품명 + 자동완성 처리 변수
            modelId,
            modelName,
            modelNameList,
            getProduct,

            partType,
            partTypeName,
            partTypeList,
            getPartType,

            next,
            cancel,
            execute,
        }
    },
}
</script>

<style>

</style>