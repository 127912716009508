<template>
    <!-- 공용 -->
    <DeletePopup v-if="popupType == 'deletePopup'"/>
    <SavePopup1 v-if="popupType == 'savePopup1'"/>
    <SavePopup2 v-if="popupType == 'savePopup2'"/>
    <EditPopup1 v-if="popupType == 'editPopup1'"/>
    <CheckBoxPopup1 v-if="popupType == 'checkBoxPopup1'"/>
    <CheckBoxPopup2 v-if="popupType == 'checkBoxPopup2'"/>
    <CheckBoxPopup3 v-if="popupType == 'checkBoxPopup3'"/>
    <ResultPopup1 v-if="popupType == 'resultPopup1'" :returnPath="returnPath" :message="message"/>
    <ResultPopup2 v-if="popupType == 'resultPopup2'" :message="message"/>
    <ResultPopup3 v-if="popupType == 'resultPopup3'" :message="message"/>

    <AlertPopup1 v-if="popupType == 'alertPopup1'" :message="message"/>

    <!-- 제품관리 -->
    <ProductPopup1 v-else-if="popupType == 'productPopup1'"/>
    <ProductPopup2 v-else-if="popupType == 'productPopup2'"/>
    <ProductDetailPopup1 v-else-if="popupType == 'productDetailPopup1'"/>
    <ProductDetailPopup2 v-else-if="popupType == 'productDetailPopup2'"/>
    <ProductDetailPopup3 v-else-if="popupType == 'productDetailPopup3'"/>
    <ProductDetailPopup4 v-else-if="popupType == 'productDetailPopup4'"/>
    <ProductDetailPopup5 v-else-if="popupType == 'productDetailPopup5'"/>
    <ProductDetailPopup6 v-else-if="popupType == 'productDetailPopup6'"/>
    <ProductDetailPopup7 v-else-if="popupType == 'productDetailPopup7'"/>

    <!-- 펌웨어 그룹 관리 -->
    <FirmwarePopup1 v-else-if="popupType == 'firmwarePopup1'"/>
    <FirmwareDetailPopup1 v-else-if="popupType == 'firmwareDetailPopup1'"/>

    <!-- 라이선스 그룹 관리 -->
    <LicenseGroupPopup1 v-else-if="popupType == 'licenseGroupPopup1'"/>
    <LicenseGroupDetailPopup1 v-else-if="popupType == 'licenseGroupDetailPopup1'"/>

    <!-- 사용자 관리 -->
    <UserPopup1 v-else-if="popupType == 'userPopup1'"/>
    <!-- <UserPopup2 v-else-if="popupType == 'userPopup2'"/> -->
    <UserDetailPopup1 v-else-if="popupType == 'userDetailPopup1'"/>
    <UserDetailPopup2 v-else-if="popupType == 'userDetailPopup2'"/>
    <UserDetailPopup3 v-else-if="popupType == 'userDetailPopup3'"/>

    <!-- 부품 관리 -->
    <PartPopup1 v-else-if="popupType == 'partPopup1'"/>
    <PartDetailPopup1 v-else-if="popupType == 'partDetailPopup1'"/>

    <!-- 대리점 코드 변경 -->
    <AgencyCodeChangePopup1 v-else-if="popupType == 'agencyCodeChangePopup1'"/>
    <AgencyCodeChangePopup2 v-else-if="popupType == 'agencyCodeChangePopup2'"/>

    <!-- 대리점 관리 -->
    <AgencyPopup1 v-else-if="popupType == 'agencyPopup1'"/>
    <AgencyDetailPopup1 v-else-if="popupType == 'agencyDetailPopup1'"/>
    
    <!-- 환경설정 -->
    <SettingPartPopup1 v-else-if="popupType == 'settingPartPopup1'"/>
    <SettingPartPopup2 v-else-if="popupType == 'settingPartPopup2'"/>
    <SettingProductPopup1 v-else-if="popupType == 'settingProductPopup1'"/>
    <SettingProductPopup2 v-else-if="popupType == 'settingProductPopup2'"/>
    <SettingCountryPopup1 v-else-if="popupType == 'settingCountryPopup1'"/>
    <SettingCountryPopup2 v-else-if="popupType == 'settingCountryPopup2'"/>
    <SettingInfoPopup1 v-else-if="popupType == 'settingInfoPopup1'"/>
    <SettingInfoPopup2 v-else-if="popupType == 'settingInfoPopup2'"/>
    <SettingInfoPopup3 v-else-if="popupType == 'settingInfoPopup3'"/>

</template>

<script>
import DeletePopup from './popup/common/DeletePopup.vue'
import SavePopup1 from './popup/common/SavePopup1.vue'
import SavePopup2 from './popup/common/SavePopup2.vue'
import EditPopup1 from './popup/common/EditPopup1.vue'
import CheckBoxPopup1 from './popup/common/CheckBoxPopup1.vue'
import CheckBoxPopup2 from './popup/common/CheckBoxPopup2.vue'
import CheckBoxPopup3 from './popup/common/CheckBoxPopup3.vue'
import ResultPopup1 from './popup/common/ResultPopup1.vue'
import ResultPopup2 from './popup/common/ResultPopup2.vue'
import ResultPopup3 from './popup/common/ResultPopup3.vue'

import AlertPopup1 from './popup/common/AlertPopup1.vue'

import ProductPopup1 from './popup/product/ProductPopup1.vue'
import ProductPopup2 from './popup/product/ProductPopup2.vue'
import ProductDetailPopup1 from './popup/product/ProductDetailPopup1.vue'
import ProductDetailPopup2 from './popup/product/ProductDetailPopup2.vue'
import ProductDetailPopup3 from './popup/product/ProductDetailPopup3.vue'
import ProductDetailPopup4 from './popup/product/ProductDetailPopup4.vue'
import ProductDetailPopup5 from './popup/product/ProductDetailPopup5.vue'
import ProductDetailPopup6 from './popup/product/ProductDetailPopup6.vue'
import ProductDetailPopup7 from './popup/product/ProductDetailPopup7.vue'

import FirmwarePopup1 from './popup/firmware/FirmwarePopup1.vue'
import FirmwareDetailPopup1 from './popup/firmware/FirmwareDetailPopup1.vue'

import LicenseGroupPopup1 from './popup/licenseGroup/LicenseGroupPopup1.vue'
import LicenseGroupDetailPopup1 from './popup/licenseGroup/LicenseGroupDetailPopup1.vue'

import UserPopup1 from './popup/user/UserPopup1.vue'
// import UserPopup2 from './popup/user/UserPopup2.vue'
import UserDetailPopup1 from './popup/user/UserDetailPopup1.vue'
import UserDetailPopup2 from './popup/user/UserDetailPopup2.vue'
import UserDetailPopup3 from './popup/user/UserDetailPopup3.vue'

import PartPopup1 from './popup/part/PartPopup1.vue'
import PartDetailPopup1 from './popup/part/PartDetailPopup1.vue'

import AgencyCodeChangePopup1 from './popup/agencyCodeChange/AgencyCodeChangePopup1.vue'
import AgencyCodeChangePopup2 from './popup/agencyCodeChange/AgencyCodeChangePopup2.vue'

import AgencyPopup1 from './popup/agency/AgencyPopup1.vue'
import AgencyDetailPopup1 from './popup/agency/AgencyDetailPopup1.vue'

import SettingPartPopup1 from './popup/setting/SettingPartPopup1.vue'
import SettingPartPopup2 from './popup/setting/SettingPartPopup2.vue'
import SettingProductPopup1 from './popup/setting/SettingProductPopup1.vue'
import SettingProductPopup2 from './popup/setting/SettingProductPopup2.vue'
import SettingCountryPopup1 from './popup/setting/SettingCountryPopup1.vue'
import SettingCountryPopup2 from './popup/setting/SettingCountryPopup2.vue'
import SettingInfoPopup1 from './popup/setting/SettingInfoPopup1.vue'
import SettingInfoPopup2 from './popup/setting/SettingInfoPopup2.vue'
import SettingInfoPopup3 from './popup/setting/SettingInfoPopup3.vue'

// import { mapState } from 'vuex' 
import { ref, inject, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
export default {
    name: 'Popup',
    setup() {
        const popupType = ref('');

        // const store = useStore();
        // const popupType = computed(() => store.state.popupModule.popupType)

        const emitter = inject('emitter')

        // 이벤트 구독
        const onPopupType = (data) => {
            popupType.value = data;
        };

        // 결과값(ResultPopup1) 전용 이벤트
        const returnPath = ref(null);
        const message = ref('');
        const onReturnPath = (data) => {
            returnPath.value = data;
        };
        const onMessage = (data) => {
            message.value = data;
        };

        onMounted(() => {
            emitter.on('popupType', onPopupType);
            emitter.on('returnPath', onReturnPath);
            emitter.on('message', onMessage);
        })

        const onBeforeUnmountHandler = () => {
            emitter.off('popupType', onPopupType);
            emitter.off('returnPath', onReturnPath);
            emitter.off('message', onMessage);
        };

        onBeforeUnmount(onBeforeUnmountHandler);
      
        return {
            popupType,

            returnPath,
            message,
        }
    },
    components: {
        DeletePopup,
        SavePopup1,
        SavePopup2,
        EditPopup1,
        CheckBoxPopup1,
        CheckBoxPopup2,
        CheckBoxPopup3,
        ResultPopup1,
        ResultPopup2,
        ResultPopup3,

        AlertPopup1,
        
        ProductPopup1,
        ProductPopup2,
        ProductDetailPopup1,
        ProductDetailPopup2,
        ProductDetailPopup3,
        ProductDetailPopup4,
        ProductDetailPopup5,
        ProductDetailPopup6,
        ProductDetailPopup7,

        FirmwarePopup1,
        FirmwareDetailPopup1,

        LicenseGroupPopup1,
        LicenseGroupDetailPopup1,

        UserPopup1,
        // UserPopup2,
        UserDetailPopup1,
        UserDetailPopup2,
        UserDetailPopup3,

        PartPopup1,
        PartDetailPopup1,

        AgencyCodeChangePopup1,
        AgencyCodeChangePopup2,

        AgencyPopup1,
        AgencyDetailPopup1,

        SettingPartPopup1,
        SettingPartPopup2,
        SettingProductPopup1,
        SettingProductPopup2,
        SettingCountryPopup1,
        SettingCountryPopup2,
        SettingInfoPopup1,
        SettingInfoPopup2,
        SettingInfoPopup3,
    },
}
</script>

<style>

</style>