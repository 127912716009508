<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/agency')" class="prev">대리점 관리</a>
                <a href="" class="now">상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/agency')" class="btn-sb-g">목록으로</button>
                        <!-- 관리자에게만 보이는 메뉴 : 삭제 -->
                        <button v-if="userAuth == 0 || userAuth == 1" 
                            @click="openPopup('deletePopup')" 
                            class="btn-sb-g">삭제
                        </button>
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">대리점명</li>
                            <li class="table-item">
                                <span>{{agencyData.customerName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">국가</li>
                            <li class="table-item">
                                <span>{{agencyData.nationName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">등록 일시</li>
                            <li class="table-item"> 
                                <span>{{agencyData.insertDate}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">대표자명</li>
                            <li class="table-item">
                                <span>{{agencyData.representativeName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">주소</li>
                            <li class="table-item">
                                <span>{{agencyData.address}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <span></span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul class="memo border-b0">
                            <li class="table-tit">메모</li>
                            <li class="table-item">
                                <!-- <textarea name="" id="" cols="30" rows="10" v-model="textAreaInput" @input="limitTextArea"></textarea> -->
                                <textarea name="" id="" cols="30" rows="10" v-model="memo" @input="limitTextArea"></textarea>
                            </li>
                        </ul>
                        <ul class="memo-btn">
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <div class="btn-box multi right">
                                    <button @click="openPopup('savePopup1')" class="btn-sb-g">저장</button>
                                </div>
                            </li>
                        </ul>
                    </div>  
                </div>
                <!-- 다른 대리점 및 현 대리점에 속한 장비는 조회되지 않도록 처리 -->
                <h3>장비 할당</h3>
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="openPopup('agencyDetailPopup1')" class="btn-sb-g">추가</button>
                        <!-- <button @click="next" class="btn-sb-g" :class="{'off': !classOff}">추가</button> -->
                        <button v-if="userAuth != 11" @click="next" class="btn-sb-g" :class="{'off': !classOff}">삭제</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li :style="{flex : 1}">
                                    <div class="chk-box">
                                        <input type="checkbox" class="chk_btn" v-model="checkedAll" value="all" id="all">
                                        <label for="all" class="chk"></label>
                                    </div>
                                </li>
                                <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :name="column.engName" :title="column.korName">{{column.korName}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li :style="{flex : 1}">
                                    <div class="chk-box">
                                        <input type="checkbox" class="chk_btn" :id="row.productId" :value="row.productId" v-model="checkList">
                                        <label :for="row.productId" class="chk"></label>
                                    </div>
                                </li>
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="goProductDetail(column.engName, row.productSn, row.productId)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :class="{'pointer' : column.engName == 'productSn'}"
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readList"/>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">삭제</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            선택한 장비를 삭제하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="deleteCustomerProduct" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validateText } from '@/js/common/regex.js';
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'AgencyDetail',
    setup() {
        // 상세보기 데이터
        const agencyData = ref({});             // 대리점 상세정보
        const customerName = ref(null);         // 대리점명
        const representativeName = ref(null);   // 대표자명
        const nationName = ref(null);           // 국가
        const address = ref(null);              // 주소
        const insertDate = ref(null);           // 등록 일시
        const memo = ref("");                   // 메모

        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let totalCount = ref(1);
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_agencyDetail || 5);
        let activeParam = ref(null);

        let checkList = ref([]);
        let textAreaInput = ref("");

        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const customerId = computed(() => store.state.dataModule.agencyDetail_customerId);
        const insertId = computed(() => store.state.profileModule.id);
        let utcTime = computed(() => store.state.utcTime);
        
        const emitter = inject('emitter');
        const router = useRouter();
        const dateUtils = inject('dateUtils');

        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);

        // 제품 일련번호를 클릭 => 해당 번호의 제품 관리 상세보기로 이동
        const goProductDetail = ((key, productSn, productId) => {
            if(key === 'productSn') {
                store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_SN', productSn);
                store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_ID', productId);
                router.push('/productDetail');
            }
        });

        // 메모 길이 제한
        const limitTextArea = (() => {
            // if (!validateText(textAreaInput.value)) {
            //     textAreaInput.value = textAreaInput.value.slice(0, 4096);
            // }
            if (!validateText(memo.value)) {
                memo.value = memo.value.slice(0, 4096);
            }
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType);
        });      

        // 상세보기 데이터 불러오기
        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/customer/getData',
                    "param": {
                        "customerId": customerId.value,
                    }
                }
            ).then(result => {
                agencyData.value = result.data.data;
                agencyData.value.insertDate = dateUtils.convertUTC(agencyData.value.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                memo.value = result.data.data.memo;
                // customerName.value = result.data.data.customerName;
                // representativeName.value = result.data.data.representativeName;
                // nationName.value = result.data.data.nationName;
                // address.value = result.data.data.address;
                // insertDate.value = dateUtils.convertUTC(result.data.data.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 장비 할당
        const readList = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_AGENCY_DETAIL', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "agencyCustomerId": customerId.value,
                    "isUse": true,
                    // "salesCustomerId": customerId.value
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": param
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 2, align: 'center'},
                    { engName: 'productSn', korName: '제품 일련 번호', flex: 3, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 4, align: 'left'},
                    { engName: 'nationName', korName: '판매 국가', flex: 3, align: 'center'},
                    { engName: 'salesCustomerName', korName: '고객사', flex: 7, align: 'left'},
                    { engName: 'customerName', korName: '판매처', flex: 7, align: 'left'},
                    { engName: 'orderCode', korName: '발주 주문 코드', flex: 3, align: 'center'},
                    { engName: 'releaseDate', korName: '출고일', flex: 5, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

                if(result.data.data.list.length == 0) {
                    totalCount.value = 0;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });
        
        const deleteFunction = (() => {
            let param = [{
                "customerId": customerId.value,
                "insertId": insertId.value,
                "isUse": false
            }];

            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/customer/updateUseList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {  
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 메모 저장 function
        const updateFunction = (() => {
            const param = {
                "customerId": customerId.value,
                "insertId": insertId.value,
                "customerCode": agencyData.value.customerCode,
                "customerName": agencyData.value.customerName,
                "representativeName": agencyData.value.representativeName,
                "isManage": agencyData.value.isManage,
                "customerType": agencyData.value.customerType,
                "address": agencyData.value.address,
                "nation": agencyData.value.nation,
                "nationName": agencyData.value.nationName,
                "joinDate": agencyData.value.joinDate,
                "remark": agencyData.value.remark,
                "isUse": agencyData.value.isUse,
                "memo": memo.value
            };
            
            store.dispatch(
                'dataModule/updateData', 
                {
                    "url": '/api/v1/customer/update',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const next = (() => {
            if(checkList.value.length == 0) {
                warning.value = true;
                message.value = '삭제할 장비를 선택해주세요.';
                return;
            } else if(checkList.value.length > 0) {
                confirm.value = true;
            }
        });

        // 장비할당 - 삭제
        const deleteCustomerProduct = (() => {
            let param = [];
            checkList.value.some(item => {
                let deleteData = {
                    "insertId": insertId.value,
                    "customerId": customerId.value,
                    "productId": item,
                }
                param.push(deleteData);
            });

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/customer-product/deleteList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    checkList.value = [];
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 메모 저장 이후 데이터 다시 조회
        const onReData = (data) => {
            if(data) {
                readData();
                readList(1, "search");
            }
        };

        const onGoMenu = (data) => {
            router.push('/agency');
        };

        // computed 설정
        const checkedAll = computed({
            get: () => {
                checkList.value.sort((a, b) => a - b);
                return rowData.value.length === checkList.value.length;
            },
            set: (e) => {
                if (e) {
                    checkList.value = [];
                    rowData.value.forEach((r) => {
                        checkList.value.push(r.productId);
                    });
                } else {
                    checkList.value = [];
                }
            },
        });

        onBeforeMount(() => {
            // 상세보기
            readData();

            // 장비할당
            readList(1, "search");
        });

        onMounted(() => {
            emitter.on('delete', deleteFunction);
            emitter.on('execute', updateFunction);
            emitter.on('reData', onReData);
            emitter.on('goMenu', onGoMenu);
        })

        const onBeforeUnmountHandler = () => {
            emitter.off('delete', deleteFunction);
            emitter.off('execute', updateFunction);
            emitter.off('reData', onReData);
            emitter.off('goMenu', onGoMenu);
        };

        onBeforeUnmount(onBeforeUnmountHandler);

        watch(utcTime, () => {
            readData();
        });

        const classOff = ref(false);
        watch(checkList, () => {
            if(checkList.value.length > 0) {
                classOff.value = true;
            } else {
                classOff.value = false;
            }
        });

        return {
            // 상세보기 데이터
            agencyData,
            // customerName,
            // representativeName,
            // nationName,
            // address,
            // insertDate,
            memo,

            timer,
            columnData,
            rowData,
            totalCount,
            selectedPage,
            selectedPageSize,
            activeParam,

            classOff,
            checkList,
            textAreaInput,
            
            userAuth,
            customerId,
            insertId,

            message,
            warning,
            confirm,

            readData,
            readList,
            goProductDetail,
            limitTextArea,
            startTimer,
            clearTimer,
            openPopup,
            deleteFunction,
            updateFunction,
            
            next,
            deleteCustomerProduct,

            checkedAll,

            onBeforeUnmountHandler,
        }
    },
}
</script>

<style>

</style>