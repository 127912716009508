<template>
    <div class="error-box">
        <div class="error">
            <p class="tit">페이지를 찾을 수 없습니다.</p>
            <!-- <p class="cont">
                요청하신 페이지를 처리하는 도중 예기치 못한 에러가 발생했습니다. <br>
                잠시 후 다시 시도해주세요.
            </p> -->
            <button @click="goHome">확인</button>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
    name: 'Error',
    setup(props) {
        // router 선언
        const router = useRouter();

        const goHome = (() => {
            router.push('/product');
        });

        return {
            goHome
        }
    }
}
</script>

<style>
.error-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.error-box > .error {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.error-box > .error > .tit {
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
}
.error-box > .error > .cont {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    color: rgb(145, 145, 145);
}
.error-box > .error > button {
    width: 100px;
    height: 32px;
    margin-top: 20px;
    line-height: 32px;
    border-radius: 5px;
    border: 1px solid rgb(0, 29, 122);
    color: rgb(0, 29, 122);
}
</style>