<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">비밀번호 변경</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                <p>현재 비밀번호<em>*</em></p>
                                <!-- <span class="warning">비밀번호 불일치</span> -->
                            </li>
                            <li class="cont">
                                <input type="password" v-model="currentPw">
                            </li>
                            <li class="tit">
                                <p>새 비밀번호<em>*</em></p>
                                <span v-if="pwAlert" class="warning">비밀번호를 입력해주세요. (3~20자리)</span>
                            </li>
                            <li class="cont">
                                <input type="password" v-model="newPw">
                            </li>
                            <li class="tit">
                                <p>새 비밀번호 확인<em>*</em></p>
                                <span v-if="newPw !== confirmNewPw" class="warning">비밀번호 불일치</span>
                            </li>
                            <li class="cont">
                                <input type="password" v-model="confirmNewPw">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">비밀번호 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            비밀번호를 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { validatePw } from '../../../js/common/regex.js';
export default {
    name: 'SettingInfoPopup2',
    setup(){
        const store = useStore();
        const emitter = inject('emitter');

        const id = computed(() => store.state.profileModule.id);

        let currentPw = ref(null);
        let newPw = ref(null);
        let confirmNewPw = ref(null);
        let pwAlert = ref(false);
        let userData = ref([]);
        
        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        const cancel = ((popupType) => {
            emitter.emit('popupType', popupType);
        });

        const next = (() => {
            if(!currentPw.value) {
                warning.value = true;
                message.value = '현재 비밀번호를 입력해주세요.';
                return;
            }
            if(!newPw.value) {
                warning.value = true;
                message.value = '새 비밀번호를 입력해주세요.';
                return;
            }
            if(!confirmNewPw.value) {
                warning.value = true;
                message.value = '새 비밀번호 확인을 입력해주세요.';
                return;
            }
            if(pwAlert.value) {
                warning.value = true;
                message.value = '새 비밀번호 확인을 입력해주세요.';
                return;
            }
            
            confirm.value = true;
        });

        const execute = (() => {
            // checkCurrentPw();
            const param = {
                "id": id.value,
                "userPw": currentPw.value
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/user/getData',
                    "param": param
            }).then(result => {
                if (result.data.rtnType === "SUCCESS") {
                    if (result.data.data.id === id.value) {
                        userData.value = result.data.data;
                        updatePw();
                    } else {
                        confirm.value = false;
                        warning.value = true;
                        message.value = '현재 비밀번호가 일치하지 않습니다.';
                        return;
                    };
                };
            }).catch(error => {
                if(error.response.status == 400) {
                    confirm.value = false;
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });
        
        const checkCurrentPw = (() => {
            const param = {
                "id": id.value,
                "userPw": currentPw.value
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/user/getData',
                    "param": param
                }
            ).then(result => {
                if (result.data.rtnType === "SUCCESS") {
                    if (result.data.data.id === id.value) {
                        userData.value = result.data.data;
                        updatePw();
                    } else {
                        alert("현재 비밀번호가 일치하지 않습니다");
                    };
                };
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const updatePw = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/user/update',
                    "param": {
                        "insertId": id.value,
                        "id": id.value,
                        "userName": String(userData.value.userName),
                        "userPw": String(newPw.value),
                        "phone": String(userData.value.phone),
                        "email": String(userData.value.email),
                        "address": String(userData.value.address),
                        "userAuth": parseInt(userData.value.userAuth),
                        "userAuthSub": parseInt(userData.value.userAuthSub),
                        "remark": String(userData.value.remark),
                        "isUse": true,
                        "memo": String(userData.value.memo),
                    }
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    store.commit('dataModule/SET_USER_DETAIL_POPUP_DATA', null);
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        watch(newPw, () => {
            if(validatePw(newPw.value)) {
                pwAlert.value = false;
            } else {
                pwAlert.value = true;
            }
        });

        return {
            userData,
            currentPw,
            newPw,
            confirmNewPw,

            message,
            warning,
            confirm,

            pwAlert,
            next,
            execute,
            checkCurrentPw,
            updatePw,
            cancel,
        }
    },
}
</script>

<style>

</style>