<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">저장</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 저장하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
export default {
    name: 'SavePopup1',
    setup() {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const execute = (() => {
            emitter.emit('execute', true);
        });

        return {
            cancel,
            execute
        }
    },
    // methods: {
    //     cancel() {
    //         this.emitter.emit('popupType', '')
    //     }
    // },
}
</script>

<style>

</style>