const state = () => ({
    userAuth: null,
    userId: null,
    userName: null,
    customerId: null,
    customerCode: null,
    id: null,
})

const getters = {
    getUserAuth: (state) => state.userAuth,
    getUserId: (state) => state.userId,
    getUserName: (state) => state.userName,
    getCustomerId: (state) => state.customerId,
    getCustomerCode: (state) => state.customerCode,    
    getId: (state) => state.id,
}

const actions = {
    // actions
    // setCustomerCode(context, response) {    
    //     console.log(response)
    //     console.log(response.userInfo.customerId)
    //     console.log(response.tokenInfo.accessToken)
    //     // 대리점 사용자의 경우 대리점 코드를 세팅
    //     let param = {
    //         "customerId": response.userInfo.customerId,
    //         "isUse": true
    //     }
        
    //     axios.post(
    //         process.env.VUE_APP_BACKEND_HOST_URL + "/api/v1/customer/getData"
    //         ,param
    //         , {
    //             headers: {
    //                 Authorization: `Bearer ${response.tokenInfo.accessToken}`
    //             }
    //         }
    //     ).then(res => {
    //         console.log(res)
    //         context.commit('SET_CUSTOMER_CODE', res.data.data.customerCode);
    //         // resolve(res);
    //     }).catch(err => {
    //         console.log(err)
    //         let errorMessage = "서버와의 통신이 원활하지 않습니다.111"; // 기본 에러 메세지
        
    //         // err.response 및 err.response.data 및 err.response.data.data 가 있는지 확인
    //         if (err.response && err.response.data && err.response.data.data) {
    //             errorMessage = err.response.data.data; // 실제 에러 메세지를 업데이트
    //         }
        
    //         return { success: false, error: errorMessage }
    //     })
    // },
}

const mutations = {
    SET_USER_AUTH(state, payload) {
        state.userAuth = payload
    },
    SET_USER_ID(state, payload) {
        state.userId = payload
    },
    SET_USER_NAME(state, payload) {
        state.userName = payload
    },
    SET_CUSTOMER_ID(state, payload) {
        state.customerId = payload
    },
    SET_CUSTOMER_CODE(state, payload) {
        state.customerCode = payload
    },
    SET_ID(state, payload) {
        state.id = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}