import router from '@/router/router'
import axios from 'axios'

const state = () => ({
    returnParam: null,                            // 상세보기에서 목록으로 돌아올 때 사용할 검색조건
    rowData: [],
    columnData: [],
    fixedColumn: [],                            // 제품 관리 필드 선택 Array
    productPopup_checkList: [],                 // 제품 관리 팝업_param
    productDetail_productSn: null,              // 제품 관리 상세보기_param
    productDetail_productId: null,              // 제품 관리 상세보기 로그_param
    productDetail_modelId: null,                // 제품 관리 상세보기_param
    cartridge_productSn: null,                  // 제품관리 => 카트리지/팁 로그_param
    system_productSn: null,                     // 제품관리 => 시스템 로그_param
    partDetail_data: null,                      // 부품 관리 상세보기_param
    userDetail_id: null,                        // 사용자 관리 상세보기_param
    userDetailPopup_data: null,                 // 사용자 분류 변경_param
    agencyDetail_customerId: null,              // 대리점 관리 상세보기_param
    firmwareDetail_firmwareModelId: null,       // 펌웨어 그룹 관리 상세보기_param
    firmwareDetail_modelId: null,               // 펌웨어 그룹 관리 상세보기_param2
    licenseGroupDetail_licenseId: null,         // 라이선스 그룹 관리 상세보기_param
    settingPartPopup_checkList: [],             // 부품 목록 코드 팝업_param
    settingProductPopup_checkList: [],          // 제품 목록 코드 팝업_param
    settingCountryPopup_checkList: [],          // 국가 목록 코드 팝업_param
    licenseKey_data: {},                        // 라이선스 키 생성 관련_param
    licenseDetail_deviceLicenseId: null,        // 라이선스 관리 상세보기_param
    agencyCodeDetail_deviceLicenseId: null,     // 대리점코드변경 관리 상세보기_param

    // 페이지 사이즈
    pageSize_product: 10,                       // 제품관리 페이지 사이즈
    pageSize_productDetail: 5,                  // 제품관리 상세보기 페이지 사이즈
    pageSize_productDetailPopup7: 5,            // 제품관리 상세보기 메모 히스토리 페이지 사이즈
    pageSize_cartridge: 10,                     // 카트리지/팁 로그 페이지 사이즈
    pageSize_system: 10,                        // 시스템 로그 페이지 사이즈
    pageSize_changeHistory: 10,                 // 변경 이력 로그 페이지 사이즈
    pageSize_firmware: 10,                      // 펌웨어 그룹 관리 페이지 사이즈
    pageSize_firmwareDetail: 5,                 // 펌웨어 그룹 관리 상세보기 페이지 사이즈
    pageSize_licenseGroup: 10,                  // 라이선스 그룹 관리 페이지 사이즈
    pageSize_licenseGroupDetail: 5,             // 라이선스 그룹 관리 상세보기 페이지 사이즈
    pageSize_agencyCode: 10,                    // 대리점 코드 변경 관리 페이지 사이즈
    pageSize_agency: 10,                        // 대리점 관리 페이지 사이즈
    pageSize_agencyDetail: 5,                   // 대리점 관리 상세보기 페이지 사이즈
    pageSize_agencyDetailPopup1: 5,             // 대리점 관리 상세보기 장비 할당 추가 팝업 페이지 사이즈
    pageSize_license: 5,                        // 라이선스 관리 페이지 사이즈
    pageSize_agencyCodeChange: 5,               // 대리점 코드 변경 페이지 사이즈
    pageSize_productDate: 5,                    // 제품 날짜 변경 페이지 사이즈
    pageSize_serialNumber: 5,                   // 일련 번호 변경 페이지 사이즈
    pageSize_part: 10,                          // 부품 관리 페이지 사이즈
    pageSize_partDetail: 5,                     // 부품 관리 상세보기 페이지 사이즈
    pageSize_user: 10,                          // 사용자 관리 페이지 사이즈
    pageSize_settingPart: 10,                   // 부품 목록 코드 페이지 사이즈
    pageSize_settingProduct: 10,                // 제품 목록 코드 페이지 사이즈
    pageSize_settingCountry: 10,                // 국가 목록 코드 페이지 사이즈
})

const getters = {
    getReturnParam: (state) => state.returnParam,
    getRowData: (state) => state.rowData,
    getColumnData: (state) => state.columnData,
    getFixedColumn: (state) => state.fixedColumn,
    getProductPopup_checkList: (state) => state.productPopup_checkList,
    getProductDetail_productSn: (state) => state.productDetail_productSn,
    getProductDetail_productId: (state) => state.productDetail_productId,
    getProductDetail_modelId: (state) => state.productDetail_modelId,
    getCartridge_productSn: (state) => state.cartridge_productSn,
    getSystem_productSn: (state) => state.system_productSn,
    getPartDetail_data: (state) => state.partDetail_data,
    getUserDetail_id: (state) => state.userDetail_id,
    getUserDetailPopup_data: (state) => state.userDetailPopup_data,
    getAgencyDetail_customerId: (state) => state.agencyDetail_customerId,
    getFirmwareDetail_firmwareModelId: (state) => state.firmwareDetail_firmwareModelId,
    getFirmwareDetail_modelId: (state) => state.firmwareDetail_modelId,
    getLicenseGroupDetail_licenseId: (state) => state.licenseGroupDetail_licenseId,
    getSettingPartPopup_checkList: (state) => state.settingPartPopup_checkList,
    getSettingProductPopup_checkList: (state) => state.settingProductPopup_checkList,
    getSettingCountryPopup_checkList: (state) => state.settingCountryPopup_checkList,
    getLicenseKey_data: (state) => state.licenseKey_data,
    getLicenseDetail_deviceLicenseId: (state) => state.licenseDetail_deviceLicenseId,
    getAgencyCodeDetail_deviceLicenseId: (state) => state.agencyCodeDetail_deviceLicenseId,

    getPageSize_product: (state) => state.pageSize_product,
    getPageSize_productDetail: (state) => state.pageSize_productDetail,
    getPageSize_productDetailPopup7: (state) => state.pageSize_productDetailPopup7,
    getPageSize_cartridge: (state) => state.pageSize_cartridge,
    getPageSize_system: (state) => state.pageSize_system,
    getPageSize_changeHistory: (state) => state.pageSize_changeHistory,
    getPageSize_firmware: (state) => state.pageSize_firmware,
    getPageSize_firmwareDetail: (state) => state.pageSize_firmwareDetail,
    getPageSize_licenseGroup: (state) => state.pageSize_licenseGroup,
    getPageSize_licenseGroupDetail: (state) => state.pageSize_licenseGroupDetail,
    getPageSize_agencyCode: (state) => state.pageSize_agencyCode,
    getPageSize_agency: (state) => state.pageSize_agency,
    getPageSize_agencyDetail: (state) => state.pageSize_agencyDetail,
    getPageSize_agencyDetailPopup1: (state) => state.pageSize_agencyDetailPopup1,
    getPageSize_license: (state) => state.pageSize_license,
    getPageSize_agencyCodeChange: (state) => state.pageSize_agencyCodeChange,
    getPageSize_productDate: (state) => state.pageSize_productDate,
    getPageSize_serialNumber: (state) => state.pageSize_serialNumber,
    getPageSize_part: (state) => state.pageSize_part,
    getPageSize_partDetail: (state) => state.pageSize_partDetail,
    getPageSize_user: (state) => state.pageSize_user,
    getPageSize_settingPart: (state) => state.pageSize_settingPart,
    getPageSize_settingProduct: (state) => state.pageSize_settingProduct,
    getPageSize_settingCountry: (state) => state.pageSize_settingCountry,
}

const actions = {
    // 조회 (목록)
    getList(context, response) {
        // const isLoading = context.rootGetters['getIsLoading'];
        // console.log(isLoading);
        
        const accessToken = context.rootGetters['loginModule/getAccessToken'];
        
        context.commit('SET_LOADING_BAR', true, { root: true });

        return new Promise((resolve, reject) => {
            axios.post(
                process.env.VUE_APP_BACKEND_HOST_URL + response.url
                ,response.param
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            ).then(res => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                resolve(res);
            }).catch(error => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                reject(error);
            })
        });

    },

    // 조회 (상세보기 등)
    getData(context, response) {
        const accessToken = context.rootGetters['loginModule/getAccessToken'];
        context.commit('SET_LOADING_BAR', true, { root: true });
        return new Promise((resolve, reject) => {
            axios.post(
                process.env.VUE_APP_BACKEND_HOST_URL + response.url
                ,response.param
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            ).then(res => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                resolve(res);
            }).catch(error => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                reject(error);
            })
        });
    },

    // 추가
    insertData(context, response) {
        const accessToken = context.rootGetters['loginModule/getAccessToken'];
        context.commit('SET_LOADING_BAR', true, { root: true });
        return new Promise((resolve, reject) => {
            axios.post(
                process.env.VUE_APP_BACKEND_HOST_URL + response.url
                ,response.param
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            ).then(res => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                resolve(res);
            }).catch(error => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                reject(error);
            })
        });
    },

    // 수정
    updateData(context, response) {
        const accessToken = context.rootGetters['loginModule/getAccessToken'];
        context.commit('SET_LOADING_BAR', true, { root: true });
        return new Promise((resolve, reject) => {
            axios.post(
                process.env.VUE_APP_BACKEND_HOST_URL + response.url
                ,response.param
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            ).then(res => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                resolve(res);
            }).catch(error => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                reject(error);
            })
        });
    },

    // 삭제
    deleteData(context, response) {
        const accessToken = context.rootGetters['loginModule/getAccessToken'];
        context.commit('SET_LOADING_BAR', true, { root: true });
        return new Promise((resolve, reject) => {
            axios.post(
                process.env.VUE_APP_BACKEND_HOST_URL + response.url
                ,response.param
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            ).then(res => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                resolve(res);
            }).catch(error => {
                context.commit('SET_LOADING_BAR', false, { root: true });
                reject(error);
            })
        });
    },
}

const mutations = {
    SET_RETURN_PARAM(state, returnParam) {
        state.returnParam = returnParam
    },
    SET_ROW_DATA(state, rowData) {
        state.rowData = rowData
    },
    SET_COLUMN_DATA(state, columnData) {
        state.columnData = columnData
    },
    SET_FIXED_COLUMN(state, payload) {
        state.fixedColumn = payload
    },
    SET_PRODUCT_POPUP_CHECK_LIST(state, payload) {
        state.productPopup_checkList = payload
    },
    SET_PRODUCT_DETAIL_PRODUCT_SN(state, payload) {
        state.productDetail_productSn = payload
    },
    SET_PRODUCT_DETAIL_PRODUCT_ID(state, payload) {
        state.productDetail_productId = payload
    },
    SET_PRODUCT_DETAIL_MODEL_ID(state, payload) {
        state.productDetail_modelId = payload
    },
    SET_CARTRIDGE_PRODUCT_SN(state, payload) {
        state.cartridge_productSn = payload
    },
    SET_SYSTEM_PRODUCT_SN(state, payload) {
        state.system_productSn = payload
    },
    SET_PART_DETAIL_PARAM(state, payload) {
        state.partDetail_data = payload
    },
    SET_USER_DETAIL_ID(state, payload) {
        state.userDetail_id = payload
    },
    SET_USER_DETAIL_POPUP_DATA(state, payload) {
        state.userDetailPopup_data = payload
    },
    SET_AGENCY_DETAIL_CUSTOMER_ID(state, payload) {
        state.agencyDetail_customerId = payload
    },
    SET_FIRMWARE_DETAIL_FIRMWARE_MODEL_ID(state, payload) {
        state.firmwareDetail_firmwareModelId = payload
    },
    SET_FIRMWARE_DETAIL_MODEL_ID(state, payload) {
        state.firmwareDetail_modelId = payload
    },
    SET_LICENSEGROUP_DETAIL_LICENSE_ID(state, payload) {
        state.licenseGroupDetail_licenseId = payload
    },
    SET_SETTING_PART_POPUP_CHECK_LIST(state, payload) {
        state.settingPartPopup_checkList = payload
    },
    SET_SETTING_PRODUCT_POPUP_CHECK_LIST(state, payload) {
        state.settingProductPopup_checkList = payload
    },
    SET_SETTING_COUNTRY_POPUP_CHECK_LIST(state, payload) {
        state.settingCountryPopup_checkList = payload
    },
    SET_LICENSE_KEY_DATA(state, payload) {
        state.licenseKey_data = payload
    },
    SET_LICENSE_DETAIL_DEVICE_LICENSE_ID(state, payload) {
        state.licenseDetail_deviceLicenseId = payload
    },
    SET_AGENCYCODE_DETAIL_DEVICE_LICENSE_ID(state, payload) {
        state.agencyCodeDetail_deviceLicenseId = payload
    },
    
    SET_PAGESIZE_PRODUCT(state, payload) {
        state.pageSize_product = payload
    },
    SET_PAGESIZE_PRODUCT_DETAIL(state, payload) {
        state.pageSize_productDetail = payload
    },
    SET_PAGESIZE_PRODUCT_DETAIL_POPUP7(state, payload) {
        state.pageSize_productDetailPopup7 = payload
    },
    SET_PAGESIZE_CARTRIDGE(state, payload) {
        state.pageSize_cartridge = payload
    },
    SET_PAGESIZE_SYSTEM(state, payload) {
        state.pageSize_system = payload
    },
    SET_PAGESIZE_CHANGEHISTORY(state, payload) {
        state.pageSize_changeHistory = payload
    },
    SET_PAGESIZE_FIRMWARE(state, payload) {
        state.pageSize_firmware = payload
    },
    SET_PAGESIZE_FIRMWARE_DETAIL(state, payload) {
        state.pageSize_firmwareDetail = payload
    },
    SET_PAGESIZE_LICENSEGROUP(state, payload) {
        state.pageSize_licenseGroup = payload
    },
    SET_PAGESIZE_LICENSEGROUP_DETAIL(state, payload) {
        state.pageSize_licenseGroupDetail = payload
    },
    SET_PAGESIZE_AGENCY(state, payload) {
        state.pageSize_agency = payload
    },
    SET_PAGESIZE_AGENCY_DETAIL(state, payload) {
        state.pageSize_agencyDetail = payload
    },
    SET_PAGESIZE_AGENCY_DETAIL_POPUP1(state, payload) {
        state.pageSize_agencyDetailPopup1 = payload
    },
    SET_PAGESIZE_LICENSE(state, payload) {
        state.pageSize_license = payload
    },
    SET_PAGESIZE_AGENCYCODECHANGE(state, payload) {
        state.pageSize_agencyCodeChange = payload
    },
    SET_PAGESIZE_PRODUCTDATE(state, payload) {
        state.pageSize_productDate = payload
    },
    SET_PAGESIZE_SERIALNUMBER(state, payload) {
        state.pageSize_serialNumber = payload
    },
    SET_PAGESIZE_PART(state, payload) {
        state.pageSize_part = payload
    },
    SET_PAGESIZE_PART_DETAIL(state, payload) {
        state.pageSize_partDetail = payload
    },
    SET_PAGESIZE_USER(state, payload) {
        state.pageSize_user = payload
    },
    SET_PAGESIZE_SETTING_PART(state, payload) {
        state.pageSize_settingPart = payload
    },
    SET_PAGESIZE_SETTING_PRODUCT(state, payload) {
        state.pageSize_settingProduct = payload
    },
    SET_PAGESIZE_SETTING_COUNTRY(state, payload) {
        state.pageSize_settingCountry = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}