<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">제품 대리점 코드 변경 키</div>
                <div class="inner">
                    <div class="cont">
                        <p class="big">
                            ABCDEF1234567890
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="cancel" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'AgencyCodeChangePopup2',
    setup() {
        const emitter = inject('emitter');

        const store = useStore();

        const cancel = (() => {
            emitter.emit('popupType', '');
        });
        
        return {
            cancel
        }
    }
}
</script>

<style>

</style>