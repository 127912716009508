<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="close" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
    name: 'AlertPopup1',
    props: {
        message: String,
    },
    setup(props) {
        const message = ref(props.message);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // router 선언
        const router = useRouter();

        const close = (() => {
            emitter.emit('popupType', '');
        });


        onMounted(() => {
        })


        return {
            message,
            close,
        }
    },
}
</script>

<style>

</style>