<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">펌웨어 관리</div>
                <div class="inner">
                    <div class="cont">
                        <div class="select-box pbot20">
                            <div class="tit">펌웨어 그룹</div>
                            <ul class="input-txt-list">
                                <li class="">
                                    <v-autocomplete
                                    :items="firmwareList"
                                    item-title="groupName"
                                    v-model="selectedFirmwareList"
                                    prepend-inner-icon="mdi-magnify"
                                    theme="light"
                                    variant="outlined"
                                    item-props
                                    return-object
                                    :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                    ></v-autocomplete>
                                </li>
                            </ul>
                        </div>
                        <div class="cont">
                            <ul v-for="(item) in firmwareModelPartArray" :key="item" class="input-txt-list">
                                <li class="tit">{{item.partCode}} 펌웨어 버전 / URL</li>
                                <li class="cont pbot20">
                                    <input type="text" v-model="item.firmwareVersion" class="readonly" :disabled="true">
                                    <input type="text" v-model="item.url" class="readonly" :disabled="true">
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="alert" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            펌웨어 그룹을 선택해주세요.
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="alert = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import firmwareAuto from '@/js/autocomplete/firmwareAuto';
export default {
    name: 'ProductPopup2',
    setup(props) {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // store에서 데이터 가져오기
        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);
        const productIdList = computed(() => store.state.dataModule.productPopup_checkList);

        let alert = ref(false);
        let confirm = ref(false);

        let firmwareModelPartArray = ref([]);

        const firmwareModelId = ref(null);
        const selectedFirmwareList = ref([]);

        const {
            firmwareList,
            getFirmware,
        } = firmwareAuto();

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(firmwareModelId.value == null || firmwareModelId.value == '') {
                alert.value = true;
                return;
            }
            confirm.value = true;
        });

        const execute = (() => {
            let param = {
                "firmwareModelId": firmwareModelId.value,
                "productIds": productIdList.value,
                "insertId": insertId.value,
                "licenseId": 1
            };
            
            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/product/firmwareChange',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const getFirmwareModelPart = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/firmware-model-part/getList',
                    "param": {
                        "isUse": true,
                        "firmwareModelId": firmwareModelId.value
                    }
                }
            ).then(result => {
                firmwareModelPartArray.value = result.data.data;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // watch(firmwareModelId, () => {
        //     if(firmwareModelId.value != null && firmwareModelId.value != '') {
        //         getFirmwareModelPart();
        //     } else {
        //         firmwareModelPartArray.value = [];
        //     }
        // });

        watch(selectedFirmwareList, (item) => {
            if(item != null) {
                firmwareModelId.value = item.firmwareModelId;
                getFirmwareModelPart();
            } else {
                firmwareModelId.value = null;
                firmwareModelPartArray.value = [];
            }
        });

        onBeforeMount(() => {
            getFirmware();
        });

        return {
            insertId,
            productIdList,

            alert,
            confirm,

            firmwareModelPartArray,

            firmwareModelId,
            firmwareList,
            getFirmware,

            selectedFirmwareList,

            cancel,
            next,
            execute,
        }
    }
}
</script>

<style>

</style>