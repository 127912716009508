<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/agencyCode')" class="prev">대리점 코드 변경 관리</a>
                <a href="" class="now">상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/agencyCode')" class="btn-sb-g">목록으로</button>
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || 
                                (userAuthSub == 1 && (userAuth == 4 || userAuth == 5 || userAuth == 6 || userAuth == 7)) " 
                            @click="openPopup('deletePopup')" 
                            class="btn-sb-g">삭제
                        </button>
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">대리점명</li>
                            <li class="table-item">
                                <span>{{detailData.customerName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">아이디</li>
                            <li class="table-item"> 
                                <span>{{detailData.userId}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">국가</li>
                            <li class="table-item">
                                <span>{{detailData.nationName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">연락처</li>
                            <li class="table-item">
                                <span>{{detailData.phone}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">일련번호</li>
                            <li class="table-item">
                                <span>{{detailData.productSn}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">requestCode</li>
                            <li class="table-item">
                                <span>{{detailData.requestCode}}</span>
                                <div class="btn-box right">
                                    <!-- <span class="done">복사완료</span>
                                    <button @click="copy" class="btn-mini">복사</button> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">요청자</li>
                            <li class="table-item">
                                <span>{{detailData.requestName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">이메일</li>
                            <li class="table-item"> 
                                <span>{{detailData.email}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">주소</li>
                            <li class="table-item">
                                <span>{{detailData.address}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">가입 일자</li>
                            <li class="table-item">
                                <span>{{detailData.joinDate}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">제품명</li>
                            <li class="table-item">
                                <span>{{detailData.modelName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <span></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="table clearfix">
                    <!-- <div class="group">
                        <ul>
                            <li class="table-tit">제품명</li>
                            <li class="table-item">
                                <span>{{detailData.userId}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">Request Code</li>
                            <li class="table-item">
                                <span>{{detailData.userId}}</span>
                                <div class="btn-box right">
                                    <span class="done">복사완료</span>
                                    <button class="btn-mini">복사</button>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                    <div class="group agencyCode">
                        <ul class="memo">
                            <li class="table-tit">요청사유</li>
                            <li class="table-item pbot10">
                                <textarea cols="30" rows="10" :value="detailData.requestReason" :readonly="true"></textarea>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="btn-box one mtop40">
                    <button @click="confirm = true" class="btn">키 생성</button>
                    <!-- <button @click="openPopup('agencyCodeDetailPopup1')" class="btn">키 생성</button> -->
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">대리점 코드 변경 관리</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            키 생성을 하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="apply" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'AgencyCodeDetail',
    setup() {
        // store에서 state 값 가져오기
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userAuthSub = computed(() => store.state.loginModule.userAuthSub);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // router 선언
        const router = useRouter();

        const dateUtils = inject('dateUtils');
        let utcTime = computed(() => store.state.utcTime);
        
        const insertId = computed(() => store.state.profileModule.id);

        const deviceLicenseId = computed(() => store.state.dataModule.agencyCodeDetail_deviceLicenseId);
        const detailData = ref({}); 
        const licenseKey = ref(null);       // 신규 라이선스 키

        const confirm = ref(false);

        let publicParam = ref(null);

        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/device-license/getCustomerData',
                    "param": {
                        "deviceLicenseId": deviceLicenseId.value,
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;
                detailData.value = result.data.data;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType);
        });

        const copy = ((text) => {
            
        })

        // 장비적용
        const apply = (() => {
            let param = {...publicParam.value}
            param.applyId = insertId.value;
            param.insertId = insertId.value;

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/device-license/apply',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                    readData();
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    confirm.value = false;
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 삭제
        const deleteFunction = (() => {
            let param = {
                "deviceLicenseId": deviceLicenseId.value,
                "insertId": insertId.value
            };

            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/device-license/delete',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 삭제 후 목록 화면으로 이동
        const onGoMenu = (data) => {
            router.push('/agencyCode');
        };

        watch(utcTime, () => {
            readData();
        });
        
        onMounted(() => {
            readData();
            emitter.on('delete', deleteFunction);
            emitter.on('goMenu', onGoMenu);
        });

        onBeforeUnmount(() => {
            emitter.off('delete', deleteFunction);
            emitter.off('goMenu', onGoMenu);
        });

        return {
            userAuth,
            userAuthSub,
            detailData,
            licenseKey,
            confirm,

            openPopup,
            apply,
        }
    },
}
</script>

<style>

</style>