<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">국가 목록 편집</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">국가 코드
                                <span class="">2자리 영어로 입력해주세요</span>
                            </li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="refData1" @input="checkRefData1">
                            </li>
                            <li class="tit">국가</li>
                            <li class="cont">
                                <input type="text" placeholder="" v-model="codeName">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">국가 목록 편집</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 편집하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'SettingCountryPopup2',
    setup(){
        const emitter = inject('emitter');
        const store = useStore();

        const checkList = computed(() => store.state.dataModule.settingCountryPopup_checkList);
        const insertId = computed(() => store.state.profileModule.id);

        let currentRefData1 = ref(null);
        let currentCodeName = ref(null);
        let refData1 = ref(null);
        let codeName = ref(null);

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        let regexConfirm = ref(true);

        // 국가 코드 제약조건 (영어? + 2자리)
        const checkRefData1 = (() => {
            refData1.value = refData1.value.toUpperCase();

            const regex = new RegExp("^[A-Za-z]{2}$");
            if(regex.test(refData1.value)) {
                regexConfirm.value = true;
            } else {
                regexConfirm.value = false;
            }
        });

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(!refData1.value) {
                warning.value = true;
                message.value = '국가 코드를 입력해주세요.';
                return;
            }
            if(!codeName.value) {
                warning.value = true;
                message.value = '국가를 입력해주세요.';
                return;
            }

            if(!regexConfirm.value) {
                    warning.value = true;
                    message.value = '국가 코드를 정확히 입력해주세요.';
                    return;
                }

                if(refData1.value == currentRefData1.value) {
                    confirm.value = true;
                    // if(codeName.value == currentCodeName.value) {
                    //     confirm.value = true;
                    // } else {
                    //     codeNameCheck();
                    // }
                } else {
                    refData1Check();
                }
        });

        const execute = () => {
            let param = [{
                "commonId": checkList.value[0],
                "insertId": insertId.value,
                "refData1": refData1.value,
                "codeName": codeName.value,
                "saveMode": "U",
            }];

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/common/nationSaveList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        };

        const readData = (() => {
            const param = {
                "mainCode": "N0001",
                "commonId": checkList.value[0]
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": param
                }
            ).then(result => {
                if(result.data.data != null) {
                    refData1.value = result.data.data.refData1;
                    codeName.value = result.data.data.codeName;
                    currentRefData1.value = result.data.data.refData1;
                    currentCodeName.value = result.data.data.codeName;
                } 
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });


        // 국가 코드 체크 (isUse가 true인 경우)
        const refData1Check = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": true,
                        "refData1": refData1.value
                    }
                }
            ).then(result => {
                if(result.data.data.commonId == null) {
                    confirm.value = true;
                    // if(codeName.value == currentCodeName.value) {
                    //     confirm.value = true;
                    // } else {
                    //     codeNameCheck();
                    // }

                    // codeNameCheck();
                    // refData1Check2();
                } else {
                    warning.value = true;
                    message.value = '국가 코드가 중복입니다.';
                    return;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 국가 코드 체크 (isUse가 false 경우)
        const refData1Check2 = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": false,
                        "refData1": refData1.value
                    }
                }
            ).then(result => {
                // if(result.data.data.commonId == null) {
                //     // confirm.value = true;
                // } else {
                //     commonId.value = result.data.data.commonId;
                //     // confirm.value = true;
                // }
                // 편집에서 삭제 되어있는 코드를 확인할 경우에는 어떻게 해야할지 미정

                if(codeName.value == currentCodeName.value) {
                    confirm.value = true;
                } else {
                    codeNameCheck();
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });
        
        // 국가 체크 (isUse가 true인 경우)
        const codeNameCheck = (() => {
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": {
                        "mainCode": "N0001",
                        "isUse": true,
                        "codeName": codeName.value
                    }
                }
            ).then(result => {
                if(result.data.data.commonId == null) {
                    confirm.value = true;
                } else {
                    warning.value = true;
                    message.value = '국가가 중복입니다.';
                    return;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        onBeforeMount(() => {
            readData();
        });

        return {
            checkList,
            refData1,
            codeName,

            message,
            warning,
            confirm,

            checkRefData1,
            
            cancel,
            next,
            execute
        }
    }
}
</script>

<style>

</style>