<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
export default {
    name: 'EditPopup1',
    setup() {
        const emitter = inject('emitter')
        
        // 취소 => 팝업 닫기
        const cancel = () => {
            emitter.emit('popupType', '')
        }

        // 확인 => 주문 코드 변경 완료 후 '라이선스 변경' 버튼 활성화
        const execute = () => {
            emitter.emit('popupType', '')   // 임시 처리
            emitter.emit('returnFunction', 'licenseChange')
        }

        return {
            cancel,
            execute
        }
    }
}
</script>

<style>

</style>