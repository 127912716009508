<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 목록 편집</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">부품 종류 코드</li>
                            <li class="cont">
                                <input type="text" v-model="subCode" placeholder="">
                            </li>
                            <li class="tit">부품 종류</li>
                            <li class="cont">
                                <input type="text" v-model="codeName" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 목록 편집</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 편집하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'SettingPartPopup2',
    setup() {
        const emitter = inject('emitter');
        const store = useStore();

        const checkList = computed(() => store.state.dataModule.settingPartPopup_checkList);
        const insertId = computed(() => store.state.profileModule.id);

        let currentSubCode  = ref(null);
        let currentCodeName = ref(null);
        let subCode  = ref(null);
        let codeName = ref(null);

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        let publicParam = ref(null);

        const cancel = () => {
            emitter.emit('popupType', '');
        };

        const next = (() => {
            // if (!subCode.value || !codeName.value) {
            //     alert("필드 값을 모두 입력해주세요.");
            //     return;
            // } else {
            //     if(currentSubCode.value == subCode.value) {
            //         if(currentCodeName.value != codeName.value) {
            //             codeNameCheck();
            //         } else {
            //             confirm.value = true;
            //         }
            //     } else {
            //         subCodeCheck();
            //     }
            // }

            if(!subCode.value) {
                warning.value = true;
                message.value = '부품 종류 코드를 입력해주세요.';
                return;
            }

            if(!codeName.value) {
                warning.value = true;
                message.value = '부품 종류를 입력해주세요.';
                return;
            }

            if(currentSubCode.value == subCode.value) {
                if(currentCodeName.value != codeName.value) {
                    codeNameCheck();
                } else {
                    confirm.value = true;
                }
            } else {
                subCodeCheck();
            }
        });
        
        // 부품 종류 코드 중복 체크
        const subCodeCheck = (() => {
            const param = {
                "mainCode": "P0001",
                "subCode": subCode.value
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": param
                }
            ).then(result => {
                if(result.data.data.subCode == null) {
                    if(currentCodeName.value == codeName.value) {
                        confirm.value = true;
                    } else {
                        codeNameCheck();
                    }
                } else {
                    alert("부폼 종류 코드가 중복되었습니다.");
                    return;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 부품 종류 중복 체크
        const codeNameCheck = (() => {
            const param = {
                "mainCode": "P0001",
                "codeName": codeName.value
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": param
                }
            ).then(result => {
                confirm.value = true;
                // if(result.data.data.codeName == null) {
                //     confirm.value = true;
                // } else {
                //     alert("부폼 종류가 중복되었습니다.");
                //     return;
                // }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const execute = (() => {
            let param = {...publicParam.value};
            param.insertId = insertId.value;
            param.mainCode = "P0001";
            param.saveMode = "U";
            param.subCode = subCode.value;
            param.codeName = codeName.value;

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/common/saveList',
                    "param": [param]
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    checkList.value = [];
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const readData = (() => {
            const param = {
                "mainCode": "P0001",
                "subCode": checkList.value
                // "subCode": checkList.value[0]
            };

            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/common/getData',
                    "param": param
                }
            ).then(result => {
                if(result.data.data != null) {
                    currentSubCode.value = result.data.data.subCode;
                    currentCodeName.value = result.data.data.codeName;
                    subCode.value = result.data.data.subCode;
                    codeName.value = result.data.data.codeName;

                    publicParam.value = result.data.data;
                } 
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        onBeforeMount(() => {
            // getPartType();
            readData();
        });

        return {
            checkList,
            insertId,
            subCode,
            codeName,

            message,
            warning,
            confirm,

            cancel,
            next,
            execute,
        }

    },
}
</script>

<style>

</style>