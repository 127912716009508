<template>
    <ul class="board-sub-menu">
        <li v-for="(column, index) in originColumn" :key="index">
            <div class="chk-box filtering">
                <input type="checkbox" :id="column.engName" class="chk_btn" v-model="checkedColumns" :value="column" 
                :disabled="column.engName == 'rowNum' || column.engName == 'productSn'"
                @change="checkColumn(column.engName)">
                <label :for="column.engName" class="chk">
                    <span>{{ column.korName }}</span>
                </label>
            </div>
        </li>
        <li>
            <span>{{checkedColumns.length}}</span>
            <span>/</span>
            <span>{{originColumn.length}}</span>
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Filtering',
    data() {
        return {
            checkedColumns: [],
        }
    },
    props: {
        columns: Array
    },
    created() {
        this.checkedColumns = [...this.columns];
    },
    computed: {
        ...mapState(['originColumn'])
    },
    watch: {
        checkedColumns(newVal, oldVal) {
            // 체크박스 3개 미만으로 체크헤제 되는것을 막기
            if(newVal.length < 3) {
                newVal = oldVal
                this.checkedColumns = oldVal
                alert("필드는 최소 3개이상 선택되어야 합니다")
            }

            let originColumn = []

            this.originColumn.forEach((r)=>{
                newVal.forEach((d)=>{
                    if(r.engName == d.engName) {
                        r.visible = true;       
                        originColumn.push(r)
                    }
                })
            })

            this.$emit('updateCheckedColumns', originColumn);
        }
    },
    methods: {
        checkColumn(engName) {
            if(engName == 'rowNum' || engName == 'productSn') {
                alert('번호와 일련번호는 필수 항목입니다')
            }  
        },
    },
}
</script>

<style>

</style>