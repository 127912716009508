<template>
  <div class="wrap">
    <div class="container">
        <Menu></Menu>
        <div class="main">
            <LoadingSpinner v-if="isLoading"></LoadingSpinner>
            <Header></Header>
            <Popup></Popup>
            <Tooltip></Tooltip>
            <router-view></router-view>
        </div>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Popup from '../components/Popup.vue'
import Tooltip from '../components/Tooltip.vue'
import LoadingSpinner from '../components/popup/common/LoadingSpinner.vue'

import { onMounted, onBeforeUnmount, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    setup() {
        // Vuex store 사용을 위해 store 가져오기
        const store = useStore();

        // router 선언
        const router = useRouter();

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // computed 속성 정의
        const isLoading = computed(() => store.state.isLoading);

        // 컴포넌트가 마운트될 때 실행될 로직
        onMounted(() => {
            // window.addEventListener('beforeunload', handleBeforeUnload);
            
            // const keepLogin = store.getters['loginModule/getKeepLogin']; // 로그인 유지 변수

            // function getCookie(name) {
            //     const value = "; " + document.cookie;
            //     const parts = value.split("; " + name + "=");
            //     if (parts.length === 2) return parts.pop().split(";").shift();
            // }
            
            // const keepLogin_cookie = getCookie('keepLogin');

            // if (keepLogin === false) {
            //     if (keepLogin_cookie === undefined || keepLogin_cookie === null) {
            //         store.dispatch('loginModule/doLogout');
            //         return;
            //     }
            // }

            // store.dispatch('loginModule/checkTokenExpiry');

            function getCookie(name) {
                const value = "; " + document.cookie;
                const parts = value.split("; " + name + "=");
                if (parts.length === 2) return parts.pop().split(";").shift();
            }

            const id_cookie = getCookie('id');

            if(id_cookie == null || id_cookie == undefined) {
                store.dispatch('loginModule/doLogout');
                return;
            }

            store.dispatch('loginModule/checkTokenExpiry');

            window.addEventListener('popstate', event => {
                // router.go(-1);
            });
        });

        // 컴포넌트가 언마운트되기 전에 실행될 로직
        onBeforeUnmount(() => {
            // window.removeEventListener('beforeunload', handleBeforeUnload);

            window.removeEventListener('popstate', event => {
                // router.go(-1);
            });
        });

        // methods에 정의했던 handleBeforeUnload 함수를 setup 내로 이동
        const handleBeforeUnload = (event) => {
            // const keepLogin = store.getters['loginModule/getKeepLogin'];
            // if (!keepLogin) {
            //     store.dispatch('loginModule/doLogout');
            // } else {
            //     store.dispatch('loginModule/checkTokenExpiry');
            // }
        };

        // isLoading과 handleBeforeUnload 함수 반환
        return {
            isLoading,
            handleBeforeUnload,
        };
    },
    components: {
        Menu,
        Header,
        Popup,
        Tooltip,
        LoadingSpinner
    },
}
</script>

<style>
.v-input__control {
    height: 32px !important;
}
.v-field__field {
    height: 32px !important;
}
.v-field__input {
    min-height: 32px !important;
    -webkit-padding-start: initial !important;
    -webkit-padding-end: initial !important;
    padding-inline-start: initial !important;
    padding-inline-end: initial !important;
    padding-top: initial !important;
    padding-bottom: initial !important;
}
.v-field__input input {
    min-height: 32px !important;
}
.v-text-field .v-field__input input {
    margin-top: initial !important;
    margin-bottom: initial !important;
}
.v-autocomplete .v-field__prepend-inner {
    display: none;
}
.v-autocomplete .v-field__append-inner {
    /* display: none; */
}
.v-autocomplete .v-input__details {
    display: none;
}
.v-autocomplete--single .v-field--variant-outlined input {
    top: initial !important;
    transform: initial !important;
}


/* .centered-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
/* .autocomplete {
    position: relative;
}

.autocomplete input {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAABHNCSVQICAgIfAhkiAAAAM1JREFUKFNjZICC27dvOzAyMq4GYhGYGDr9////N0AcqqqqegAkxwhTcOfOnTdAjcK4NMLEgZrfqqiogC2gmuYEoM2TgQby4HH2F6BcLNDmDSg2gzh3795dBqSCgZgNiwG/gGJrlZWVo2BycGeDBO7fvy/w9+/fM0AXKGMJrLvMzMwmioqKH7BqhhpgADTgANAAfqRA+gjU6ADUeAHZUBSbkUIe5P8JQD7IgI/AEC4A+nMBumuwagYpAkZdANCAGKDGJbAAIlozofgGyQMAD79UC45yDugAAAAASUVORK5CYII=);
    background-position: 94% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
}

.autocomplete-options {
    list-style-type: none;
    padding: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    position: absolute;
    width: 100%;
    max-height: 180px;
    overflow-y: scroll;
    z-index: 1;
}
.autocomplete-options.reverse {
    transform: translateY(-100%);
}

.autocomplete-options li {
  padding: 8px;
  cursor: pointer;
}

.selected {
  background-color: lightgray;
} */
</style>