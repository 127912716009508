import { Workbook } from 'exceljs';

export const downloadExcel = async (dataList, filename = 'classys.xlsx', sheetname = 'classys', engNames = null, korNames = null, width = 15) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(sheetname); // 시트 이름 설정

    // engNames 값이 제공되었는지 확인
    let headers = Object.keys(dataList[0]);
    if (engNames) {
        headers = headers.filter(key => engNames.includes(key));
        // 순서 조정
        headers = engNames.map(item => headers.find(key => key === item));
    }
    // worksheet.addRow(headers);
    const headerRow = worksheet.addRow(korNames);

    // 데이터 추가
    for (const data of dataList) {
        // engNames가 제공되었다면 해당 헤더의 값만, 아니면 모든 값을 가져옴
        const rowValues = headers.map(header => data[header]);
        worksheet.addRow(rowValues);
    }

    // 셀 스타일
    headerRow.eachCell(cell => {
        // cell.alignment = { vertical: 'middle', horizontal: 'center' };
        // cell.font = { bold: true };
        // // cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
        // cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
        // cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

        // 셀 너비 설정(최소 10글자)
        worksheet.getColumn(cell.col).width = Math.max(cell.value.length, width);
    });

    // 엑셀 파일을 브라우저에서 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename; // 파일 이름 설정
    link.click();

    // 사용이 끝난 URL 객체 메모리 해제
    URL.revokeObjectURL(link.href);
};
