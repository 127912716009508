<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">제품 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">제품명</li>
                            <li class="">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                item-value="modelId"
                                v-model="modelId"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import productAuto from '@/js/autocomplete/productAuto';
import { onBeforeMount, inject } from 'vue';

export default {
    name: 'SettingInfoPopup3',
    setup() {
        const emitter = inject('emitter');

        const modelName = ref(null);
        const modelId = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        onBeforeMount(() => {
            getProduct();
        })

        return {
            modelName,
            modelId,
            modelNameList,

            cancel,
        }
    },
}
</script>

<style>

</style>