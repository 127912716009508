<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">결과</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, onUpdated, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
    name: 'ResultPopup',
    props: {
        returnPath: String,
        message: String,
    },
    setup(props) {
        // 결과 메시지
        const returnPath = ref(props.returnPath);
        const message = ref(props.message);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // router 선언
        const router = useRouter();

        const execute = (() => {
            // emitter.emit('returnPath', '');
            // emitter.emit('message', '');
            // emitter.emit('popupType', '');
            // router.push(returnPath.value);

            emitter.emit('reData', true);
            emitter.emit('popupType', '');
        });

        // 이벤트 구독
        // const onReturnPath = (data) => {
        //     console.log('onReturnPath')
        //     console.log(data)
        //     returnPath.value = data;
        // };

        // const onMessage = (data) => {
        //     console.log('onMessage')
        //     console.log(data)
        //     message.value = data;
        // };

        onMounted(() => {
            // emitter.on('returnPath', onReturnPath);
            // emitter.on('message', onMessage);
            if(message.value == '' || message.value == null) {
                message.value = '데이터 처리가 완료되었습니다.'
            }
        })

        // 컴포넌트가 제거될 때 이벤트 구독 해제
        // const onBeforeUnmountHandler = () => {
        //     emitter.off('returnPath', onReturnPath);
        //     emitter.off('message', onMessage);
        // };

        // onBeforeUnmount를 훅으로 등록
        // onBeforeUnmount(onBeforeUnmountHandler);

        return {
            returnPath,
            message,
            execute
        }
    },
}
</script>

<style>

</style>