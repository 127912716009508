<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">대리점 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">대리점명</li>
                            <li class="cont">
                                <input type="text" v-model="customerName" placeholder="">
                            </li>
                            <li class="tit">대리점 코드</li>
                            <li class="cont">
                                <input type="text" v-model="customerCode" placeholder="">
                            </li>
                            <li class="tit">국가</li>
                            <li class="pbot20">
                                <v-autocomplete
                                :items="nationList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="subCode"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">주소</li>
                            <li class="cont">
                                <input type="text" v-model="address" placeholder="">
                            </li>
                            <li class="tit">대표자명</li>
                            <li class="cont">
                                <input type="text" v-model="representativeName" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">대리점 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            대리점을 추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUnmounted, computed, inject, watch } from 'vue'
import { useStore } from 'vuex'
import nationAuto from '@/js/autocomplete/nationAuto';

export default {
    name: 'AgencyPopup1',
    setup() {
        const emitter = inject('emitter');

        const customerName = ref('');
        const customerCode = ref('');
        const address = ref('');
        const representativeName = ref('');

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        // store
        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);

        // 국가 (자동완성 관련)
        const subCode = ref(null);
        const nationName = ref(null);
        const {
            nationList,
            getNation,
        } = nationAuto();
        
        // 취소 => 팝업 닫기
        const cancel = () => {
            emitter.emit('popupType', '')
        };
        
        const next = (() => {
            if(customerName.value == '' || customerName.value == null) {
                warning.value = true;
                message.value = '대리점명을 입력해주세요.';
                return;
            }

            if(customerCode.value == '' || customerCode.value == null) {
                warning.value = true;
                message.value = '대리젘 코드를 입력해주세요.';
                return;
            }

            if(subCode.value == '' || subCode.value == null) {
                warning.value = true;
                message.value = '국가를 선택해주세요.';
                return;
            }
            
            if(address.value == '' || address.value == null) {
                warning.value = true;
                message.value = '주소를 입력해주세요.';
                return;
            }
            
            if(representativeName.value == '' || representativeName.value == null) {
                warning.value = true;
                message.value = '대표자명을 입력해주세요.';
                return;
            }

            // 대리점 코드 중복 여부 확인
            duplicateCheck();
        });

        // 확인 => 대리점 추가
        const execute = () => {
            // 추가 팝업에 있는 요소들은 address제외하고 전부 필수값
            let param = {
                "insertId": insertId.value,
                "customerName": customerName.value,
                "customerCode": customerCode.value,
                "customerType": 2,                   // 고객유형(C0001 0:클래시스1:판매업체(대리점) 2: 고객) default : 2
                "representativeName": representativeName.value,
                "address": address.value,
                "nation": subCode.value,
                "isManage": true,
                "isUse": true,
            };

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/customer/insert',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        };

        // 대리점명 중복 확인
        const duplicateCheck = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/customer/getData',
                    "param": {
                        "customerName": customerName.value
                    }
                }
            ).then(result => {
                // 성공시
                if(result.data.data != null) {
                    warning.value = true;
                    message.value = '대리점명이 중복입니다.';
                    return;
                } else {
                    duplicateCheck2();
                }
                // confirm.value = true;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 대리점 코드 중복 확인
        const duplicateCheck2 = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/customer/getData',
                    "param": {
                        "customerCode": customerCode.value
                    }
                }
            ).then(result => {
                // 성공시
                if(result.data.data != null) {
                    warning.value = true;
                    message.value = '대리점 코드가 중복입니다.';
                    return;
                }
                confirm.value = true;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onBeforeMount(() => {
            // nationAutoComplete.getNation();
            getNation();
        });

        return {
            customerName,
            customerCode,
            address,
            representativeName,

            message,
            warning,
            confirm,

            insertId,
            
            subCode,
            nationName,
            nationList,
            getNation,

            cancel,
            next,
            execute,
        }
    }
}
</script>

<style>

</style>