<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/licenseGroup')" class="prev">라이선스 그룹 관리</a>
                <a href="" class="now">상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/licenseGroup')" class="btn-sb-g">목록으로</button>
                        <button @click="openPopup('licenseGroupDetailPopup1')" class="btn-sb-g">편집</button>
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || 
                                (userAuthSub == 1 && (userAuth == 4 || userAuth == 5 || userAuth == 6 || userAuth == 7)) " 
                            @click="openPopup('deletePopup')" 
                            class="btn-sb-g">삭제
                        </button>
                        <!-- <button v-if="userAuth != 11 && isUse == true" @click="openPopup('deletePopup')" class="btn-sb-g">삭제</button> -->
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">그룹명</li>
                            <li class="table-item">
                                <span>{{licenseName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">서버 연동</li>
                            <li class="table-item"> 
                                <span>{{isServer}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">오프라인 유효기간</li>
                            <li class="table-item">
                                <span>{{offlineExpirationDay}}일</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">변경 일시</li>
                            <li class="table-item">
                                <!-- <span>2022.09.16 023:53:42</span> -->
                                <span>{{updateDate}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">사용 여부</li>
                            <li class="table-item">
                                <!-- <span>{{isUse}}</span> -->
                                <span>{{licenseIsUse}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">라이선스 만료일</li>
                            <li class="table-item"> 
                                <span>{{expirationDate}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">유효기간 만기안내</li>
                            <li class="table-item">
                                <span>{{offlineExpirationAlarm}}일</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">등록 일시</li>
                            <li class="table-item">
                                <span>{{insertDate}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <h3>목록</h3>
                <div class="top-bar">
                    <div class="list-area">
                        <ul class="input-txt-list">
                            <li class="tit">일련번호</li>
                            <li class="cont">
                                <input type="text" v-model="productSn">
                            </li>
                        </ul>
                        <ul class="input-txt-list">
                            <li class="tit">제품명</li>
                            <li class="">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                item-value="modelName"
                                v-model="modelName"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                        <div class="btn-box one">
                            <button class="btn-sb-g" @click="readList(1, 'search')">검색</button>
                        </div>
                    </div> 
                </div>
                <div v-if="totalCount === 0" class="board license-group-detail filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board license-group-detail filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :name="column.engName" :title="column.korName">{{column.korName}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="goProductDetail(column.engName, row.productSn, row.productId)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :class="{'pointer' : column.engName == 'productSn'}"
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readList"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, reactive, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import productAuto from '@/js/autocomplete/productAuto';
export default {
    name: 'LicenseGroupDetail',
    setup() {
        // 변수 선언
        let licenseName = ref(null);
        let licenseIsUse = ref(null);
        let isServer = ref(null);
        let offlineExpirationDay = ref(null);
        let updateDate = ref(null);
        let isUse = ref(null);
        let expirationDate = ref(null);
        let offlineExpirationAlarm = ref(null);
        let insertDate = ref(null);

        let activeParam = ref(null);
        let productSn = ref(null);          

        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let totalCount = ref(1);
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_licenseGroupDetail || 5);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userAuthSub = computed(() => store.state.loginModule.userAuthSub);
        const licenseId = computed(() => store.state.dataModule.licenseGroupDetail_licenseId);
        const insertId = computed(() => store.state.profileModule.id);
        let utcTime = computed(() => store.state.utcTime);
        
        const emitter = inject('emitter');
        const router = useRouter();
        const dateUtils = inject('dateUtils');

        // 자동완성 - 제품명
        const modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        /**
         * 함수 선언
         */
        // 제품 일련번호를 클릭 => 해당 번호의 제품 관리 상세보기로 이동
        const goProductDetail = ((key, productSn, productId) => {
            if(key === 'productSn') {
                store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_SN', productSn);
                store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_ID', productId);
                router.push('/productDetail');
            }
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            if(popupType == 'deletePopup') {
                // 목록에 조회되는 장비가 없는 경우에만 삭제 가능
                if(totalCount.value != 0) {
                    alert('목록에 조회되는 장비가 존재하여 삭제가 불가능합니다.')
                    return;
                }
            }
            emitter.emit('popupType', popupType)
        });

        // 삭제
        const deleteFunction = (() => {
            let param = [{
                "licenseId": licenseId.value,
                "insertId": insertId.value,
                "isUse": false
            }];

            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/license/updateUseList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {  
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 편집 후 데이터 다시 조회
        const onReData = (data) => {
            if(data) {
                readData();
            }
        };

        const onGoMenu = (data) => {
            router.push('/licenseGroup');
        };

        // 목록
        const readList = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_LICENSEGROUP_DETAIL', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "licenseId": licenseId.value,
                    "productSn" : productSn.value,
                    "modelName": modelName.value,
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": param
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 2, align: 'center'},
                    { engName: 'productSn', korName: '제품 일련 번호', flex: 4, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 5, align: 'left'},
                    { engName: 'nationName', korName: '판매 국가', flex: 3, align: 'center'},
                    { engName: 'salesCustomerName', korName: '고객사', flex: 8, align: 'left'},
                    { engName: 'customerName', korName: '판매처', flex: 8, align: 'left'},
                    { engName: 'orderCode', korName: '발주 주문 코드', flex: 4, align: 'center'},
                    { engName: 'releaseDate', korName: '출고일', flex: 4, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/license/getData',
                    "param": {
                        "licenseId": licenseId.value,
                    }
                }
            ).then(result => {
                licenseName.value = result.data.data.licenseName;
                isServer.value = result.data.data.isServer;
                offlineExpirationDay.value = result.data.data.offlineExpirationDay;
                updateDate.value = dateUtils.convertUTC(result.data.data.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                isUse.value = result.data.data.isUse;
                licenseIsUse.value = result.data.data.licenseIsUse;
                expirationDate.value = dateUtils.convertUTC(result.data.data.expirationDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                offlineExpirationAlarm.value = result.data.data.offlineExpirationAlarm;
                insertDate.value = dateUtils.convertUTC(result.data.data.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onBeforeMount(() => {
            readData();
            readList(1, "search");
            getProduct();
        });

        onMounted(() => {
            emitter.on('delete', deleteFunction);
            emitter.on('reData', onReData);
            emitter.on('goMenu', onGoMenu);
        })

        const onBeforeUnmountHandler = () => {
            emitter.off('delete', deleteFunction);
            emitter.off('reData', onReData);
            emitter.off('goMenu', onGoMenu);
        };

        onBeforeUnmount(onBeforeUnmountHandler);

        watch(utcTime, () => {
            readData();
        });

        return {
            licenseName,
            licenseIsUse,
            isServer,
            offlineExpirationDay,
            updateDate,
            // isUse,
            expirationDate,
            offlineExpirationAlarm,
            insertDate,

            timer,
            columnData,
            rowData,
            activeParam,
            productSn,

            userAuth,
            userAuthSub,
            licenseId,
            insertId,

            // 제품명 + 자동완성 처리 변수
            modelName,
            modelNameList,
            getProduct,

            goProductDetail,
            startTimer,
            clearTimer,
            openPopup,
            deleteFunction,

            totalCount,
            selectedPage,
            selectedPageSize,
            readList,
            readData,
        }
    },
}
</script>

<style>

</style>