import { createApp } from 'vue'
import App from './App.vue'

import router from './router/router.js'
import store from './store/store.js'
import axios from 'axios'
import mitt from 'mitt'
import VueMoment from 'vue-moment'
import moment from 'moment'

// 공용 css
import './assets/css/reset.css'
import './assets/css/font/font.css'
import './assets/css/common.css'
import './assets/css/fontawesome/all.css'
import './assets/css/fontawesome/all.js'

import Filtering from './components/Filtering.vue'
import Filtering2 from './components/Filtering2.vue'
import Pagination from './components/Pagination.vue'
import Tooltip from './components/Tooltip.vue'

import dateUtils from '@/js/common/UTCutils.js';

axios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        // if(error.code == "ERR_NETWORK") {
        //     if (error.config.url.includes('/api/v1/oauth/refresh-token')) {
        //         console.log("refresh token");
        //         return Promise.reject(error);
        //     }

        //     var isSuccessRefresh = await store.dispatch('loginModule/reissueRefreshToken');

        //     if (isSuccessRefresh) {
        //         const config = error.config;
        //         const newAccessToken = store.getters['loginModule/getAccessToken']; // 새로운 토큰 값으로 변경
        //         config.headers['Authorization'] = `Bearer ${newAccessToken}`;

        //         return axios(config);
        //     } else {
        //         store.dispatch('loginModule/doLogout');
        //         return Promise.reject(error);
        //     }
        // }

        if (error.response.data.code === "cm0003") { // AccessToken이 만료가 되었을 때,
            // 토큰 재발급 요청을 재시도하지 않도록 조건 추가
            if (error.config.url.includes('/api/v1/oauth/refresh-token')) {
                console.log("refresh token");
                return Promise.reject(error);
            }

            var isSuccessRefresh = await store.dispatch('loginModule/reissueRefreshToken');

            if (isSuccessRefresh) {
                const config = error.config;
                const newAccessToken = store.getters['loginModule/getAccessToken']; // 새로운 토큰 값으로 변경
                config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                window.location.reload();
                return axios(config);
            } else {
                store.dispatch('loginModule/doLogout');
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }
)

// axios 통신 시간 설정을 하면 서버에서 어떤 오류를 보내주는지 받질 못해서 주석 처리
// axios.defaults.timeout = 3000;

let emitter = mitt();
let app = createApp(App);
app.config.globalProperties.emitter = emitter
app.config.globalProperties.axios = axios
app.config.globalProperties.dateUtils = dateUtils

app.provide('emitter', emitter);
app.provide('dateUtils', dateUtils);

// 지정된 영역외에 클릭시 이벤트 구성
const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
}

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

app.component('Filtering', Filtering)
app.component('Filtering2', Filtering2)
app.component('Pagination', Pagination)
app.component('Tooltip', Tooltip)
app.use(vuetify).use(router).use(store).use(moment).directive("click-outside", clickOutside).mount('#app')