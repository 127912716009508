<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">라이선스 그룹 편집</div>
                <div class="inner">
                    <div class="cont">
                        <div class="table pbot20 clearfix">
                            <div class="group">
                                <ul>
                                    <li class="table-tit">그룹명</li>
                                    <li class="table-item">
                                        <span>{{licenseName}}</span>
                                    </li>
                                </ul>
                            </div>  
                        </div>
                        <div class="chk-box pbot20 filtering">
                            <div class="tit">라이선스 권한</div>
                            <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" checked="">
                            <label for="licenseIsUse" class="chk">
                                <span>사용 여부</span>
                            </label>
                            <input type="checkbox" v-model="isServer" id="isServer" class="chk_btn" checked="">
                            <label for="isServer" class="chk">
                                <span>서버 연동</span>
                            </label>
                        </div>
                        <ul class="input-txt-list">
                            <li class="tit">라이선스 만료일</li>
                            <li class="cont">
                                <input type="date" v-model="expirationDate" @input="setDate('direct')" placeholder="">
                            </li>
                        </ul>
                        <div class="btn-box tab pbot20">
                            <button @click="setDate('month', 1)" class="btn-sb-g" :class="{'active': expirationButton == 'm1'}">1달</button>
                            <button @click="setDate('month', 3)" class="btn-sb-g" :class="{'active': expirationButton == 'm3'}">3달</button>
                            <button @click="setDate('month', 6)" class="btn-sb-g" :class="{'active': expirationButton == 'm6'}">6달</button>
                            <button @click="setDate('year', 1)" class="btn-sb-g" :class="{'active': expirationButton == 'y1'}">1년</button>
                            <button @click="setDate('year', 3)" class="btn-sb-g" :class="{'active': expirationButton == 'y3'}">3년</button>
                            <button @click="setDate('none', 999999)" class="btn-sb-g" :class="{'active': expirationButton == 'n'}">영구</button>
                        </div>
                        <ul class="input-txt-list">
                            <li class="tit">오프라인 유효기간(일)</li>
                            <li class="cont">
                                <input type="number" v-model="offlineExpirationDay" @input="checkOfflineExpirationDay" placeholder="">
                            </li>
                            <li class="tit">유효기간 만기안내(일)</li>
                            <li class="cont">
                                <input type="number" v-model="offlineExpirationAlarm" @input="checkOfflineExpirationAlarm" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="confirm = true" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, reactive } from 'vue';
import { useStore, mapState } from 'vuex';
import { validateOfflineExpirationDate } from '@/js/common/regex.js';
export default {
    name: 'LicenseGroupDetailPopup1',
    setup() {
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // store
        const store = useStore();
        const licenseId = computed(() => store.state.dataModule.licenseGroupDetail_licenseId);
        const insertId = computed(() => store.state.profileModule.id);

        let licenseName = ref(null);            // 그룹명
        let licenseIsUse = ref(false);          // 사용 여부
        let isServer = ref(false);              // 서버 연동
        let expirationDay = ref(null);          // 만료일(일)
        let expirationDate = ref(null);         // 라이선스 만료일
        let offlineExpirationDay = ref(null);   // 오프라인 유효기간(일)
        let offlineExpirationAlarm = ref(null); // 유효기간 만기안내(일)

        let expirationButton = ref(null);       // 라이선스 만료일 버튼 제어

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);                // 팝업 step 조절

        // 라이선스 만료일 설정
        const setDate = ((type, num) => {
            if(type == 'month') {
                let returnDate = dateUtils.changeMonthDate(num);
                expirationDate.value = returnDate.dateString;
                expirationDay.value = returnDate.day;
                expirationButton.value = 'm'+num;
            } else if(type == 'year') {
                let returnDate = dateUtils.changeYearDate(num);
                expirationDate.value = returnDate.dateString
                expirationDay.value = returnDate.day;
                expirationButton.value = 'y'+num;
            } else if(type == 'none') {
                expirationDate.value = '2127-12-31';
                expirationDay.value = num;
                expirationButton.value = 'n';
            } else if(type == 'direct') {
                expirationDay.value = dateUtils.getCurrentDate(expirationDate.value)
                expirationButton.value = null;
            }
        });

        // 오프라인 유효기간(일) 제약조건
        const checkOfflineExpirationDay = (() => {
            offlineExpirationDay.value = parseInt(offlineExpirationDay.value, 10).toString();
            if(isNaN(offlineExpirationDay.value)) offlineExpirationDay.value = 0;
            if (!validateOfflineExpirationDate(offlineExpirationDay.value)) {
                offlineExpirationDay.value = 1000000;
            }
        });

        // 유효기간 만기안내(일)
        const checkOfflineExpirationAlarm = (() => {
            offlineExpirationAlarm.value = parseInt(offlineExpirationAlarm.value, 10).toString();
            if(isNaN(offlineExpirationAlarm.value)) offlineExpirationAlarm.value = 0;
            if (!validateOfflineExpirationDate(offlineExpirationAlarm.value)) {
                offlineExpirationAlarm.value = 1000000;
            }
        });

        // 취소 => 팝업 닫기
        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        // 확인 => 라이선스 그룹 추가
        const execute = (() => {    
            let param = {
                "insertId": insertId.value,
                "licenseId": licenseId.value,
                "licenseName": licenseName.value,
                // "isUse": isUse.value,
                "isUse": true,
                "licenseIsUse": licenseIsUse.value,
                "isServer": isServer.value,
                "expirationDay": expirationDay.value,
                "expirationDate": expirationDate.value,
                "offlineExpirationDay": offlineExpirationDay.value,
                "offlineExpirationAlarm": offlineExpirationAlarm.value,
            };

            if(expirationDate.value == null || expirationDate.value == '' 
                || expirationDay.value == null || expirationDay.value == '') {
                warning.value = true;
                message.value = '라이선스 만료일을 선택해주세요.';
                return;
            }
            if(offlineExpirationDay.value == null || offlineExpirationDay.value == undefined) {
                warning.value = true;
                message.value = '오프라인 유효기간(일)을 입력해주세요.';
                return;
            }
            if(offlineExpirationAlarm.value == null || offlineExpirationAlarm.value == undefined) {
                warning.value = true;
                message.value = '유효기간 만기안내(일)을 입력해주세요.';
                return;
            }
            // if(!expirationDate.value || !expirationDay.value) {
            //     alert("라이선스 만료일을 선택해주세요.");
            //     return;
            // }
            // if(offlineExpirationDay.value == null || offlineExpirationDay.value == undefined) {
            //     alert("오프라인 유효기간(일)을 입력해주세요.");
            //     return;
            // }
            // if(offlineExpirationAlarm.value == null || offlineExpirationAlarm.value == undefined) {
            //     alert("유효기간 만기안내(일)을 입력해주세요.");
            //     return;
            // }

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/license/update',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onBeforeMount(() => {
            // 상세보기 요청
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/license/getData',
                    "param": {
                        "licenseId": licenseId.value,
                    }
                }
            ).then(result => {
                licenseName.value = result.data.data.licenseName;
                // isUse.value = result.data.data.isUse;
                licenseIsUse.value = result.data.data.licenseIsUse;
                isServer.value = result.data.data.isServer;
                expirationDay.value = result.data.data.expirationDay;
                expirationDate.value = result.data.data.expirationDate;
                offlineExpirationDay.value = result.data.data.offlineExpirationDay;
                offlineExpirationAlarm.value = result.data.data.offlineExpirationAlarm;
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        return {
            insertId,
            licenseId,
            licenseName,
            licenseIsUse,
            isServer,
            expirationDay,
            expirationDate,
            offlineExpirationDay,
            offlineExpirationAlarm,

            checkOfflineExpirationDay,
            checkOfflineExpirationAlarm,

            expirationButton,

            message,
            warning,
            confirm,

            setDate,
            cancel,
            execute,
        }
    },
}
</script>

<style>

</style>