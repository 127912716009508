<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/license')" class="prev">라이선스 관리</a>
                <a href="" class="now">상세 보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/license')" class="btn-sb-g">목록으로</button>
                        <!-- 권한OK : 관리자 -->
                        <button 
                            v-if="userAuth == 0 || userAuth == 1" 
                            @click="openPopup('deletePopup')" 
                            class="btn-sb-g">삭제
                        </button>
                        <button @click="confirm = true" class="btn-sb-g">장비 적용</button>
                    </div>
                </div>
                <div class="table clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">일련번호</li>
                            <li class="table-item">
                                <span>{{productSn}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">판매처</li>
                            <li class="table-item">
                                <span>{{customerName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">Request Code</li>
                            <li class="table-item">
                                <span>{{requestCode}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">제품명</li>
                            <li class="table-item">
                                <span>{{modelName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">라이선스 기간</li>
                            <li class="table-item">
                                <span>{{expirationDate}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">오프라인 유효기간(일)</li>
                            <li class="table-item"> 
                                <span>{{offlineExpirationDay}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <h3>신규 라이선스 키</h3>
                <div class="box">
                    <div class="inner licenseKey">
                        <div class="cont">
                            <p class="big">
                            </p>
                            <p class="big">
                                {{licenseKey}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btn-box one">
                    <button @click="$router.push('/license')" class="btn">확인</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">라이선스 관리</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            장비 적용을 하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="apply" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'LicenseDetail',
    setup() {
        // store에서 state 값 가져오기
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');

        // router 선언
        const router = useRouter();
        
        const insertId = computed(() => store.state.profileModule.id);

        const deviceLicenseId = computed(() => store.state.dataModule.licenseDetail_deviceLicenseId);
        const productSn = ref(null);
        const modelName = ref(null);
        const customerName = ref(null);
        const expirationDate = ref(null);
        const requestCode = ref(null);
        const offlineExpirationDay = ref(null);
        const licenseKey = ref(null);       // 신규 라이선스 키

        const confirm = ref(false);

        let publicParam = ref(null);

        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/device-license/getData',
                    "param": {
                        "deviceLicenseId": deviceLicenseId.value,
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;
                
                productSn.value = result.data.data.productSn;
                modelName.value = result.data.data.modelName;
                expirationDate.value = result.data.data.expirationDate;
                requestCode.value = result.data.data.requestCode;
                customerName.value = result.data.data.customerName;
                offlineExpirationDay.value = result.data.data.offlineExpirationDay;
                
                // 신규 라이선스 키 임시 처리
                // licenseKey.value = result.data.data.licenseKey;
                if(result.data.data.licenseKey != null) {
                    licenseKey.value = result.data.data.licenseKey;
                }

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType);
        });

        // 장비적용
        const apply = (() => {
            let param = {...publicParam.value}
            param.applyId = insertId.value;
            param.insertId = insertId.value;
            
            // delete param.applyDate;
            // delete param.isApply;

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/device-license/apply',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    confirm.value = false;
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 삭제
        const deleteFunction = (() => {
            let param = {
                "insertId": insertId.value,
                "deviceLicenseId": deviceLicenseId.value
            };

            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/device-license/delete',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup2');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 편집 후 데이터 다시 조회
        const onGoMenu = (data) => {
            router.push('/license');
        };
        
        onMounted(() => {
            readData();
            emitter.on('delete', deleteFunction);
            emitter.on('goMenu', onGoMenu);
        });

        onBeforeUnmount(() => {
            emitter.off('delete', deleteFunction);
            emitter.off('goMenu', onGoMenu);
        });

        return {
            userAuth,

            productSn,
            modelName,
            customerName,
            expirationDate,
            requestCode,
            offlineExpirationDay,
            licenseKey,

            confirm,

            openPopup,
            apply,
        }
    },
}
</script>

<style>

</style>