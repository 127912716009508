<template>
    <div class="popup-box">
        <div class="bg">
            <div v-if="confirm === false" class="popup">
                <div class="tit">판매 정보 변경</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">고객사</li>
                            <li class="cont">
                                <input type="text" v-model="salesCustomerName" :maxlength="50">
                            </li>
                            <li class="tit">고객사 코드</li>
                            <li class="cont">
                                <input type="text" v-model="salesCustomerCode" :maxlength="20">
                            </li>
                            <li class="tit">판매처</li>
                            <li class="cont">
                                <input type="text" v-model="customerName" :maxlength="50">
                            </li>
                            <li class="tit">판매 국가</li>
                            <li class="">
                                <v-autocomplete
                                :items="nationList"
                                item-title="codeName"
                                item-value="subCode"
                                v-model="subCode"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
            <div v-if="confirm === true" class="popup">
                <div class="tit">판매 정보 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            해당 정보로 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, onBeforeUnmount, onUnmounted, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import nationAuto from '@/js/autocomplete/nationAuto';

export default {
    name: 'ProductDetailPopup4',
    setup(){
        const emitter = inject('emitter');
        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);
        const productSn = computed(() => store.state.dataModule.productDetail_productSn);
        const productId = computed(() => store.state.dataModule.productDetail_productId);
        const modelId = computed(() => store.state.dataModule.productDetail_modelId);

        let publicParam = ref(null);

        let confirm = ref(false);
        let customerName = ref('');
        let salesCustomerCode = ref('');
        let salesCustomerName = ref('');

        const subCode = ref(null);
        const nationName = ref(null);

        // 판매 국가
        const {
            nationList,
            getNation,
        } = nationAuto();

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (()=> {
            confirm.value = true;
        });

        const execute = (() => {
            let param = {...publicParam.value};
            param.insertId = insertId.value;
            param.customerName = customerName.value,
            param.salesCustomerCode = salesCustomerCode.value,
            param.salesCustomerName = salesCustomerName.value,
            param.nation = subCode.value

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/product/salesCustomerUpdate',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readData = (()=> {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/getData',
                    "param": {
                        "productSn": productSn.value,
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;
                customerName.value = result.data.data.customerName;
                salesCustomerCode.value = result.data.data.salesCustomerCode;
                salesCustomerName.value = result.data.data.salesCustomerName;
                if (result.data.data.nationName !== null && result.data.data.nationName !== undefined) {
                    nationName.value = result.data.data.nationName;
                    subCode.value = result.data.data.nation;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onBeforeMount(() => {
            readData();
            getNation();
        });

        return {
            confirm,
            customerName,
            salesCustomerCode,
            salesCustomerName,

             // 판매 국가 + 자동완성 처리 변수
            subCode,
            nationName,
            nationList,
            getNation,

            cancel,
            next,
            execute,
            readData,
        }
    }
}
</script>

<style>

</style>