import moment from 'moment';

// dateString: API에서 전달해 온 UTC-0의 dateTime
// dateType: 2023-08-10 hh:mm:ss => yyyy-MM-DD HH:mm:ss 형식
// UTCTime: 변환하고자 하는 UTCTime
const convertUTC = (dateString, dateType, UTCTime) => {
	  let date = moment.utc(dateString, dateType);
	  date.add(UTCTime, 'hours');

	  return date.format(dateType);
}

// YYYY-MM-DD HH:mm:ss 형태의 문자열을 Date형으로 변환
const convertToUTCDate = (timeString) => {
    var utcMoment = moment.utc(timeString, "YYYY-MM-DD HH:mm:ss");
    return utcMoment.valueOf();
    // return utcMoment.toDate();
}

// 해당 날짜가 2127년 이후인지 확인
const checkYear2127 = (dateString) => {
  let date = moment(dateString, 'YYYY-MM-DD');
  let days = date.diff(moment('2127-01-01').format('YYYY-MM-DD'), 'days')
  return days;
}

// 현지 시간과 특정 시간 사이의 날짜 구하기
const getCurrentDate = (dateString) => {
    let date = moment(dateString, 'YYYY-MM-DD');
    let days = date.diff(moment().format('YYYY-MM-DD'), 'days')
    return days;
}

// 현재 시간으로부터 n년 후의 날짜 구하기
const changeYearDate = (n) => {
  let changeYear = moment().add(n, 'year');
  let changeYearString = moment(changeYear).format('YYYY-MM-DD');
  let days = changeYear.diff(moment().format('YYYY-MM-DD'), 'days')

  return {"day": days, "dateString": changeYearString};
}

// 현재 시간으로부터 n달 후의 날짜 구하기
const changeMonthDate = (n) => {
  let changeMonth = moment().add(n, 'month');
  let changeMonthString = moment(changeMonth).format('YYYY-MM-DD');
  let days = changeMonth.diff(moment().format('YYYY-MM-DD'), 'days')
 
  return {"day": days, "dateString": changeMonthString};
}

export default {
  convertUTC,
  convertToUTCDate,
  checkYear2127,
  getCurrentDate,
  changeYearDate,
  changeMonthDate,
  moment,
};