<template>
    <div v-if="!confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">부품 종류 코드</li>
                            <li class="cont">
                                <input type="number" v-model="subCode" placeholder="">
                            </li>
                            <li class="tit">부품 종류</li>
                            <li class="cont">
                                <input type="text" v-model="codeName" placeholder="">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="openPopup('')" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">부품 목록 추가</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject } from 'vue';
import { useStore, mapState } from 'vuex';
export default {
    name: 'SettingPartPopup1',
    setup() {
        const emitter = inject('emitter');
        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);

        let subCode  = ref(null);
        let codeName = ref(null);

        const message = ref(null);
        const warning = ref(false);
        let confirm = ref(false);

        const openPopup = (popupType) => {
            emitter.emit('popupType', popupType);
        };

        const next = (() => {
            if(!subCode.value) {
                warning.value = true;
                message.value = '부품 종류 코드를 입력해주세요.';
                return;
            }

            if(!codeName.value) {
                warning.value = true;
                message.value = '부품 종류를 입력해주세요.';
                return;
            }

            subCodeCheck();
        });

        // 부품 종류 코드 중복 체크
        const subCodeCheck = (() => {
            const param = {
                "mainCode": "P0001",
                "subCode": subCode.value
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": param
                }
            ).then(result => {
                if(result.data.data.subCode == null) {
                    codeNameCheck();
                } else {
                    warning.value = true;
                    message.value = '부폼 종류 코드가 중복되었습니다.';
                    return;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 부품 종류 중복 체크
        const codeNameCheck = (() => {
            const param = {
                "mainCode": "P0001",
                "codeName": codeName.value
            };

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getData',
                    "param": param
                }
            ).then(result => {
                confirm.value = true
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        // 저장
        const execute = () => {
            let param = [{
                "insertId": insertId.value,
                "saveMode": "N",
                "mainCode": "P0001",
                "subCode": subCode.value,
                "orderSeq": subCode.value,
                "codeName": codeName.value,
                "isUse": true,
            }];

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/common/saveList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.message);
                    openPopup('resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        };

        // onBeforeMount(() => {});

        return {
            insertId,
            subCode,
            codeName,
            
            openPopup,

            message,
            warning,
            confirm,
            next,
            execute,
        }
    }
}
</script>

<style>

</style>