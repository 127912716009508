// partTypeAutoComplete.js
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function partTypeAuto() {
    const store = useStore();
    
    const partTypeList = ref([]);

    const getPartType = () => {
        // 상세 검색 => 부품 종류
        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/common/getList',
                "param": {
                    "mainCode": "P0001",
                    "isUse": true
                }
            }
        ).then(result => {
            partTypeList.value = result.data.data;
        });
    }
    
    return {
        partTypeList,
        getPartType,
    }
}