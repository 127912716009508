<template>
    <div class="popup-box">
        <div class="bg">
            <div class="centered-spinner">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner"
}
</script>

<style scoped>
.centered-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #e0e0e0;
  border-bottom: 5px solid #fe9616;
  animation: spin 1s linear infinite;
  position: relative;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>