// authAuto.js
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function authAuto() {
    const store = useStore();

    const authList = ref([]);

    const getAuth = (exclude) => {
        // 상세 검색 => 권한
        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/common/getList',
                "param": {
                    "mainCode": "A0001",
                    "isUse": true
                }
            }
        ).then(result => {
            if(exclude != null && exclude != undefined) {
                if(exclude == 0) {
                    authList.value = result.data.data;
                } else {
                    authList.value = result.data.data.filter(item => item.subCode != 0)
                }
            } else {
                // codeNameGroup.value = result.data.data;
                // codeNameGroup.value.sort((a, b) => a.subCode - b.subCode);
                authList.value = result.data.data;
            }
        });
    }


    // 서브 분류
    const authSubList = ref([]);
    const authSubListOri = ref([]);

    const getAuthSub = () => {
        // 상세 검색 => 권한
        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/common/getList',
                "param": {
                    "mainCode": "A0002",
                    "isUse": true
                }
            }
        ).then(result => {
            // codeNameGroup2.value = result.data.data;
            // codeNameGroup2.value.sort((a, b) => a.subCode - b.subCode);

            // codeNameGroup2Ori.value = codeNameGroup2.value;

            authSubList.value = result.data.data;
            authSubListOri.value = result.data.data;
        });
    }
    
    return {
        authList,
        getAuth,
        authSubList,
        authSubListOri,
        getAuthSub,
    }
}