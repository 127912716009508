export const idPattern = new RegExp("^(?=.*[a-z])(?=.*[0-9]).{5,20}$");
export const pwPattern = new RegExp("^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$");

// 임시 4~20자리 제한 (사용자 추가 아이디, 비밀번호)
export const idPwPattern = new RegExp("^.{3,30}$");

export function validateId(newVal) {
    // return idPattern.test(newVal);
    return idPwPattern.test(newVal);
}

export function validatePw(newVal) {
    // return pwPattern.test(newVal);
    return idPwPattern.test(newVal);
}

export function validateText(newVal) {
    if (new TextEncoder().encode(newVal).length > 4096) {
        return false;
    } else {
        return true;
    }
}

export function validateOfflineExpirationDate(newVal) {
    const regex = new RegExp("^(0|[1-9]\\d{0,5}|1000000)$");
    if(regex.test(newVal)) {
        return true;
    } else {
        return false;
    }
}