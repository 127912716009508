<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">비밀번호 초기화</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            비밀번호를 초기화하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'UserDetailPopup2',
    setup() {
        const store = useStore();

        const emitter = inject('emitter');

        const id = computed(() => store.state.dataModule.userDetail_id);
        const insertId = computed(() => store.state.profileModule.id);

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const execute = (() => {
            const param = {
                "id": id.value,
                "insertId": insertId.value
            };

            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/user/pwInit',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });
        
        return {
            cancel,
            execute
        }
    }
}
</script>

<style>

</style>