<template>
    <div class="popup-box">
        <div class="bg">
            <div class="popup bigsize">
                <div class="tit">장비 할당 추가</div>
                <div class="inner">
                    <div class="cont">
                        <div class="list-area">
                            <ul class="input-txt-list">
                                <li class="tit">일련번호</li>
                                <li class="cont">
                                    <input type="text" v-model="productSn">
                                </li>
                            </ul>
                            <ul class="input-txt-list">
                                <li class="tit">발주 주문 코드</li>
                                <li class="cont">
                                    <input type="text" v-model="orderCode">
                                </li>
                            </ul>
                            <div class="btn-box one">
                                <button class="btn-sb-g" @click="readData(1, 'search')">검색</button>
                            </div>
                        </div>
                        <div v-if="totalCount === 0" class="board popup-equipment filtering-board">
                            <ul class="list-item filtering-board-item list-item-none">
                                <li>
                                    <ul class="item">  
                                        <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div v-if="totalCount !== 0" class="board popup-equipment filtering-board">
                            <ul class="list-item filtering-board-tit">
                                <li>
                                    <ul class="item">
                                        <li :style="{flex : 1}">
                                            <div class="chk-box">
                                                <input type="checkbox" class="chk_btn" v-model="checkedAll" value="all" id="all">
                                                <label for="all" class="chk"></label>
                                            </div>
                                        </li>
                                        <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :name="column.engName" :title="column.korName">{{column.korName}}</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                                <li>
                                    <ul class="item">
                                        <li :style="{flex : 1}">
                                            <div class="chk-box">
                                                <input type="checkbox" class="chk_btn" :id="row.productId" :value="row.productId" v-model="checkList">
                                                <label :for="row.productId" class="chk"></label>
                                            </div>
                                        </li>
                                        <li v-for="(column) in columnData" :key="column" 
                                            @touchstart="startTimer(column.korName, row[column.engName])" 
                                            @touchend="clearTimer" 
                                            :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                            :name="column.engName" 
                                            :title="row[column.engName]">{{row[column.engName]}}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">장비 할당</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            선택한 장비를 추가하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'AgencyDetailPopup1',
    setup() {
        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let totalCount = ref(1);
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_agencyDetailPopup1 || 5);
        let activeParam = ref(null);

        let checkList = ref([]);

        const store = useStore();
        const customerId = computed(() => store.state.dataModule.agencyDetail_customerId);
        const insertId = computed(() => store.state.profileModule.id);
        let utcTime = computed(() => store.state.utcTime);

        const emitter = inject('emitter');
        const router = useRouter();
        const dateUtils = inject('dateUtils');

        const productSn = ref(null);
        const orderCode = ref(null);

        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_AGENCY_DETAIL_POPUP1', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "productSn": productSn.value,
                    "orderCode": orderCode.value,
                    // "agencyCustomerId": customerId.value
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": param
                }
            ).then(result => {
                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 2, align: 'center'},
                    { engName: 'productSn', korName: '일련 번호', flex: 3, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 4, align: 'left'},
                    { engName: 'nationName', korName: '판매 국가', flex: 3, align: 'center'},
                    { engName: 'salesCustomerName', korName: '고객사', flex: 7, align: 'left'},
                    { engName: 'customerName', korName: '판매처', flex: 7, align: 'left'},
                    { engName: 'orderCode', korName: '발주 주문 코드', flex: 3, align: 'center'},
                    { engName: 'releaseDate', korName: '출고일', flex: 5, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

                if(result.data.data.list.length == 0) {
                    totalCount.value = 0;
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // computed 설정
        const checkedAll = computed({
            get: () => {
                checkList.value.sort((a, b) => a - b);
                return rowData.value.length === checkList.value.length;
            },
            set: (e) => {
                if (e) {
                    checkList.value = [];
                    rowData.value.forEach((r) => {
                        checkList.value.push(r.productId);
                    });
                } else {
                    checkList.value = [];
                }
            },
        });

        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            if(checkList.value.length == 0) {
                warning.value = true;
                message.value = '추가할 장비를 선택해주세요.';
                return;
            } else if(checkList.value.length > 0) {
                confirm.value = true;
            } 
        });

        // 장비할당 - 추가
        const execute = (() => {
            // const param = {
            //     "customerId": customerId.value,
            //     "productId": checkList.value[0],
            //     "insertId": insertId.value
            // }
            let param = [];

            checkList.value.some(item => {
                let inputData = {
                    "customerId": customerId.value,
                    "productId": item,
                    "insertId": insertId.value
                }
                param.push(inputData);
            });

            store.dispatch(
                'dataModule/insertData'
                , {
                    // "url": '/api/v1/customer-product/insert',
                    "url": '/api/v1/customer-product/insertList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    emitter.emit('message', result.data.message);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        onBeforeMount(() => {
            // 목록
            readData(1, "search");
        });

        return {
            timer,
            columnData,
            rowData,
            totalCount,
            selectedPage,
            selectedPageSize,
            activeParam,

            productSn,
            orderCode,

            message,
            warning,
            confirm,

            checkList,
            readData,

            startTimer,
            clearTimer,

            checkedAll,

            next,
            execute,
            cancel,
        }
    }
}
</script>

<style>

</style>