<template>
    <div>
        <div class="page-box">
            <div class="page-nation">
                <a @click.prevent="first" class="arrow pprev"></a>
                <a @click.prevent="prev" class="arrow prev"></a>
                <a v-for="pageNum in pageArray" :key="pageNum" :class="{'active': pageNum === selectedPage}" @click="change(pageNum)">{{ pageNum }}</a>
                <a @click.prevent="next" class="arrow next"></a>
                <a @click.prevent="last" class="arrow nnext"></a>
            </div>
            <div class="page-nation page-size page-pc">
                <div class="page-select">
                    페이지 사이즈 : 
                    <select @change="change2" v-model="selectedPageSize">
                        <option value=5>5</option>
                        <option value=10>10</option>
                        <option value=15>15</option>
                        <option value=20>20</option>
                        <option value=25>25</option>
                        <option value=30>30</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="page-box page-mobile">
            <div class="page-nation page-size">
                <div class="page-select">
                    페이지 사이즈 : 
                    <select @change="change2" v-model="selectedPageSize">
                        <option value=5>5</option>
                        <option value=10>10</option>
                        <option value=15>15</option>
                        <option value=20>20</option>
                        <option value=25>25</option>
                        <option value=30>30</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watchEffect } from 'vue';

export default {
    name: 'Pagination',
    props: {
        totalCount: Number,
        selectedPage: Number,
        selectedPageSize: Number,
    },
    setup(props, { emit }) {
        const pageSize = ref(5);
        const selectedPage = ref(props.selectedPage);
        const selectedPageSize = ref(props.selectedPageSize);
        const showPage = computed(() => Math.floor((selectedPage.value - 1) / pageSize.value));
        const startNum = computed(() => showPage.value * pageSize.value + 1);

        const prev = () => {
            if (showPage.value > 0) {
                selectedPage.value = startNum.value - 1;
                change(selectedPage.value);
            }
        };

        const next = () => {
            if (startNum.value + pageSize.value <= props.totalCount) {
                selectedPage.value = startNum.value + pageSize.value;
                change(selectedPage.value);
            }
        };

        const first = () => {
            selectedPage.value = 1;
            change(selectedPage.value);
        };

        const last = () => {
            selectedPage.value = props.totalCount;
            change(selectedPage.value);
        };

        const change = (pageNum) => {
            selectedPage.value = pageNum;
            emit('page-change', selectedPage.value, undefined, parseInt(selectedPageSize.value));
        };

        // pageSize를 변경한 경우
        const change2 =  () => {
            // selectedPageSize.value = parseInt(pageSize);
            emit('page-change', 1, undefined, parseInt(selectedPageSize.value));
        };

        const pageArray = computed(() => {
            const array = [];
            for (let step = 0; step < pageSize.value && startNum.value + step <= props.totalCount; step++) {
                array.push(startNum.value + step);
            }
            return array;
        });

        watchEffect(() => {
            selectedPage.value = props.selectedPage;
            selectedPageSize.value = props.selectedPageSize;
        });

        // watch(() => props.totalCount, (newVal, oldVal) => {
        //     console.log('이전 값 : ', oldVal);
        //     console.log('신규 값 : ', newVal);
        // });

        return {
            pageSize,
            selectedPage,
            selectedPageSize,
            prev,
            next,
            first,
            last,
            change,
            change2,
            pageArray,
            showPage,
            startNum
        };
    }
}
</script>
